import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import {
  map, noop, filter,
} from 'lodash';
import { Button, Input, FileInput } from '../../../../../components/base';
import {
  commaFormatted,
} from '../../../../../utils/transformer.util';

const MySwal = withReactContent(Swal);

const userGroupPusat = ['Korektor Pusat', 'Manager Keuangan', 'Bendahara'];
const Total = (filteredList) => {
  let totalnominal = 0;
  // console.log(filteredList)

  if (filteredList.data.length > 0) {
    for (let i = 0; i < filteredList.data.length; i += 1) {
      totalnominal += Number(filteredList.data[i].total);
    }
  }
  return totalnominal;
};

const renderHeaderModal = () => (
  <thead>
    <tr>
      <th style={{ fontSize: 20 }} rowSpan={2}>
        Keterangan
      </th>
      <th style={{ fontSize: 20 }} rowSpan={4}>
        Waktu Pelaksanaan
      </th>
      <th style={{ fontSize: 20 }} rowSpan={2}>
        Kuantitas
      </th>
      <th style={{ fontSize: 20 }} rowSpan={2}>
        Nominal
      </th>
      <th style={{ fontSize: 20 }} rowSpan={2}>
        Period
      </th>
      <th style={{ fontSize: 20 }} rowSpan={2}>
        Total
      </th>
    </tr>
  </thead>
);

const renderModalContent = (data, list) => {
  const filteredList = filter(list,
    o => data.tema_code === o.code_tema);

  return (
    <div>
      <div>
        <h1>{filteredList[0].name_tema}</h1>
      </div>
      <table className="manage-budget__form-table table">
        {renderHeaderModal()}
        {map(filteredList[0].data, item => (
          <tr>
            <td style={{ fontSize: 20 }}>{item.desc}</td>
            <td style={{ fontSize: 20 }}>{item.execution_time}</td>
            <td style={{ fontSize: 20 }} className="nominal">
              {commaFormatted(item.quantity)}
            </td>
            <td style={{ fontSize: 20 }} className="nominal">
              {commaFormatted(item.price)}
            </td>
            <td style={{ fontSize: 20 }} className="nominal">
              {commaFormatted(item.term)}
            </td>
            <td style={{ fontSize: 20 }} className="nominal">
              {commaFormatted(item.total)}
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={5} style={{ fontSize: 20 }} className="nominal">
            Total
          </td>
          <td className="nominal">
            Rp. {commaFormatted(Total(filteredList[0]))}
          </td>
        </tr>
      </table>
    </div>
  );
};

const renderAllocations = (allocations = []) => {
  let rutinValue = 0;
  let kegiatanValue = 0;
  let danPemValue = 0;
  let biPengValue = 0;

  allocations.forEach((allocation) => {
    const { allocations: sub } = allocation;
    Object.keys(sub).forEach((key) => {
      const all = sub[key];
      if (key === '1') {
        rutinValue += parseInt(all.nominal, 10);
      } else if (key === '2') {
        kegiatanValue += parseInt(all.nominal, 10);
      } else if (key === '3') {
        danPemValue += parseInt(all.nominal, 10);
      } else if (key === '4') {
        biPengValue += parseInt(all.nominal, 10);
      }
    });
  });

  const rows = [];

  rows.push([
    <td className="nominal">Rp.{commaFormatted(rutinValue)}</td>,
    <td className="nominal">Rp.{commaFormatted(kegiatanValue)}</td>,
    <td className="nominal">Rp.{commaFormatted(danPemValue)}</td>,
    <td className="nominal">Rp.{commaFormatted(biPengValue)}</td>,
  ]);
  return rows;
};

const countSummary = (type, collections = [], divisi) => {
  const plafondTotal = {};
  let total = 0;
  collections.forEach((collection) => {
    const rows = collection[type] || {};

    Object.values(rows).forEach((row) => {
      const { data } = row;
      data.forEach((o) => {
        if (o.divisi_id === divisi) {
          const { allocations } = o;
          const plafondIds = Object.keys(allocations);
          plafondIds.forEach((key) => {
            const allocation = allocations[key];
            if (!plafondTotal[key]) {
              Object.assign(plafondTotal, { [key]: 0 });
            }
            plafondTotal[key] += parseInt(allocation.nominal, 10);
          });
        }
      });

      if (plafondTotal) {
        Object.values(plafondTotal).forEach((sum) => {
          total += sum;
        });
      }
    });
  });

  return {
    plafondTotal,
    total,
  };
};

export default class BudgetRapbu extends Component {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
    this.onCheckAll = this.onCheckAll.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderSummary = this.renderSummary.bind(this);

    this.onABP = this.onABP.bind(this);
    this.onManagerial = this.onManagerial.bind(this);
    this.onKurikulum = this.onKurikulum.bind(this);
    this.onKesiswaan = this.onKesiswaan.bind(this);

    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onFormChange = this.onFormChange.bind(this);

    this.checkboxRefs = {
      ypl: {},
      committee: {},
      intern: {},
      bos: {},
    };
  }

  onKurikulum() {
    const { onKurikulum } = this.props;
    onKurikulum('kurikulum');
  }

  onKesiswaan() {
    const { onKesiswaan } = this.props;
    onKesiswaan('kesiswaan');
  }

  onABP() {
    const { onABP } = this.props;
    onABP('ABP');
  }

  onManagerial() {
    const { onManagerial } = this.props;
    onManagerial('managerial');
  }

  onCheckAll(event) {
    const { onCheckAll } = this.props;
    const { target } = event;
    const { dataset } = target;
    const { pos } = dataset;
    const checkboxPos = this.checkboxRefs[pos];

    Object.keys(checkboxPos).forEach((referenceId) => {
      checkboxPos[referenceId].checked = target.checked;
    });

    onCheckAll(checkboxPos);
  }

  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)
    // console.log(this.props)

    if (name === 'file') {
      // console.log(value)
    }
    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });

    // console.log(dataset.arrayPosition)
  }

  async onFileUpload(event) {
    const { handleFileUpload, onChange, idx } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const file = files[0];

    if (file) {
      const filename = file.name;
      const res = await handleFileUpload(file);

      onChange({
        target: {
          name: 'file',
          value: {
            path: res.path,
            filename,
          },
          dataset: {
            ...dataset,
            inputArray: true,
            arrayPosition: idx,
          },
        },
      });
    }
  }

  setRef(el) {
    const { input } = el;
    const { dataset } = input;
    const { pos, referenceid } = dataset;
    this.checkboxRefs[pos][referenceid] = input;
  }

  removeFile() {
    const { onRemoveFile, idx } = this.props;
    onRemoveFile(idx);
  }

  renderRows(type, semester) {
    const {
      user,
      workflow,
      handleFileDownload,
      selectedDivisi,
      rapbuSummary,
    } = this.props;
    const { semesterOne, semesterTwo } = rapbuSummary;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const canEdit = user_group.name === 'Korektor Perwakilan'
      || user_group.name === 'Manager Keuangan';
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;
    const list = semester === 1 ? semesterOne.pengeluaran || {} : semesterTwo.pengeluaran || {};
    const rows = [];

    if (!list) {
      return null;
    }

    Object.keys(list).forEach((key) => {
      const row = list[key];
      const { data } = row;
      const show = data.some(o => o.divisi_id === selectedDivisi);
      if (show) {
        rows.push([
          <tr key={`budget_rapbu_rows_${key}${(new Date()).toString(36)}`}>
            <td>
              {data.code}
              <Button
                variant="primary"
                onClick={() => {
                  MySwal.fire({
                    size: 20000,
                    title: <h3>Keterangan Anggaran</h3>,
                    width: '1000px',
                    height: '1000px',
                    footer: '',
                    html: renderModalContent(data[0], list),
                  });
                }}
              >
                cek data
              </Button>
            </td>
            <td>{data.title}</td>
            {(row.code_tema === '0' && ( // cek jika data = 0
              <h3>
                <center>-</center>
              </h3>
            )) || ( // kalau tidak = 0
              <td>
                {row.code_tema} - {row.name_tema}
              </td>
            )}

            <td>{data[0].target}</td>
            <td>{data[0].execution_time}</td>

            {renderAllocations(data)}
            <td>
              <div className="icon2">
                <FileInput
                  style={{ width: '300px' }}
                  data-input-type="file"
                  noMargin
                  type="file"
                  name="file"
                  edit={canEdit}
                  onChange={this.onFileUpload}
                  fileName={data[0].file ? data[0].file.name : ''}
                  displayName={
                    data[0].file
                      ? data[0].file.display_name
                      : 'default'
                  }
                  onRemoveFile={this.removeFile}
                  onDownloadFile={handleFileDownload}
                />
              </div>
            </td>

            {hasCheckbox && (
              <td>
                <Input
                  type="checkbox"
                  name="select_budget_detail"
                  data-referenceid={row.id}
                  data-pos="bos"
                  value={row.id}
                  ref={this.setRef}
                />
              </td>
            )}

            <td className="nominal">
              Rp.{commaFormatted(Total(row))}
            </td>
          </tr>,
        ]);
      }
    });
    return rows;
  }

  renderSummary(type, semester) {
    const { rapbuSummary, selectedDivisi } = this.props;
    const { semesterOne, semesterTwo } = rapbuSummary;
    const semesterDataList = semester === 1 ? semesterOne : semesterTwo;
    const title = type === 'pengeluaran' ? 'Pengeluaran' : 'Pendapatan';

    const { plafondTotal = [], total = 0 } = countSummary(type, [semesterDataList], selectedDivisi);

    return (
      <tr className="total">
        <td className="total" colSpan={5}>
          Jumlah {title}
        </td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[1])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[2])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[3])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[4])}</td>
        <td>
          <center> - </center>
        </td>
        <td className="nominal" style={{ width: '145px' }}>
          Rp.{commaFormatted(total)}
        </td>
      </tr>
    );
  }

  renderKetTotalPengeluaran() {
    const {
      rapbuSummary, selectedDivisi,
    } = this.props;
    const { semesterOne, semesterTwo } = rapbuSummary;
    const summaryRowColSpan = 5;

    const { plafondTotal = {}, total = 0 } = countSummary('pengeluaran', [
      semesterOne, semesterTwo,
    ], selectedDivisi);
    return (
    // <div>
    // <table>

      <tr className="summary total">
        <td className="total" colSpan={summaryRowColSpan}>
          Total Pengeluaran
        </td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[1])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[2])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[3])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[4])}</td>

        <td>
          <center> - </center>
        </td>
        <td className="nominal" style={{ width: '145px' }}>
          Rp.{commaFormatted(total)}
        </td>
      </tr>
    );
  }

  renderHeader() {
    const { workflow, user } = this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;

    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    return (
      <thead>
        <tr>
          <th className="manage-budget__row" rowSpan={2}>
            Kode Akun
          </th>
          <th rowSpan={3}>Nama Account</th>
          <th rowSpan={2}>Nama Tema</th>
          <th rowSpan={2}>Target</th>
          <th rowSpan={2}>Waktu Pelaksanaan</th>
          <th colSpan={hasCheckbox ? 3 : 4}>Alokasi Anggaran</th>
          <th rowSpan={2}>Upload File</th>
          <th rowSpan={6}>Total</th>
        </tr>
        <tr>
          <th>Rutin</th>
          <th>Kegiatan</th>
          {/* <th>Dana Pemerintah</th> */}
          <th>
            Dana <strong>BOS</strong>
          </th>
          <th>Biaya Pengembangan Pendidikan</th>
          {hasCheckbox && (
            <th rowSpan="2">
              <Input
                type="checkbox"
                name="check_all"
                data-pos="committee"
                onClick={this.onCheckAll}
              />
            </th>
          )}
          {hasCheckbox && (
            <th rowSpan="2">
              <Input
                type="checkbox"
                name="check_all"
                data-pos="intern"
                onClick={this.onCheckAll}
              />
            </th>
          )}
          {hasCheckbox && (
            <th rowSpan="2">
              <Input
                type="checkbox"
                name="check_all"
                data-pos="bos"
                onClick={this.onCheckAll}
              />
            </th>
          )}
        </tr>
      </thead>
    );
  }

  render() {
    const { workflow, user } = this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    const tableColSpan = isPusat && !is_done ? 18 : 18;

    return (
      <div className="manage-budget__table-wrapper">
        <table className="manage-budget__form-table table">
          {this.renderHeader()}

          <tbody>
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester II Tahun Ajaran Berjalan</h4>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pengeluaran</h4>
                </div>
              </td>
            </tr>
            {this.renderRows('pengeluaran', 2)}
            {this.renderSummary('pengeluaran', 2)}
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester I Tahun Ajaran Berikutnya</h4>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pengeluaran</h4>
                </div>
              </td>
            </tr>
            {this.renderRows('pengeluaran', 1)}
            {this.renderSummary('pengeluaran', 1)}
            {this.renderKetTotalPengeluaran()}
          </tbody>
        </table>
      </div>
    );
  }
}

BudgetRapbu.propTypes = {
  onCheckAll: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  onRemoveFile: PropTypes.func,
  rapbuSummary: PropTypes.object.isRequired,
  user: PropTypes.object,
  workflow: PropTypes.object,
  onKesiswaan: PropTypes.func.isRequired,
  onKurikulum: PropTypes.func.isRequired,
  onABP: PropTypes.func.isRequired,
  onManagerial: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  selectedDivisi: PropTypes.string.isRequired,
};
BudgetRapbu.defaultProps = {
  onCheckAll: noop,
  handleFileUpload: noop,
  handleFileDownload: noop,
  onRemoveFile: noop,
  user: null,
  workflow: null,
};
