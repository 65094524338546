import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { Input, SearchSelect } from '../base';
import Breadcrumb from '../Breadcrumb/Breadcrumb.component';
//import logo from '../../assets/img/SIMPeeL.png';
import demoLogo from '../../assets/img/logo_ricci.png';
//import yplLogo from '../../assets/img/logo-YPL.png';
import { HEADER_ICON } from '../../variables/common.variable';
import language from '../../languages';


class HeaderComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.onClickUnit = this.onClickUnit.bind(this);
  }

  onClickUnit(event) {
    const { setWorkingUnit } = this.props;
    const { target } = event;

    // console.log(event)
    target.value['id'] = target.value['value'];
    target.value['title'] = target.value['label'];
    setWorkingUnit(target.value);
    
  }

  render() {
    const {
      onClickMenu, pathname, onClickBreadcrumb, user, onSearchUnit,
    } = this.props;
    const { school_unit, user_group, workingUnit } = user;
    // const activeUnit =
    //   Object.values(workingUnit).length > 0 ? workingUnit : school_unit;
    // const { id: activeUnitId } = activeUnit;

    
    let unitCode = ''; 

   

    let defaultValue = {
      id: '0',
      title: 'SEMUA UNIT',
      value: '0',
      label: 'SEMUA UNIT'
    };

    if(workingUnit && workingUnit.attributes) {
      unitCode = workingUnit.attributes.unit_code;
      defaultValue = {
        id: workingUnit.attributes.id,
        title: workingUnit.attributes.name,
      }
      
    } else if(school_unit && school_unit.unit_code) {
      unitCode = school_unit.unit_code;
      
      if(school_unit.id!==9){
        defaultValue = {
          value: school_unit.id,
          label: school_unit.name,
        } 
        
      }
      
    }

    
    

    return (
      <header className="header">
        <div className="header__top">
          <div className="header__top-left">
            {/* <img className="header__logo" src={logo} alt="logo" /> */}
            <div className="header__title">
              <p>KODE UNIT:</p>
              <p>{unitCode}</p>
            </div>
          </div>
          <div className="header__top-right">
            <form className="header__search-form">
              <Input
                noMargin
                theme="line"
                leftIcon="icon-search"
                placeholder="Search here.."
                value=""
              />
            </form>
            {
              map(HEADER_ICON, (list, idx) => (
                <button
                  key={`icon_header__${idx}`}
                  className="header__icon"
                  onClick={onClickMenu}
                >
                  <span className={list.icon} />
                </button>
              ))
            }
            <button
              onClick={onClickMenu}
            >
              <img className="header__demo-logo" src={demoLogo} alt="Demo Logo" />
            </button>
          </div>
        </div>
        <div className="header__bottom">
          <div className="header__top-left" />
          <div className="header__bottom-right">
            <p className="header__greeting">{language.translate.COMMON__WELCOME}   {user.email}</p>
            <Breadcrumb pathname={pathname} onClick={onClickBreadcrumb} />
            {
              (user_group.id === 12 || user_group.id === 19
                // ||  school_unit.id === 14 || school_unit.id === 2
                ) && (
                <div className="unit_selector">
                  <SearchSelect
                    async
                    onSearch={onSearchUnit}
                    onClick={this.onClickUnit}
                    labelName="label"
                    valueName="value"
                    placeholder="Pilih Unit Kerja"
                    value={user.workingUnit|| defaultValue}
                  />
               
                </div>
              )
            }
           
          </div>
        </div>
      </header>
    );
  }
}

export default function Header(props) {
  return (
    <HeaderComponent {...props} />
  );
}

HeaderComponent.propTypes = {
  onClickMenu: PropTypes.func,
  onClickBreadcrumb: PropTypes.func,
  onSearchUnit: PropTypes.func,
  setWorkingUnit: PropTypes.func,
  pathname: PropTypes.string.isRequired,
  user: PropTypes.object,
};

HeaderComponent.defaultProps = {
  onClickMenu: noop,
  onClickBreadcrumb: noop,
  onSearchUnit: noop,
  setWorkingUnit: noop,
  user: null,
};
