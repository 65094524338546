import logo from '../assets/img/logo-kopsurat-ricci.png';

export function printDetailBank() {
  const divToPrint = document.getElementById('detail-bank-journal');
  let htmlToPrint = `
  <style type="text/css">
  @media print {
    @page {size: A5 landscape;}

    .pagebreak {
      clear: both;
      page-break-after: always;
    }

    table th, table td {
        border:1px solid #000;
        padding;0.5em;
    }
    .detail-bank-journal__content {
      margin-top: 40px;
    }
  }
    .detail-bank-journal__header {
       padding: 20px;
       display: flex;
       flex-direction: column;
       justify-content: center;
       border: 1px solid black;
    }
    .detail-bank-journal__header-top {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       align-items: center;
    }
     .detail-bank-journal__header-top--left {
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__header-top--right {
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__header-title {
     margin-top: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
    }
     .detail-bank-journal__body {
     margin-top: 10px;
    }
     .detail-bank-journal__table {
     border-collapse: collapse;
     width: 100%;
     margin-bottom: 40px;
    }
     .detail-bank-journal__table td, .detail-bank-journal__table th {
     border: 1px solid black;
     padding: 8px;
    }
     .detail-bank-journal__table tr td:nth-child(1), .detail-bank-journal__table tr th:nth-child(1) {
     width: 1%;
    }
     .detail-bank-journal__table td {
     font-size: 12px;
    }
     .detail-bank-journal__table th {
     font-size: 12px;
     padding-top: 12px;
     padding-bottom: 12px;
     text-align: left;
    }
     .detail-bank-journal__footer {
     padding: 10px 0 0 0;
     border: 1px solid black;
    }
     .detail-bank-journal__footer-top {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
    }
     .detail-bank-journal__footer-top--left {
     padding: 0 10px;
     flex: 3;
     display: flex;
     flex-direction: column;
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__footer-top--right {
     flex: 2;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
    }
     .detail-bank-journal__footer-top--right p {
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__footer-top--total {
     padding: 0 10px;
     display: flex;
     justify-content: space-between;
     border-bottom: 1px solid black;
    }
     .detail-bank-journal__footer-bottom {
     margin-top: 10px;
     display: flex;
     flex-direction: row;
     border-top: 1px solid black;
    }
     .detail-bank-journal__footer-bottom div {
     min-height: 10rem;
     padding: 10px;
     display: flex;
     flex-direction: column;
     flex: 1;
     justify-content: space-between;
     align-items: center;
     font-size: 14px;
    }
     .detail-bank-journal__footer-bottom div p:nth-child(2) {
     font-weight: bold;
    }
     .detail-bank-journal__footer-bottom div:not(:last-child) {
     border-right: 1px solid black;
    }
  </style>
  <div id="header_t">HEADER</div>
  <div id="footer_t">FOOTER</div>`;
  htmlToPrint += divToPrint.outerHTML;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}
export function printJournalKAS(headerClass, contentClass, footerclass, options) {
  const header = (headerClass) ? document.getElementsByClassName(headerClass)[0].outerHTML : '';
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const footer = (footerclass) ? document.getElementsByClassName(footerclass)[0].outerHTML : '';

  let defaults = {
    pageSize: 'A5 landscape',
    printHeader: {
      repeated: true,
    },
  };
  if (options) {
    defaults = { ...options };
  }
  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: ${defaults.pageSize};
      margin-top: 50px;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    table tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead#print-table-header{
      display:${(defaults.printHeader.repeated) ? 'table-header-group' : 'table-row-group'};
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead#print-table-header > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody#print-table-content > tr > td > table td,
    #print-container > tbody#print-table-content > tr > td > table th,
    #print-container > tfoot#print-table-content  > tr > td > div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody#print-table-content > tr > td > table > thead {
      display: table-row-group !important;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }

    #print-table-footer {
      display: table-footer-group;
    }

    #print-table-footer #page-counter{
      counter-increment: page;
      content: "Hal " counter(page) "/" counter(pages);
    }

    .page-break-avoid {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }
  }

  </style>

  <table id="print-container">
    <thead id="print-table-header">
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>
    <tbody id="print-table-content">
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>
    <tfoot id="print-table-footer">
      <tr>
        <td>
        ${footer}
        <td>
      </tr>
    </tfoot>
  </table>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}
// export function printJournal(headerClass, contentClass, contentClass2, contentClass3,  options) {
export function printJournal(
  headerClass, contentClass, contentClass2, contentClass3, contentClass4, options,
) {
  const header = (headerClass) ? document.getElementsByClassName(headerClass)[0].outerHTML : '';
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const content2 = document.getElementsByClassName(contentClass2)[0].outerHTML;
  const content3 = document.getElementsByClassName(contentClass3)[0].outerHTML;
  const content4 = document.getElementsByClassName(contentClass4)[0].outerHTML;


  let defaults = {
    pageSize: 'A4 landscape',
    printHeader: {
      repeated: true,
    },
  };
  if (options) {
    defaults = { ...options };
  }
  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: ${defaults.pageSize};
      margin-top: 50px;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table4 {
      margin-top : 50rem;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #000 !important;
    }

    table2 {
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    table tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead#print-table-header{
      display:${(defaults.printHeader.repeated) ? 'table-header-group' : 'table-row-group'};
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead#print-table-header > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody#print-table-content > tr > td > table td,
    #print-container > tbody#print-table-content > tr > td > table th,
    #print-container > tfoot#print-table-content  > tr > td > div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody#print-table-content > tr > td > table > thead {
      display: table-row-group !important;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }

    #print-table-footer {
      display: table-footer-group;
    }

    #print-table-footer #page-counter{
      counter-increment: page;
      content: "Hal " counter(page) "/" counter(pages);
    }

    .page-break-avoid {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }
  }

  </style>

  <table id="print-container">
    <thead id="print-table-header">
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>

    <tbody id="print-table2-content">
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>
    <tbody id="print-table-content">
      <tr>
        <td>${content2}</td>
      </tr>
    </tbody>

    <tbody id="print-table-content">
      <tr>
        <td>${content3}</td>
      </tr>
    </tbody>


    <tbody id="print-table4-content">
      <tr>
        <td>${content4}</td>
      </tr>
    </tbody>

  </table>`;

  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}

export function printDetailRapbs(headerClass, contentClass, options) {
  const header = (headerClass) ? document.getElementsByClassName(headerClass)[0].outerHTML : '';
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  // const footer = (footerclass) ? document.getElementsByClassName(footerclass)[0].outerHTML : '';

  let defaults = {
    pageSize: 'A5 landscape',
    printHeader: {
      repeated: true,
    },
  };
  if (options) {
    defaults = { ...options };
  }
  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: ${defaults.pageSize};
      margin-top: 50px;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    table tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead#print-table-header{
      display:${(defaults.printHeader.repeated) ? 'table-header-group' : 'table-row-group'};
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead#print-table-header > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody#print-table-content > tr > td > table td,
    #print-container > tbody#print-table-content > tr > td > table th,
    #print-container > tfoot#print-table-content  > tr > td > div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody#print-table-content > tr > td > table > thead {
      display: table-row-group !important;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }

    #print-table-footer {
      display: table-footer-group;
    }

    #print-table-footer #page-counter{
      counter-increment: page;
      content: "Hal " counter(page) "/" counter(pages);
    }

    .page-break-avoid {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }
  }

  </style>

  <table id="print-container">
    <thead id="print-table-header">
      <tr>
        <th>
            ${header}
        </th>
      </tr>
    </thead>
    <tbody id="print-table-content">
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>

  </table>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}

export function printRekapRapbs(headerClass, contentClass, options) {
  const header = (headerClass) ? document.getElementsByClassName(headerClass)[0].outerHTML : '';
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  // const footer = (footerclass) ? document.getElementsByClassName(footerclass)[0].outerHTML : '';

  let defaults = {
    pageSize: 'A5 landscape',
    printHeader: {
      repeated: true,
    },
  };
  if (options) {
    defaults = { ...options };
  }
  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: ${defaults.pageSize};
      margin-top: 50px;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    table tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead#print-table-header{
      display:${(defaults.printHeader.repeated) ? 'table-header-group' : 'table-row-group'};
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead#print-table-header > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody#print-table-content > tr > td > table td,
    #print-container > tbody#print-table-content > tr > td > table th,
    #print-container > tfoot#print-table-content  > tr > td > div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody#print-table-content > tr > td > table > thead {
      display: table-row-group !important;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }

    #print-table-footer {
      display: table-footer-group;
    }

    #print-table-footer #page-counter{
      counter-increment: page;
      content: "Hal " counter(page) "/" counter(pages);
    }

    .page-break-avoid {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }
  }

  </style>

  <table id="print-container">
    <thead id="print-table-header">
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>
    <tbody id="print-table-content">
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>

  </table>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}


export function printBukuInduk(headerClass, contentClass, footerclass) {
  const header = document.getElementsByClassName(headerClass)[0].outerHTML;
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const footer = document.getElementsByClassName(footerclass)[0].outerHTML;

  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: A5 landscape;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead{
      display:table-header-group;
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody table td,
    #print-container > tbody table th,
    #print-container > tfoot div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }
  }
  </style>

  <table id="print-container">
    <thead>
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>
  </table>
  <div id="footer">
    ${footer}
  </div>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}


export function printLaporan8355(headerClass, contentClass, footerclass) {
  const header = document.getElementsByClassName(headerClass)[0].outerHTML;
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const footer = document.getElementsByClassName(footerclass)[0].outerHTML;

  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: A5 landscape;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead{
      display:table-header-group;
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody table td,
    #print-container > tbody table th,
    #print-container > tfoot div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }
  }
  </style>

  <table id="print-container">
    <thead>
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>
  </table>
  <div id="footer">
    ${footer}
  </div>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}

export function printLaporanDispliner(headerClass, contentClass, footerclass) {
  const header = document.getElementsByClassName(headerClass)[0].outerHTML;
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const footer = document.getElementsByClassName(footerclass)[0].outerHTML;

  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: A5 landscape;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead{
      display:table-header-group;
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody table td,
    #print-container > tbody table th,
    #print-container > tfoot div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }
  }
  </style>

  <table id="print-container">
    <thead>
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>
  </table>
  <div id="footer">
    ${footer}
  </div>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}


export function printObservasi(headerClass, contentClass, footerclass, options) {
  const header = (headerClass) ? document.getElementsByClassName(headerClass)[0].outerHTML : '';
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const footer = (footerclass) ? document.getElementsByClassName(footerclass)[0].outerHTML : '';

  let defaults = {
    pageSize: 'A5 landscape',
    printHeader: {
      repeated: true,
    },
  };
  if (options) {
    defaults = { ...options };
  }
  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: ${defaults.pageSize};
      margin-top: 50px;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    table tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead#print-table-header{
      display:${(defaults.printHeader.repeated) ? 'table-header-group' : 'table-row-group'};
      margin-bottom:5mm;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead#print-table-header > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody#print-table-content > tr > td > table td,
    #print-container > tbody#print-table-content > tr > td > table th,
    #print-container > tfoot#print-table-content  > tr > td > div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody#print-table-content > tr > td > table > thead {
      display: table-row-group !important;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }

    #print-table-footer {
      display: table-footer-group;
    }

    #print-table-footer #page-counter{
      counter-increment: page;
      content: "Hal " counter(page) "/" counter(pages);
    }

    .page-break-avoid {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }
  }

  </style>

  <table id="print-container">
    <thead id="print-table-header">
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>
    <tbody id="print-table-content">
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>
    <tfoot id="print-table-footer">
      <tr>
        <td>
        ${footer}
        <td>
      </tr>
    </tfoot>
  </table>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}

export function printRKB(
  headerClass, headerClass3, headerClass2, contentClass, contentClass2, footerclass,
  creator, approvers, workflow,
) {
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const content2 = document.getElementsByClassName(contentClass2)[0].outerHTML;
  const uri = `${window.location.origin}`;
  const htmlToPrint = `
  <table id="print-container">
    <thead>
      <tr>
        <th id="page-header">
          <table className="fund-request-bottom__table-head">
            <tbody>
              <tr>
                <td id="title">
                  <img src="${uri}/img/logo-kopsurat-ricci.png" id="logo"/> YAYAYASAN RICCI
                </td>
              </tr>
              <tr id="subtitle">
                <td>Jl. Kemenangan III/47, Glodok, Tamansari, Jakarta Barat 11120
                  <br />
                  Telp. (021) 6392495 - 6260049 Fax. (021) 6491705
                </td>
              </tr>
            </tbody>
          </table>
        </th>
      </tr>
    </thead>
    <tbody id="print-table-content">
      <tr>
        <td>${content}</td>
      </tr>
      <tr>
        <td>${content2}</td>
      </tr>
    </tbody>
    <tfoot>
      <table id="signature">
        <tbody>
          <tr>
            <td />
            <td>Hormat kami,</td>
            <td>Menyetujui,</td>
            <td />
            <td>Mengetahui,</td>
            <td />
          </tr>
          <tr class="space">
            <td />
            <td>${creator}</td>
            <td>${approvers['Manager Opsdik']}</td>
            <td>${approvers.Keuangan}</td>
            <td>${approvers.Pengadaan}</td>
            <td>${approvers.RT}</td>
          </tr>
          <tr>
            <td />
            <td>${workflow[0] && workflow[0].prev_role}</td>
            <td>Manager OPSDIK</td>
            <td>Keuangan</td>
            <td>Pengadaan</td>
            <td>Rumah Tangga</td>
          </tr>
        </tbody>
      </table>
    </tfoot>
  </table>`;
  const popup = window.open('', 'popup');
  popup.document.write('<html><head><title>Yayasan Ricci</title>');
  popup.document.write('<link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">');
  popup.document.write(`<link rel="stylesheet" type="text/css" href="${uri}/css/print.css" media="print">`);
  popup.document.write('</head><body>');
  popup.document.write(htmlToPrint);
  popup.document.write('<script type="text/javascript">addEventListener("load", () => { print(); close(); })</script>');
  popup.document.write('</body></html>');
  popup.document.close();
  popup.focus();
}
