import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty, filter } from "lodash";
import SelectCoa from "../../../../../components/SelectCoa/SelectCoa.component";
import { commaFormatted } from "../../../../../utils/transformer.util";
import { Button, Input, Pagination } from "../../../../../components/base";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const userGroupPusat = ["Korektor Pusat", "Manager Keuangan", "Bendahara"];

export default class BudgetRapbuRekapExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // content: initialContent,
      // listAmount: 10,
      form: {
        value: {
          tema: "",
        },
      },
      selectedTema: "",
    };

    this.setRef = this.setRef.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderExcel = this.renderExcel.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  setRef(el) {
    const { input } = el;
    const { dataset } = input;
    const { pos, referenceid } = dataset;
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  onSearchTema(tema = null) {
    // let divisiValue = this.state.selectedDivisi?this.state.selectedDivisi:2;
    const { handleGetTemaOptions } = this.props;
    const { target } = tema;
    const { value } = target;
    handleGetTemaOptions({
      tema,
      value,
      // divisiValue
    });

    this.setState({ selectedTema: target.value });
    // console.log(this.props)
    // console.log(value)
    // console.log(divisiValue)
    // console.log(this.state.selectedTema)
  }

  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)

    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
    // console.log(dataset.arrayPosition)
  }

  renderExcel(list, budgetloop) {
    const { recommendations, user, onSelectBudgetDetail, onSearchTema } =
      this.props;
    const { user_group } = user;
    const isBendahara = user_group && user_group.name === "Bendahara";
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    console.log(userGroupPusat.includes(user_group.name));
    let excelTbl = [];

    if (list.length !== undefined) {
      map(list, (data, idx) => {
        map(data.header_tema, (data_header_tema, idx_header_tema) => {
          // console.log(data.divisi)
          let items = Object.create({});
          items.code = data.divisi + " - " + data_header_tema.code_header_tema;
          // items.code = data_header_tema.code_header_tema;
          items.name = data_header_tema.name;

          excelTbl.push(items);

          let total2 = 0;
          let total3 = 0;
          let total4 = 0;
          // renew excel file
          if (data_header_tema.sub_header_tema.length == 0) {
            map(data_header_tema.tema, (data_tema, idx_tema) => {
              let execution_time = "";
              let total = 0;
              let target = "";
              let i = "";
              let a = "";
              let nominal = 0;
              let rutinNominal = 0;
              let kegiatanNominal = 0;
              let bppNominal = 0;
              let danaNominal = 0;
              let plafond_id = "";

              for (i = 0; i < budgetloop.length; i++) {
                if (budgetloop[i].tema == data_tema.id) {
                  execution_time = budgetloop[i].execution_time;
                  // total += Number(budgetloop[i].total);
                  if (budgetloop[i].target != null) {
                    target = budgetloop[i].target;
                  }
                  for (a = 0; a < budgetloop[i].allocations.length; a++) {
                    plafond_id = budgetloop[i].allocations[a].plafond_id;
                    nominal = budgetloop[i].allocations[a].nominal;
                    if (plafond_id === 1) {
                      rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                    } else if (plafond_id === 2) {
                      kegiatanNominal =
                        parseInt(kegiatanNominal) + parseInt(nominal);
                    } else if (plafond_id === 3) {
                      danaNominal = parseInt(danaNominal) + parseInt(nominal);
                    } else if (plafond_id === 4) {
                      bppNominal = parseInt(bppNominal) + parseInt(nominal);
                    }
                  }
                  total =
                    rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                }
              }
              total2 += total;

              // console.log(total)
              let items = Object.create({});

              items.code = data_tema.code;
              items.name = data_tema.name;
              items.execution_time = execution_time;
              items.target = target;
              items.rutinNominal = "Rp" + "." + commaFormatted(rutinNominal);
              items.kegiatanNominal =
                "Rp" + "." + commaFormatted(kegiatanNominal);
              items.danaNominal = "Rp" + "." + commaFormatted(danaNominal);
              items.bppNominal = "Rp" + "." + commaFormatted(bppNominal);
              items.total = "Rp" + "." + commaFormatted(total);

              excelTbl.push(items);
            });

            for (let t = 0; t < 1; t++) {
              let items = Object.create({});
              items.bppNominal = "Total";
              items.total = "Rp" + "." + commaFormatted(total2);

              excelTbl.push(items);
            }
          } else if (data_header_tema.tema.length == 0) {
            map(
              data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                let total2 = 0;
                let totalht2 = 0;
                map(data_header_tema.tema, (data_tema, idx_tema) => {
                  let execution_time = "";
                  let total = 0;
                  let target = "";
                  let i = "";
                  let a = "";
                  let nominal = 0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";

                  for (i = 0; i < budgetloop.length; i++) {
                    // console.log(budgetloop[i].length)
                    if (budgetloop[i].tema == data_tema.id) {
                      // console.log(budgetloop[i].execution_time)
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if (budgetloop[i].target != null) {
                        target = budgetloop[i].target;
                      }
                      for (a = 0; a < budgetloop[i].allocations.length; a++) {
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;
                        if (plafond_id === 1) {
                          rutinNominal =
                            parseInt(rutinNominal) + parseInt(nominal);
                        } else if (plafond_id === 2) {
                          kegiatanNominal =
                            parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if (plafond_id === 3) {
                          danaNominal =
                            parseInt(danaNominal) + parseInt(nominal);
                        } else if (plafond_id === 4) {
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                        // console.log(rutinNominal)
                      }
                      total =
                        rutinNominal +
                        kegiatanNominal +
                        danaNominal +
                        bppNominal;
                    }
                  }
                  totalht2 += total;
                });
                total3 += totalht2;

                let items = Object.create({});

                items.code = data_header_tema.code_sub_header_tema;
                items.name = data_header_tema.name;
                items.total = "Rp" + "." + commaFormatted(totalht2);

                excelTbl.push(items);
              }
            );

            for (let t = 0; t < 1; t++) {
              let items = Object.create({});
              items.bppNominal = "Total";
              items.total = "Rp" + "." + commaFormatted(total3);

              excelTbl.push(items);
            }

            map(
              data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                let total2 = 0;

                let items = Object.create({});
                items.code = data_header_tema.code_sub_header_tema;
                // items.code = data_header_tema.code_header_tema;
                items.name = data_header_tema.name;

                excelTbl.push(items);

                let execution_time = "";
                let total = 0;
                let target = "";
                let i = "";
                let a = "";
                let nominal = 0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";
                for (i = 0; i < budgetloop.length; i++) {
                  if (budgetloop[i].tema == data_header_tema.id) {
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if (budgetloop[i].target != null) {
                      target = budgetloop[i].target;
                    }
                    for (a = 0; a < budgetloop[i].allocations.length; a++) {
                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                      // console.log(plafond_id);
                      nominal = budgetloop[i].allocations[a].nominal;
                      if (plafond_id === 1) {
                        rutinNominal =
                          parseInt(rutinNominal) + parseInt(nominal);
                      } else if (plafond_id === 2) {
                        kegiatanNominal =
                          parseInt(kegiatanNominal) + parseInt(nominal);
                      } else if (plafond_id === 3) {
                        danaNominal = parseInt(danaNominal) + parseInt(nominal);
                      } else if (plafond_id === 4) {
                        bppNominal = parseInt(bppNominal) + parseInt(nominal);
                      }
                    }
                    total =
                      rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                  }
                }

                map(data_header_tema.tema, (data_tema, idx_tema) => {
                  let execution_time = "";
                  let total = 0;
                  let target = "";
                  let i = "";
                  let a = "";
                  let nominal = 0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";
                  for (i = 0; i < budgetloop.length; i++) {
                    // console.log(budgetloop[i].length)
                    if (budgetloop[i].tema == data_tema.id) {
                      // console.log(budgetloop[i].execution_time)
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if (budgetloop[i].target != null) {
                        target = budgetloop[i].target;
                      }
                      for (a = 0; a < budgetloop[i].allocations.length; a++) {
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;
                        if (plafond_id === 1) {
                          rutinNominal =
                            parseInt(rutinNominal) + parseInt(nominal);
                        } else if (plafond_id === 2) {
                          kegiatanNominal =
                            parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if (plafond_id === 3) {
                          danaNominal =
                            parseInt(danaNominal) + parseInt(nominal);
                        } else if (plafond_id === 4) {
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                      }
                      total =
                        rutinNominal +
                        kegiatanNominal +
                        danaNominal +
                        bppNominal;
                    }
                  }

                  total2 += total;

                  let items = Object.create({});

                  items.code = data_tema.code;
                  items.name = data_tema.name;
                  items.execution_time = execution_time;
                  items.target = target;
                  // items.rutinNominal = "Rp"+"."+commaFormatted(rutinNominal)+"cek";
                  items.rutinNominal =
                    "Rp" + "." + commaFormatted(rutinNominal);
                  items.kegiatanNominal =
                    "Rp" + "." + commaFormatted(kegiatanNominal);
                  items.danaNominal = "Rp" + "." + commaFormatted(danaNominal);
                  items.bppNominal = "Rp" + "." + commaFormatted(bppNominal);
                  items.total = "Rp" + "." + commaFormatted(total);

                  excelTbl.push(items);
                });
                for (let t = 0; t < 1; t++) {
                  let items = Object.create({});
                  items.bppNominal = "Total";
                  items.total = "Rp" + "." + commaFormatted(total2);
                  // items.total ="Rp"+"."+commaFormatted(total2)+"cek";

                  excelTbl.push(items);
                }
              }
            );

            // for (let t=0; t<1; t++){

            //   let items = Object.create({});
            //   items.bppNominal = "Total";
            //   // items.total ="Rp"+"."+commaFormatted(total2)+"cek";

            //   excelTbl.push(items);
            // }
          } else if (
            data_header_tema.sub_header_tema &&
            data_header_tema.tema
          ) {
            map(data_header_tema.tema, (data_tema, idx_tema) => {
              let execution_time = "";
              let total = 0;
              let target = "";
              let i = "";
              let a = "";
              let nominal = 0;
              let rutinNominal = 0;
              let kegiatanNominal = 0;
              let bppNominal = 0;
              let danaNominal = 0;
              let plafond_id = "";

              for (i = 0; i < budgetloop.length; i++) {
                if (budgetloop[i].tema == data_tema.id) {
                  execution_time = budgetloop[i].execution_time;
                  // total += Number(budgetloop[i].total);
                  if (budgetloop[i].target != null) {
                    target = budgetloop[i].target;
                  }

                  for (a = 0; a < budgetloop[i].allocations.length; a++) {
                    plafond_id = budgetloop[i].allocations[a].plafond_id;
                    // console.log(plafond_id);
                    nominal = budgetloop[i].allocations[a].nominal;

                    if (plafond_id === 1) {
                      rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                    } else if (plafond_id === 2) {
                      kegiatanNominal =
                        parseInt(kegiatanNominal) + parseInt(nominal);
                    } else if (plafond_id === 3) {
                      danaNominal = parseInt(danaNominal) + parseInt(nominal);
                    } else if (plafond_id === 4) {
                      bppNominal = parseInt(bppNominal) + parseInt(nominal);
                    }
                  }
                  total =
                    rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                }
              }

              total2 += total;
              total3 += total2;

              // console.log(total)
              let items = Object.create({});
              items.code = data_tema.code_sub_header_tema;
              items.code = data_tema.code;
              items.name = data_tema.name;
              items.execution_time = execution_time;
              items.target = target;
              items.rutinNominal = "Rp" + "." + commaFormatted(rutinNominal);
              items.kegiatanNominal =
                "Rp" + "." + commaFormatted(kegiatanNominal);
              items.danaNominal = "Rp" + "." + commaFormatted(danaNominal);
              items.bppNominal = "Rp" + "." + commaFormatted(bppNominal);
              items.total = "Rp" + "." + commaFormatted(total);

              excelTbl.push(items);
            });

            for (let t = 0; t < 1; t++) {
              let items = Object.create({});
              items.bppNominal = "Total";
              items.total2 = "Rp" + "." + commaFormatted(total3);

              excelTbl.push(items);
            }

            map(
              data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                let items = Object.create({});
                items.code =
                  data.divisi + " - " + data_header_tema.code_sub_header_tema;
                // items.code = data_header_tema.code_header_tema;
                items.name = data_header_tema.name;

                excelTbl.push(items);

                map(data_header_tema.tema, (data_tema, idx_tema) => {
                  let execution_time = "";
                  let total = 0;
                  let target = "";
                  let i = "";
                  let a = "";
                  let nominal = 0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";

                  for (i = 0; i < budgetloop.length; i++) {
                    if (budgetloop[i].tema == data_tema.id) {
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if (budgetloop[i].target != null) {
                        target = budgetloop[i].target;
                      }

                      for (a = 0; a < budgetloop[i].allocations.length; a++) {
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;

                        if (plafond_id === 1) {
                          rutinNominal =
                            parseInt(rutinNominal) + parseInt(nominal);
                        } else if (plafond_id === 2) {
                          kegiatanNominal =
                            parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if (plafond_id === 3) {
                          danaNominal =
                            parseInt(danaNominal) + parseInt(nominal);
                        } else if (plafond_id === 4) {
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                      }
                      total =
                        rutinNominal +
                        kegiatanNominal +
                        danaNominal +
                        bppNominal;
                    }
                  }
                  let items = Object.create({});

                  items.code = data_tema.code;
                  items.name = data_tema.name;
                  items.execution_time = execution_time;
                  items.target = target;
                  items.rutinNominal =
                    "Rp" + "." + commaFormatted(rutinNominal);
                  items.kegiatanNominal =
                    "Rp" + "." + commaFormatted(kegiatanNominal);
                  items.danaNominal = "Rp" + "." + commaFormatted(danaNominal);
                  items.bppNominal = "Rp" + "." + commaFormatted(bppNominal);
                  items.total = "Rp" + "." + commaFormatted(total);

                  excelTbl.push(items);
                });
                for (let t = 0; t < 1; t++) {
                  let items = Object.create({});
                  items.bppNominal = "Total";
                  items.total = "Rp" + "." + commaFormatted(total2);

                  excelTbl.push(items);
                }
              }
            );
          } else if (data_header_tema.sub_header_tema) {
            map(
              data_header_tema.sub_header_tema,
              (data_header_tema, idx_sub_header_tema) => {
                if (data_header_tema.prm_detail_header_tema) {
                  map(
                    data_header_tema.prm_detail_header_tema,
                    (data_header_tema, idx_header_tema) => {
                      map(data_header_tema.tema, (data_tema, idx_tema) => {
                        let execution_time = "";
                        let total = 0;
                        let target = "";
                        let i = "";
                        let a = "";
                        let nominal = 0;
                        let rutinNominal = 0;
                        let kegiatanNominal = 0;
                        let bppNominal = 0;
                        let danaNominal = 0;
                        let plafond_id = "";

                        for (i = 0; i < budgetloop.length; i++) {
                          if (budgetloop[i].tema == data_tema.id) {
                            execution_time = budgetloop[i].execution_time;
                            // total += Number(budgetloop[i].total);
                            if (budgetloop[i].target != null) {
                              target = budgetloop[i].target;
                            }

                            for (
                              a = 0;
                              a < budgetloop[i].allocations.length;
                              a++
                            ) {
                              plafond_id =
                                budgetloop[i].allocations[a].plafond_id;
                              // console.log(plafond_id);
                              nominal = budgetloop[i].allocations[a].nominal;

                              if (plafond_id === 1) {
                                rutinNominal =
                                  parseInt(rutinNominal) + parseInt(nominal);
                              } else if (plafond_id === 2) {
                                kegiatanNominal =
                                  parseInt(kegiatanNominal) + parseInt(nominal);
                              } else if (plafond_id === 3) {
                                danaNominal =
                                  parseInt(danaNominal) + parseInt(nominal);
                              } else if (plafond_id === 4) {
                                bppNominal =
                                  parseInt(bppNominal) + parseInt(nominal);
                              }
                            }
                            total =
                              rutinNominal +
                              kegiatanNominal +
                              danaNominal +
                              bppNominal;
                          }
                        }

                        let items = Object.create({});

                        items.code = data_tema.code;
                        items.name = data_tema.name;
                        items.execution_time = execution_time;
                        items.target = target;
                        items.rutinNominal =
                          "Rp" + "." + commaFormatted(rutinNominal);
                        items.kegiatanNominal =
                          "Rp" + "." + commaFormatted(kegiatanNominal);
                        items.danaNominal =
                          "Rp" + "." + commaFormatted(danaNominal);
                        items.bppNominal =
                          "Rp" + "." + commaFormatted(bppNominal);
                        items.total = "Rp" + "." + commaFormatted(total);

                        excelTbl.push(items);
                      });
                      for (let t = 0; t < 1; t++) {
                        let items = Object.create({});
                        items.bppNominal = "Total";
                        items.total = "Rp" + "." + commaFormatted(total2);

                        excelTbl.push(items);
                      }

                      if (data_header_tema.prm_sub_detail_header_tema) {
                        // console.log(data_header_tema.prm_sub_detail_header_tema)
                        map(
                          data_header_tema.prm_sub_detail_header_tema,
                          (data_header_tema, idx_header_tema) => {
                            map(
                              data_header_tema.tema,
                              (data_tema, idx_tema) => {
                                let execution_time = "";
                                let total = 0;
                                let target = "";
                                let i = "";
                                let a = "";
                                let nominal = 0;
                                let rutinNominal = 0;
                                let kegiatanNominal = 0;
                                let bppNominal = 0;
                                let danaNominal = 0;
                                let plafond_id = "";

                                for (i = 0; i < budgetloop.length; i++) {
                                  if (budgetloop[i].tema == data_tema.id) {
                                    execution_time =
                                      budgetloop[i].execution_time;
                                    // total += Number(budgetloop[i].total);
                                    target = budgetloop[i].target;

                                    for (
                                      a = 0;
                                      a < budgetloop[i].allocations.length;
                                      a++
                                    ) {
                                      plafond_id =
                                        budgetloop[i].allocations[a].plafond_id;
                                      // console.log(plafond_id);
                                      nominal =
                                        budgetloop[i].allocations[a].nominal;

                                      if (plafond_id === 1) {
                                        rutinNominal =
                                          parseInt(rutinNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 2) {
                                        kegiatanNominal =
                                          parseInt(kegiatanNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 3) {
                                        danaNominal =
                                          parseInt(danaNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 4) {
                                        bppNominal =
                                          parseInt(bppNominal) +
                                          parseInt(nominal);
                                      }
                                    }
                                    total =
                                      rutinNominal +
                                      kegiatanNominal +
                                      danaNominal +
                                      bppNominal;
                                  }
                                }

                                let items = Object.create({});

                                items.code = data_tema.code;
                                items.name = data_tema.name;
                                items.execution_time = execution_time;
                                items.target = target;
                                items.rutinNominal =
                                  "Rp" + "." + commaFormatted(rutinNominal);
                                items.kegiatanNominal =
                                  "Rp" + "." + commaFormatted(kegiatanNominal);
                                items.danaNominal =
                                  "Rp" + "." + commaFormatted(danaNominal);
                                items.bppNominal =
                                  "Rp" + "." + commaFormatted(bppNominal);
                                items.total =
                                  "Rp" + "." + commaFormatted(total);

                                excelTbl.push(items);
                              }
                            );
                            for (let t = 0; t < 1; t++) {
                              let items = Object.create({});
                              items.bppNominal = "Total";
                              items.total = "Rp" + "." + commaFormatted(total2);

                              excelTbl.push(items);
                            }
                          }
                        );
                      } else {
                        // console.log(data_header_tema.code)
                        map(
                          data_header_tema.tema,
                          (data_header_tema, idx_tema) => {
                            let execution_time = "";
                            let total = 0;
                            let target = "";
                            let i = "";
                            let a = "";
                            let nominal = 0;
                            let rutinNominal = 0;
                            let kegiatanNominal = 0;
                            let bppNominal = 0;
                            let danaNominal = 0;
                            let plafond_id = "";

                            for (i = 0; i < budgetloop.length; i++) {
                              if (budgetloop[i].tema == data_header_tema.id) {
                                execution_time = budgetloop[i].execution_time;
                                // total += Number(budgetloop[i].total);
                                if (budgetloop[i].target != null) {
                                  target = budgetloop[i].target;
                                }

                                for (
                                  a = 0;
                                  a < budgetloop[i].allocations.length;
                                  a++
                                ) {
                                  plafond_id =
                                    budgetloop[i].allocations[a].plafond_id;
                                  // console.log(plafond_id);
                                  nominal =
                                    budgetloop[i].allocations[a].nominal;

                                  if (plafond_id === 1) {
                                    rutinNominal =
                                      parseInt(rutinNominal) +
                                      parseInt(nominal);
                                  } else if (plafond_id === 2) {
                                    kegiatanNominal =
                                      parseInt(kegiatanNominal) +
                                      parseInt(nominal);
                                  } else if (plafond_id === 3) {
                                    danaNominal =
                                      parseInt(danaNominal) + parseInt(nominal);
                                  } else if (plafond_id === 4) {
                                    bppNominal =
                                      parseInt(bppNominal) + parseInt(nominal);
                                  }
                                }
                                total =
                                  rutinNominal +
                                  kegiatanNominal +
                                  danaNominal +
                                  bppNominal;
                              }
                            }

                            let items = Object.create({});

                            items.code = data_header_tema.code;
                            items.name = data_header_tema.name;
                            items.execution_time = execution_time;
                            items.target = target;
                            items.rutinNominal =
                              "Rp" + "." + commaFormatted(rutinNominal);
                            items.kegiatanNominal =
                              "Rp" + "." + commaFormatted(kegiatanNominal);
                            items.danaNominal =
                              "Rp" + "." + commaFormatted(danaNominal);
                            items.bppNominal =
                              "Rp" + "." + commaFormatted(bppNominal);
                            items.total = "Rp" + "." + commaFormatted(total);

                            excelTbl.push(items);
                          }
                        );
                        for (let t = 0; t < 1; t++) {
                          let items = Object.create({});
                          items.bppNominal = "Total";
                          items.total = "Rp" + "." + commaFormatted(total2);

                          excelTbl.push(items);
                        }
                      }
                    }
                  );
                }
              }
            );
          }

          map(
            data_header_tema.sub_header_tema,
            (data_header_tema, idx_sub_header_tema) => {
              if (data_header_tema.prm_detail_header_tema) {
                map(
                  data_header_tema.prm_detail_header_tema,
                  (data_header_tema, idx_header_tema) => {
                    map(data_header_tema.tema, (data_tema, idx_tema) => {
                      let execution_time = "";
                      let total = 0;
                      let target = "";
                      let i = "";
                      let a = "";
                      let nominal = 0;
                      let rutinNominal = 0;
                      let kegiatanNominal = 0;
                      let bppNominal = 0;
                      let danaNominal = 0;
                      let plafond_id = "";

                      for (i = 0; i < budgetloop.length; i++) {
                        if (budgetloop[i].tema == data_tema.id) {
                          execution_time = budgetloop[i].execution_time;
                          // total += Number(budgetloop[i].total);
                          if (budgetloop[i].target != null) {
                            target = budgetloop[i].target;
                          }

                          for (
                            a = 0;
                            a < budgetloop[i].allocations.length;
                            a++
                          ) {
                            plafond_id =
                              budgetloop[i].allocations[a].plafond_id;
                            // console.log(plafond_id);
                            nominal = budgetloop[i].allocations[a].nominal;

                            if (plafond_id === 1) {
                              rutinNominal =
                                parseInt(rutinNominal) + parseInt(nominal);
                            } else if (plafond_id === 2) {
                              kegiatanNominal =
                                parseInt(kegiatanNominal) + parseInt(nominal);
                            } else if (plafond_id === 3) {
                              danaNominal =
                                parseInt(danaNominal) + parseInt(nominal);
                            } else if (plafond_id === 4) {
                              bppNominal =
                                parseInt(bppNominal) + parseInt(nominal);
                            }
                          }
                          total =
                            rutinNominal +
                            kegiatanNominal +
                            danaNominal +
                            bppNominal;
                        }
                      }

                      let items = Object.create({});

                      items.code = data_tema.code;
                      items.name = data_tema.name;
                      items.execution_time = execution_time;
                      items.target = target;
                      items.rutinNominal =
                        "Rp" + "." + commaFormatted(rutinNominal);
                      items.kegiatanNominal =
                        "Rp" + "." + commaFormatted(kegiatanNominal);
                      items.danaNominal =
                        "Rp" + "." + commaFormatted(danaNominal);
                      items.bppNominal =
                        "Rp" + "." + commaFormatted(bppNominal);
                      items.total = "Rp" + "." + commaFormatted(total);

                      excelTbl.push(items);
                    });
                    for (let t = 0; t < 1; t++) {
                      let items = Object.create({});
                      items.bppNominal = "Total";
                      items.total = "Rp" + "." + commaFormatted(total2);

                      excelTbl.push(items);
                    }

                    if (data_header_tema.prm_sub_detail_header_tema) {
                      // console.log(data_header_tema.prm_sub_detail_header_tema)
                      map(
                        data_header_tema.prm_sub_detail_header_tema,
                        (data_header_tema, idx_header_tema) => {
                          map(data_header_tema.tema, (data_tema, idx_tema) => {
                            let execution_time = "";
                            let total = 0;
                            let target = "";
                            let i = "";
                            let a = "";
                            let nominal = 0;
                            let rutinNominal = 0;
                            let kegiatanNominal = 0;
                            let bppNominal = 0;
                            let danaNominal = 0;
                            let plafond_id = "";

                            for (i = 0; i < budgetloop.length; i++) {
                              if (budgetloop[i].tema == data_tema.id) {
                                execution_time = budgetloop[i].execution_time;
                                // total += Number(budgetloop[i].total);
                                if (budgetloop[i].target != null) {
                                  target = budgetloop[i].target;
                                }

                                for (
                                  a = 0;
                                  a < budgetloop[i].allocations.length;
                                  a++
                                ) {
                                  plafond_id =
                                    budgetloop[i].allocations[a].plafond_id;
                                  // console.log(plafond_id);
                                  nominal =
                                    budgetloop[i].allocations[a].nominal;

                                  if (plafond_id === 1) {
                                    rutinNominal =
                                      parseInt(rutinNominal) +
                                      parseInt(nominal);
                                  } else if (plafond_id === 2) {
                                    kegiatanNominal =
                                      parseInt(kegiatanNominal) +
                                      parseInt(nominal);
                                  } else if (plafond_id === 3) {
                                    danaNominal =
                                      parseInt(danaNominal) + parseInt(nominal);
                                  } else if (plafond_id === 4) {
                                    bppNominal =
                                      parseInt(bppNominal) + parseInt(nominal);
                                  }
                                }
                                total =
                                  rutinNominal +
                                  kegiatanNominal +
                                  danaNominal +
                                  bppNominal;
                              }
                            }

                            let items = Object.create({});

                            items.code = data_tema.code;
                            items.name = data_tema.name;
                            items.execution_time = execution_time;
                            items.target = target;
                            items.rutinNominal =
                              "Rp" + "." + commaFormatted(rutinNominal);
                            items.kegiatanNominal =
                              "Rp" + "." + commaFormatted(kegiatanNominal);
                            items.danaNominal =
                              "Rp" + "." + commaFormatted(danaNominal);
                            items.bppNominal =
                              "Rp" + "." + commaFormatted(bppNominal);
                            items.total = "Rp" + "." + commaFormatted(total);

                            excelTbl.push(items);
                          });
                          for (let t = 0; t < 1; t++) {
                            let items = Object.create({});
                            items.bppNominal = "Total";
                            items.total = "Rp" + "." + commaFormatted(total2);

                            excelTbl.push(items);
                          }
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        });
      });

      let rows = [];
      rows.push([
        <ExcelFile
          element={<Button style={{ height: 30, width: 250 }}>Excel</Button>}
        >
          <ExcelSheet data={excelTbl} name="excel">
            {/* <ExcelColumn label="Kode" value="code2"/> */}
            <ExcelColumn label="Kode" value="code" />
            <ExcelColumn label="Nama" value="name" />
            <ExcelColumn label="Waktu Pelaksanaan" value="execution_time" />
            <ExcelColumn label="Target" value="target" />
            <ExcelColumn label="Rutin" value="rutinNominal" />
            <ExcelColumn label="Kegiatan" value="kegiatanNominal" />
            <ExcelColumn label="Dana Pemerintah" value="danaNominal" />
            <ExcelColumn
              label="Biaya Pengembangan Pendidikan"
              value="bppNominal"
            />
            <ExcelColumn label="Total" value="total" />
          </ExcelSheet>
        </ExcelFile>,
      ]);
      return rows;
    }
  }

  renderRows(list, budgetloop) {
    const { recommendations, user, onSelectBudgetDetail, onSearchTema } =
      this.props;
    const { user_group } = user;
    const isBendahara = user_group && user_group.name === "Bendahara";
    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    let rows = [];

    // console.log(list.length)
    if (list.length !== undefined) {
      // console.log(list.length)
      // console.log(list.data)
      // data muncul semua karaena kalo gak undefined

      map(list, (data, idx) => {
        map(data.header_tema, (data_header_tema, idx_header_tema) => {
          // console.log(data.header_tema)

          let totalHeader = 0;

          rows.push([
            <tr key={`budget_rapbu_rows_${idx}`}>
              <td colspan="10">
                <b>
                  {data.divisi +
                    " - " +
                    data_header_tema.code_header_tema +
                    " - " +
                    data_header_tema.name}
                </b>
              </td>
            </tr>,
          ]);

          let total2 = 0;
          let total3 = 0;
          if (data_header_tema.sub_header_tema.length == 0) {
            if (this.state.selectedTema.code) {
              let PengeluaranData = data_header_tema.tema.filter((o, index) => {
                return o.code === this.state.selectedTema.code;
              });
              map(PengeluaranData, (data_tema, idx_tema) => {
                let execution_time = "";
                let total = 0;
                let target = "";
                let i = "";
                let a = "";
                let nominal = 0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for (i = 0; i < budgetloop.length; i++) {
                  if (budgetloop[i].tema == data_tema.id) {
                    execution_time = budgetloop[i].execution_time;
                    total += Number(budgetloop[i].total);
                    if (budgetloop[i].target != null) {
                      target = budgetloop[i].target;
                    }
                    for (a = 0; a < budgetloop[i].allocations.length; a++) {
                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                      nominal = budgetloop[i].allocations[a].nominal;
                      if (plafond_id === 1) {
                        rutinNominal =
                          parseInt(rutinNominal) + parseInt(nominal);
                      } else if (plafond_id === 2) {
                        kegiatanNominal =
                          parseInt(kegiatanNominal) + parseInt(nominal);
                      } else if (plafond_id === 3) {
                        danaNominal = parseInt(danaNominal) + parseInt(nominal);
                      } else if (plafond_id === 4) {
                        bppNominal = parseInt(bppNominal) + parseInt(nominal);
                      }
                    }
                  }
                }

                total2 += total;
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    {/* aaaaa */}
                    <td>{data_tema.code}</td>
                    <td>{data_tema.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(rutinNominal)}
                    </td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(kegiatanNominal)}
                    </td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>,
                ]);
              });
            } else {
              map(data_header_tema.tema, (data_tema, idx_tema) => {
                let execution_time = "";
                let total = 0;
                let target = "";
                let i = "";
                let a = "";
                let nominal = 0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for (i = 0; i < budgetloop.length; i++) {
                  if (budgetloop[i].tema == data_tema.id) {
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if (budgetloop[i].target != null) {
                      target = budgetloop[i].target;
                    }
                    for (a = 0; a < budgetloop[i].allocations.length; a++) {
                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                      nominal = budgetloop[i].allocations[a].nominal;
                      if (plafond_id === 1) {
                        rutinNominal =
                          parseInt(rutinNominal) + parseInt(nominal);
                      } else if (plafond_id === 2) {
                        kegiatanNominal =
                          parseInt(kegiatanNominal) + parseInt(nominal);
                      } else if (plafond_id === 3) {
                        danaNominal = parseInt(danaNominal) + parseInt(nominal);
                      } else if (plafond_id === 4) {
                        bppNominal = parseInt(bppNominal) + parseInt(nominal);
                      }
                      console.log(rutinNominal);
                      console.log(bppNominal);
                      console.log(nominal);
                    }
                    total =
                      rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                  }
                }

                total2 += total;
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    {/* aaaaa */}
                    <td>{data_tema.code}</td>
                    <td>{data_tema.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(rutinNominal)}
                    </td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(kegiatanNominal)}
                    </td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>,
                ]);

                // }
              });
            }
            for (let t = 0; t < 1; t++) {
              rows.push([
                <tr key={`budget_rapbu_rows_${idx}`}>
                  <td colspan="10" className="nominal">
                    Rp. {commaFormatted(total2)}
                  </td>
                </tr>,
              ]);
            }
          } else if (data_header_tema.tema.length == 0) {
            if (this.state.selectedTema.code) {
              let PengeluaranData8 = data_header_tema.sub_header_tema.filter(
                (o, index) => {
                  return (
                    o.code_sub_header_tema === this.state.selectedTema.code
                  );
                }
              );

              map(PengeluaranData8, (data_header_tema, idx_sub_header_tema) => {
                let execution_time = "";
                let total = 0;
                let target = "";
                let i = "";
                let a = "";
                let nominal = 0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for (i = 0; i < budgetloop.length; i++) {
                  if (budgetloop[i].tema == data.id) {
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if (budgetloop[i].target != null) {
                      target = budgetloop[i].target;
                    }

                    // console.log(budgetloop)

                    for (a = 0; a < budgetloop[i].allocations.length; a++) {
                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                      nominal = budgetloop[i].allocations[a].nominal;
                      if (plafond_id === 1) {
                        rutinNominal =
                          parseInt(rutinNominal) + parseInt(nominal);
                      } else if (plafond_id === 2) {
                        kegiatanNominal =
                          parseInt(kegiatanNominal) + parseInt(nominal);
                      } else if (plafond_id === 3) {
                        danaNominal = parseInt(danaNominal) + parseInt(nominal);
                      } else if (plafond_id === 4) {
                        bppNominal = parseInt(bppNominal) + parseInt(nominal);
                      }
                    }
                    total =
                      rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                  }
                }
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td>{data.code_sub_header_tema}</td>
                    <td>{data.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(rutinNominal)}
                    </td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(kegiatanNominal)}
                    </td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>,
                ]);
              });
            } else {
              map(
                data_header_tema.sub_header_tema,
                (data_header_tema, idx_sub_header_tema) => {
                  let total2 = 0;
                  map(data_header_tema.tema, (data_tema, idx_tema) => {
                    let execution_time = "";
                    let total = 0;
                    let target = "";
                    let i = "";
                    let a = "";
                    let nominal = 0;
                    let rutinNominal = 0;
                    let kegiatanNominal = 0;
                    let bppNominal = 0;
                    let danaNominal = 0;
                    let plafond_id = "";

                    for (i = 0; i < budgetloop.length; i++) {
                      // console.log(budgetloop[i].length)
                      if (budgetloop[i].tema == data_tema.id) {
                        // console.log(budgetloop[i].execution_time)
                        execution_time = budgetloop[i].execution_time;
                        // total += Number(budgetloop[i].total);
                        if (budgetloop[i].target != null) {
                          target = budgetloop[i].target;
                        }
                        for (a = 0; a < budgetloop[i].allocations.length; a++) {
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;
                          if (plafond_id === 1) {
                            rutinNominal =
                              parseInt(rutinNominal) + parseInt(nominal);
                          } else if (plafond_id === 2) {
                            kegiatanNominal =
                              parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if (plafond_id === 3) {
                            danaNominal =
                              parseInt(danaNominal) + parseInt(nominal);
                          } else if (plafond_id === 4) {
                            bppNominal =
                              parseInt(bppNominal) + parseInt(nominal);
                          }
                          console.log(bppNominal);
                        }
                        total =
                          rutinNominal +
                          kegiatanNominal +
                          danaNominal +
                          bppNominal;
                      }
                    }
                    total2 += total;
                  });

                  total3 += total2;

                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td>{data_header_tema.code_sub_header_tema}</td>
                      <td>{data_header_tema.name}</td>
                      <td colSpan="7" className="nominal">
                        Rp.{commaFormatted(total2)}
                      </td>
                    </tr>,
                  ]);
                  console.log(total2);
                }
              );
            }

            // console.log(data_header_tema.sub_header_tema.length)

            for (let t = 0; t < 1; t++) {
              rows.push([
                <tr key={`budget_rapbu_rows_${idx}`}>
                  <td colspan="10" className="nominal">
                    Rp. {commaFormatted(total3)}
                  </td>
                </tr>,
              ]);
            }

            map(
              data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                let total2 = 0;
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td colspan="10">
                      <b>
                        {data.divisi +
                          " - " +
                          data_header_tema.code_sub_header_tema +
                          " - " +
                          data_header_tema.name}
                      </b>
                    </td>
                  </tr>,
                ]);

                if (this.state.selectedTema.code) {
                  let PengeluaranData2 = data_header_tema.tema.filter(
                    (o, index) => {
                      return o.code === this.state.selectedTema.code;
                    }
                  );

                  // console.log(data.divisi + " - " +data_header_tema.name)
                  map(PengeluaranData2, (data_tema, idx_tema) => {
                    let execution_time = "";
                    let total = 0;
                    let target = "";
                    let i = "";
                    let a = "";
                    let nominal = 0;
                    let rutinNominal = 0;
                    let kegiatanNominal = 0;
                    let bppNominal = 0;
                    let danaNominal = 0;
                    let plafond_id = "";
                    for (i = 0; i < budgetloop.length; i++) {
                      // console.log(budgetloop[i].length)
                      if (budgetloop[i].tema == data_tema.id) {
                        // console.log(budgetloop[i].execution_time)
                        execution_time = budgetloop[i].execution_time;
                        // total += Number(budgetloop[i].total);
                        if (budgetloop[i].target != null) {
                          target = budgetloop[i].target;
                        }
                        for (a = 0; a < budgetloop[i].allocations.length; a++) {
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;
                          if (plafond_id === 1) {
                            rutinNominal =
                              parseInt(rutinNominal) + parseInt(nominal);
                          } else if (plafond_id === 2) {
                            kegiatanNominal =
                              parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if (plafond_id === 3) {
                            danaNominal =
                              parseInt(danaNominal) + parseInt(nominal);
                          } else if (plafond_id === 4) {
                            bppNominal =
                              parseInt(bppNominal) + parseInt(nominal);
                          }
                          // console.log(rutinNominal)
                        }
                        total =
                          rutinNominal +
                          kegiatanNominal +
                          danaNominal +
                          bppNominal;
                      }
                    }
                    total2 += total;
                    rows.push([
                      <tr key={`budget_rapbu_rows_${idx}`}>
                        <td>{data_tema.code}</td>
                        <td>{data_tema.name}</td>
                        <td>{execution_time}</td>
                        <td>{target}</td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(rutinNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(kegiatanNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(danaNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(bppNominal)}
                        </td>
                        <td className="nominal">
                          Rp.{commaFormatted(total)}dfg
                        </td>
                      </tr>,
                    ]);
                  });
                } else {
                  // console.log(data.divisi + " - " +data_header_tema.name)
                  map(data_header_tema.tema, (data_tema, idx_tema) => {
                    let execution_time = "";
                    let total = 0;
                    let target = "";
                    let i = "";
                    let a = "";
                    let nominal = 0;
                    let rutinNominal = 0;
                    let kegiatanNominal = 0;
                    let bppNominal = 0;
                    let danaNominal = 0;
                    let plafond_id = "";
                    for (i = 0; i < budgetloop.length; i++) {
                      // console.log(budgetloop[i].length)
                      if (budgetloop[i].tema == data_tema.id) {
                        // console.log(budgetloop[i].execution_time)
                        execution_time = budgetloop[i].execution_time;
                        // total += Number(budgetloop[i].total);
                        if (budgetloop[i].target != null) {
                          target = budgetloop[i].target;
                        }
                        for (a = 0; a < budgetloop[i].allocations.length; a++) {
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;
                          if (plafond_id === 1) {
                            rutinNominal =
                              parseInt(rutinNominal) + parseInt(nominal);
                          } else if (plafond_id === 2) {
                            kegiatanNominal =
                              parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if (plafond_id === 3) {
                            danaNominal =
                              parseInt(danaNominal) + parseInt(nominal);
                          } else if (plafond_id === 4) {
                            bppNominal =
                              parseInt(bppNominal) + parseInt(nominal);
                          }
                          // console.log(rutinNominal)
                        }
                        total =
                          rutinNominal +
                          kegiatanNominal +
                          danaNominal +
                          bppNominal;
                      }
                    }
                    total2 += total;
                    rows.push([
                      <tr key={`budget_rapbu_rows_${idx}`}>
                        <td>{data_tema.code}</td>
                        <td>{data_tema.name}</td>
                        <td>{execution_time}</td>
                        <td>{target}</td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(rutinNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(kegiatanNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(danaNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(bppNominal)}
                        </td>
                        <td className="nominal">Rp.{commaFormatted(total)}</td>
                      </tr>,
                    ]);
                  });
                }
                // console.log(total2)
                for (let t = 0; t < 1; t++) {
                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td colspan="10" className="nominal">
                        Rp. {commaFormatted(total2)}
                      </td>
                    </tr>,
                  ]);
                }
              }
            );
          } else if (
            data_header_tema.sub_header_tema &&
            data_header_tema.tema
          ) {
            if (this.state.selectedTema.code) {
              let PengeluaranData9 =
                data_header_tema.sub_header_tema &&
                data_header_tema.tema.filter((o, index) => {
                  return (
                    o.code_sub_header_tema === this.state.selectedTema.code
                  );
                });

              map(PengeluaranData9, (data_header_tema, idx_sub_header_tema) => {
                let execution_time = "";
                let total = 0;
                let target = "";
                let i = "";
                let a = "";
                let nominal = 0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for (i = 0; i < budgetloop.length; i++) {
                  if (budgetloop[i].tema == data.id) {
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if (budgetloop[i].target != null) {
                      target = budgetloop[i].target;
                    }

                    // console.log(budgetloop)

                    for (a = 0; a < budgetloop[i].allocations.length; a++) {
                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                      nominal = budgetloop[i].allocations[a].nominal;
                      if (plafond_id === 1) {
                        rutinNominal =
                          parseInt(rutinNominal) + parseInt(nominal);
                      } else if (plafond_id === 2) {
                        kegiatanNominal =
                          parseInt(kegiatanNominal) + parseInt(nominal);
                      } else if (plafond_id === 3) {
                        danaNominal = parseInt(danaNominal) + parseInt(nominal);
                      } else if (plafond_id === 4) {
                        bppNominal = parseInt(bppNominal) + parseInt(nominal);
                      }
                    }
                    total =
                      rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                  }
                }
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td>{data_header_tema.code_sub_header_tema}</td>
                    <td>{data_header_tema.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(rutinNominal)}
                    </td>
                    <td clasname="nominal">
                      Rp.{commaFormatted(kegiatanNominal)}
                    </td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>,
                ]);
              });
            } else {
              map(
                data_header_tema.sub_header_tema,
                (data_header_tema, idx_sub_header_tema) => {
                  let execution_time = "";
                  let total = 0;
                  let target = "";
                  let i = "";
                  let a = "";
                  let nominal = 0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";

                  for (i = 0; i < budgetloop.length; i++) {
                    if (budgetloop[i].tema == data.id) {
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if (budgetloop[i].target != null) {
                        target = budgetloop[i].target;
                      }

                      // console.log(budgetloop)

                      for (a = 0; a < budgetloop[i].allocations.length; a++) {
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        nominal = budgetloop[i].allocations[a].nominal;
                        if (plafond_id === 1) {
                          rutinNominal =
                            parseInt(rutinNominal) + parseInt(nominal);
                        } else if (plafond_id === 2) {
                          kegiatanNominal =
                            parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if (plafond_id === 3) {
                          danaNominal =
                            parseInt(danaNominal) + parseInt(nominal);
                        } else if (plafond_id === 4) {
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                      }
                      total =
                        rutinNominal +
                        kegiatanNominal +
                        danaNominal +
                        bppNominal;
                    }
                  }
                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td>
                        {data_header_tema.code_sub_header_tema}dadada excel
                      </td>
                      <td>{data_header_tema.name}</td>
                      <td>{execution_time}</td>
                      <td>{target}</td>
                      <td clasname="nominal">
                        Rp.{commaFormatted(rutinNominal)}
                      </td>
                      <td clasname="nominal">
                        Rp.{commaFormatted(kegiatanNominal)}
                      </td>
                      <td clasname="nominal">
                        Rp.{commaFormatted(danaNominal)}
                      </td>
                      <td clasname="nominal">
                        Rp.{commaFormatted(bppNominal)}
                      </td>
                      <td className="nominal">Rp.{commaFormatted(total)}</td>
                    </tr>,
                  ]);
                }
              );
            }

            map(data_header_tema.tema, (data_tema, idx_tema) => {
              let execution_time = "";
              let total = 0;
              let target = "";
              let i = "";
              let a = "";
              let nominal = 0;
              let rutinNominal = 0;
              let kegiatanNominal = 0;
              let bppNominal = 0;
              let danaNominal = 0;
              let plafond_id = "";

              for (i = 0; i < budgetloop.length; i++) {
                if (budgetloop[i].tema == data_tema.id) {
                  execution_time = budgetloop[i].execution_time;
                  // total += Number(budgetloop[i].total);
                  if (budgetloop[i].target != null) {
                    target = budgetloop[i].target;
                  }

                  for (a = 0; a < budgetloop[i].allocations.length; a++) {
                    plafond_id = budgetloop[i].allocations[a].plafond_id;
                    // console.log(plafond_id);
                    nominal = budgetloop[i].allocations[a].nominal;

                    if (plafond_id === 1) {
                      rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                    } else if (plafond_id === 2) {
                      kegiatanNominal =
                        parseInt(kegiatanNominal) + parseInt(nominal);
                    } else if (plafond_id === 3) {
                      danaNominal = parseInt(danaNominal) + parseInt(nominal);
                    } else if (plafond_id === 4) {
                      bppNominal = parseInt(bppNominal) + parseInt(nominal);
                    }
                  }
                  total =
                    rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                }
              }

              rows.push([
                <tr key={`budget_rapbu_rows_${idx}`}>
                  <td>{data_tema.code}bawah dadada</td>
                  <td>{data_tema.name}</td>
                  <td>{execution_time}</td>
                  <td>{target}</td>
                  <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                  <td clasname="nominal">
                    Rp.{commaFormatted(kegiatanNominal)}
                  </td>
                  <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                  <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                  <td className="nominal">Rp.{commaFormatted(total)}</td>
                </tr>,
              ]);
            });
            for (let t = 0; t < 1; t++) {
              rows.push([
                <tr key={`budget_rapbu_rows_${idx}`}>
                  <td colspan="10" className="nominal">
                    Rp. {commaFormatted(total2)}
                  </td>
                </tr>,
              ]);
            }

            map(
              data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td colspan="10">
                      <b>
                        {data.divisi +
                          " - " +
                          data_header_tema.code_sub_header_tema +
                          " -" +
                          data_header_tema.name}
                      </b>
                    </td>
                  </tr>,
                ]);

                if (this.state.selectedTema.code) {
                  let PengeluaranData3 = data_header_tema.tema.filter(
                    (o, index) => {
                      return o.code === this.state.selectedTema.code;
                    }
                  );
                  map(PengeluaranData3, (data_tema, idx_tema) => {
                    let execution_time = "";
                    let total = 0;
                    let target = "";
                    let i = "";
                    let a = "";
                    let nominal = 0;
                    let rutinNominal = 0;
                    let kegiatanNominal = 0;
                    let bppNominal = 0;
                    let danaNominal = 0;
                    let plafond_id = "";

                    for (i = 0; i < budgetloop.length; i++) {
                      if (budgetloop[i].tema == data_tema.id) {
                        execution_time = budgetloop[i].execution_time;
                        // total += Number(budgetloop[i].total);
                        if (budgetloop[i].target != null) {
                          target = budgetloop[i].target;
                        }

                        for (a = 0; a < budgetloop[i].allocations.length; a++) {
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;

                          if (plafond_id === 1) {
                            rutinNominal =
                              parseInt(rutinNominal) + parseInt(nominal);
                          } else if (plafond_id === 2) {
                            kegiatanNominal =
                              parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if (plafond_id === 3) {
                            danaNominal =
                              parseInt(danaNominal) + parseInt(nominal);
                          } else if (plafond_id === 4) {
                            bppNominal =
                              parseInt(bppNominal) + parseInt(nominal);
                          }
                        }
                        total =
                          rutinNominal +
                          kegiatanNominal +
                          danaNominal +
                          bppNominal;
                      }
                    }

                    rows.push([
                      <tr key={`budget_rapbu_rows_${idx}`}>
                        <td>{data_tema.code}</td>
                        <td>{data_tema.name}</td>
                        <td>{execution_time}</td>
                        <td>{target}</td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(rutinNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(kegiatanNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(danaNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(bppNominal)}
                        </td>
                        <td className="nominal">Rp.{commaFormatted(total)}</td>
                      </tr>,
                    ]);
                  });
                } else {
                  map(data_header_tema.tema, (data_tema, idx_tema) => {
                    let execution_time = "";
                    let total = 0;
                    let target = "";
                    let i = "";
                    let a = "";
                    let nominal = 0;
                    let rutinNominal = 0;
                    let kegiatanNominal = 0;
                    let bppNominal = 0;
                    let danaNominal = 0;
                    let plafond_id = "";

                    for (i = 0; i < budgetloop.length; i++) {
                      if (budgetloop[i].tema == data_tema.id) {
                        execution_time = budgetloop[i].execution_time;
                        // total += Number(budgetloop[i].total);
                        if (budgetloop[i].target != null) {
                          target = budgetloop[i].target;
                        }

                        for (a = 0; a < budgetloop[i].allocations.length; a++) {
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;

                          if (plafond_id === 1) {
                            rutinNominal =
                              parseInt(rutinNominal) + parseInt(nominal);
                          } else if (plafond_id === 2) {
                            kegiatanNominal =
                              parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if (plafond_id === 3) {
                            danaNominal =
                              parseInt(danaNominal) + parseInt(nominal);
                          } else if (plafond_id === 4) {
                            bppNominal =
                              parseInt(bppNominal) + parseInt(nominal);
                          }
                        }
                        total =
                          rutinNominal +
                          kegiatanNominal +
                          danaNominal +
                          bppNominal;
                      }
                    }

                    rows.push([
                      <tr key={`budget_rapbu_rows_${idx}`}>
                        <td>{data_tema.code}</td>
                        <td>{data_tema.name}</td>
                        <td>{execution_time}</td>
                        <td>{target}</td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(rutinNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(kegiatanNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(danaNominal)}
                        </td>
                        <td clasname="nominal">
                          Rp.{commaFormatted(bppNominal)}
                        </td>
                        <td className="nominal">Rp.{commaFormatted(total)}</td>
                      </tr>,
                    ]);
                  });
                }

                for (let t = 0; t < 1; t++) {
                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td colspan="10" className="nominal">
                        Rp. {commaFormatted(total2)}
                      </td>
                    </tr>,
                  ]);
                }
              }
            );
          }
          // ddsdsds
          else if (data_header_tema.sub_header_tema) {
            map(
              data_header_tema.sub_header_tema,
              (data_header_tema, idx_sub_header_tema) => {
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td>{data_header_tema.code_sub_header_tema}</td>
                    <td>{data_header_tema.name}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>,
                ]);

                // console.log(data_header_tema.prm_detail_header_tema)

                if (data_header_tema.prm_detail_header_tema) {
                  map(
                    data_header_tema.prm_detail_header_tema,
                    (data_header_tema, idx_header_tema) => {
                      rows.push([
                        <tr key={`budget_rapbu_rows_${idx}`}>
                          <td colspan="10">
                            <b>
                              {data.divisi +
                                " - " +
                                data_header_tema.code_detail_header_tema +
                                " - " +
                                data_header_tema.name}
                            </b>
                          </td>
                        </tr>,
                      ]);

                      if (this.state.selectedTema.code) {
                        let PengeluaranData6 = data_header_tema.tema.filter(
                          (o, index) => {
                            return o.code === this.state.selectedTema.code;
                          }
                        );

                        map(PengeluaranData6, (data_tema, idx_tema) => {
                          let execution_time = "";
                          let total = 0;
                          let target = "";
                          let i = "";
                          let a = "";
                          let nominal = 0;
                          let rutinNominal = 0;
                          let kegiatanNominal = 0;
                          let bppNominal = 0;
                          let danaNominal = 0;
                          let plafond_id = "";

                          for (i = 0; i < budgetloop.length; i++) {
                            if (budgetloop[i].tema == data_tema.id) {
                              execution_time = budgetloop[i].execution_time;
                              // total += Number(budgetloop[i].total);
                              if (budgetloop[i].target != null) {
                                target = budgetloop[i].target;
                              }

                              for (
                                a = 0;
                                a < budgetloop[i].allocations.length;
                                a++
                              ) {
                                plafond_id =
                                  budgetloop[i].allocations[a].plafond_id;
                                // console.log(plafond_id);
                                nominal = budgetloop[i].allocations[a].nominal;

                                if (plafond_id === 1) {
                                  rutinNominal =
                                    parseInt(rutinNominal) + parseInt(nominal);
                                } else if (plafond_id === 2) {
                                  kegiatanNominal =
                                    parseInt(kegiatanNominal) +
                                    parseInt(nominal);
                                } else if (plafond_id === 3) {
                                  danaNominal =
                                    parseInt(danaNominal) + parseInt(nominal);
                                } else if (plafond_id === 4) {
                                  bppNominal =
                                    parseInt(bppNominal) + parseInt(nominal);
                                }
                              }
                              total =
                                rutinNominal +
                                kegiatanNominal +
                                danaNominal +
                                bppNominal;
                            }
                          }

                          rows.push([
                            <tr key={`budget_rapbu_rows_${idx}`}>
                              <td>{data_tema.code}</td>
                              <td>{data_tema.name}</td>
                              <td>{execution_time}</td>
                              <td>{target}</td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(rutinNominal)}
                              </td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(kegiatanNominal)}
                              </td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(danaNominal)}
                              </td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(bppNominal)}
                              </td>
                              <td className="nominal">
                                Rp.{commaFormatted(total)}
                              </td>
                            </tr>,
                          ]);
                        });
                      } else {
                        map(data_header_tema.tema, (data_tema, idx_tema) => {
                          let execution_time = "";
                          let total = 0;
                          let target = "";
                          let i = "";
                          let a = "";
                          let nominal = 0;
                          let rutinNominal = 0;
                          let kegiatanNominal = 0;
                          let bppNominal = 0;
                          let danaNominal = 0;
                          let plafond_id = "";

                          for (i = 0; i < budgetloop.length; i++) {
                            if (budgetloop[i].tema == data_tema.id) {
                              execution_time = budgetloop[i].execution_time;
                              // total += Number(budgetloop[i].total);
                              if (budgetloop[i].target != null) {
                                target = budgetloop[i].target;
                              }

                              for (
                                a = 0;
                                a < budgetloop[i].allocations.length;
                                a++
                              ) {
                                plafond_id =
                                  budgetloop[i].allocations[a].plafond_id;
                                // console.log(plafond_id);
                                nominal = budgetloop[i].allocations[a].nominal;

                                if (plafond_id === 1) {
                                  rutinNominal =
                                    parseInt(rutinNominal) + parseInt(nominal);
                                } else if (plafond_id === 2) {
                                  kegiatanNominal =
                                    parseInt(kegiatanNominal) +
                                    parseInt(nominal);
                                } else if (plafond_id === 3) {
                                  danaNominal =
                                    parseInt(danaNominal) + parseInt(nominal);
                                } else if (plafond_id === 4) {
                                  bppNominal =
                                    parseInt(bppNominal) + parseInt(nominal);
                                }
                              }
                              total =
                                rutinNominal +
                                kegiatanNominal +
                                danaNominal +
                                bppNominal;
                            }
                          }

                          rows.push([
                            <tr key={`budget_rapbu_rows_${idx}`}>
                              <td>{data_tema.code}</td>
                              <td>{data_tema.name}</td>
                              <td>{execution_time}</td>
                              <td>{target}</td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(rutinNominal)}
                              </td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(kegiatanNominal)}
                              </td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(danaNominal)}
                              </td>
                              <td clasname="nominal">
                                Rp.{commaFormatted(bppNominal)}
                              </td>
                              <td className="nominal">
                                Rp.{commaFormatted(total)}
                              </td>
                            </tr>,
                          ]);
                        });
                      }

                      for (let t = 0; t < 1; t++) {
                        rows.push([
                          <tr key={`budget_rapbu_rows_${idx}`}>
                            <td colspan="10" className="nominal">
                              Rp. {commaFormatted(total2)}
                            </td>
                          </tr>,
                        ]);
                      }

                      if (data_header_tema.prm_sub_detail_header_tema) {
                        // console.log(data_header_tema.prm_sub_detail_header_tema)
                        map(
                          data_header_tema.prm_sub_detail_header_tema,
                          (data_header_tema, idx_header_tema) => {
                            rows.push([
                              <tr key={`budget_rapbu_rows_${idx}`}>
                                <td colspan="10">
                                  <b>
                                    {data.divisi +
                                      " - " +
                                      data_header_tema.code_sub_detail_header_tema +
                                      " - " +
                                      data_header_tema.name}
                                  </b>
                                </td>
                              </tr>,
                            ]);

                            if (this.state.selectedTema.code) {
                              let PengeluaranData8 =
                                data_header_tema.tema.filter((o, index) => {
                                  return (
                                    o.code === this.state.selectedTema.code
                                  );
                                });

                              map(PengeluaranData8, (data_tema, idx_tema) => {
                                let execution_time = "";
                                let total = 0;
                                let target = "";
                                let i = "";
                                let a = "";
                                let nominal = 0;
                                let rutinNominal = 0;
                                let kegiatanNominal = 0;
                                let bppNominal = 0;
                                let danaNominal = 0;
                                let plafond_id = "";

                                for (i = 0; i < budgetloop.length; i++) {
                                  if (budgetloop[i].tema == data_tema.id) {
                                    execution_time =
                                      budgetloop[i].execution_time;
                                    // total += Number(budgetloop[i].total);
                                    target = budgetloop[i].target;

                                    for (
                                      a = 0;
                                      a < budgetloop[i].allocations.length;
                                      a++
                                    ) {
                                      plafond_id =
                                        budgetloop[i].allocations[a].plafond_id;
                                      // console.log(plafond_id);
                                      nominal =
                                        budgetloop[i].allocations[a].nominal;

                                      if (plafond_id === 1) {
                                        rutinNominal =
                                          parseInt(rutinNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 2) {
                                        kegiatanNominal =
                                          parseInt(kegiatanNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 3) {
                                        danaNominal =
                                          parseInt(danaNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 4) {
                                        bppNominal =
                                          parseInt(bppNominal) +
                                          parseInt(nominal);
                                      }
                                    }
                                    total =
                                      rutinNominal +
                                      kegiatanNominal +
                                      danaNominal +
                                      bppNominal;
                                  }
                                }

                                rows.push([
                                  <tr key={`budget_rapbu_rows_${idx}`}>
                                    <td>{data_tema.code}</td>
                                    <td>{data_tema.name}</td>
                                    <td>{execution_time}</td>
                                    <td>{target}</td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(rutinNominal)}
                                    </td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(kegiatanNominal)}
                                    </td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(danaNominal)}
                                    </td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(bppNominal)}
                                    </td>
                                    <td className="nominal">
                                      Rp.{commaFormatted(total)}
                                    </td>
                                  </tr>,
                                ]);
                              });
                            } else {
                              map(
                                data_header_tema.tema,
                                (data_tema, idx_tema) => {
                                  let execution_time = "";
                                  let total = 0;
                                  let target = "";
                                  let i = "";
                                  let a = "";
                                  let nominal = 0;
                                  let rutinNominal = 0;
                                  let kegiatanNominal = 0;
                                  let bppNominal = 0;
                                  let danaNominal = 0;
                                  let plafond_id = "";

                                  for (i = 0; i < budgetloop.length; i++) {
                                    if (budgetloop[i].tema == data_tema.id) {
                                      execution_time =
                                        budgetloop[i].execution_time;
                                      // total += Number(budgetloop[i].total);
                                      target = budgetloop[i].target;

                                      for (
                                        a = 0;
                                        a < budgetloop[i].allocations.length;
                                        a++
                                      ) {
                                        plafond_id =
                                          budgetloop[i].allocations[a]
                                            .plafond_id;
                                        // console.log(plafond_id);
                                        nominal =
                                          budgetloop[i].allocations[a].nominal;

                                        if (plafond_id === 1) {
                                          rutinNominal =
                                            parseInt(rutinNominal) +
                                            parseInt(nominal);
                                        } else if (plafond_id === 2) {
                                          kegiatanNominal =
                                            parseInt(kegiatanNominal) +
                                            parseInt(nominal);
                                        } else if (plafond_id === 3) {
                                          danaNominal =
                                            parseInt(danaNominal) +
                                            parseInt(nominal);
                                        } else if (plafond_id === 4) {
                                          bppNominal =
                                            parseInt(bppNominal) +
                                            parseInt(nominal);
                                        }
                                      }
                                      total =
                                        rutinNominal +
                                        kegiatanNominal +
                                        danaNominal +
                                        bppNominal;
                                    }
                                  }

                                  rows.push([
                                    <tr key={`budget_rapbu_rows_${idx}`}>
                                      <td>{data_tema.code}</td>
                                      <td>{data_tema.name}</td>
                                      <td>{execution_time}</td>
                                      <td>{target}</td>
                                      <td clasname="nominal">
                                        Rp.{commaFormatted(rutinNominal)}
                                      </td>
                                      <td clasname="nominal">
                                        Rp.{commaFormatted(kegiatanNominal)}
                                      </td>
                                      <td clasname="nominal">
                                        Rp.{commaFormatted(danaNominal)}
                                      </td>
                                      <td clasname="nominal">
                                        Rp.{commaFormatted(bppNominal)}
                                      </td>
                                      <td className="nominal">
                                        Rp.{commaFormatted(total)}
                                      </td>
                                    </tr>,
                                  ]);
                                }
                              );
                            }

                            for (let t = 0; t < 1; t++) {
                              rows.push([
                                <tr key={`budget_rapbu_rows_${idx}`}>
                                  <td colspan="10" className="nominal">
                                    Rp. {commaFormatted(total2)}
                                  </td>
                                </tr>,
                              ]);
                            }
                          }
                        );
                      } else {
                        // console.log(data_header_tema.code)
                        map(
                          data_header_tema.tema,
                          (data_header_tema, idx_tema) => {
                            let execution_time = "";
                            let total = 0;
                            let target = "";
                            let i = "";
                            let a = "";
                            let nominal = 0;
                            let rutinNominal = 0;
                            let kegiatanNominal = 0;
                            let bppNominal = 0;
                            let danaNominal = 0;
                            let plafond_id = "";

                            for (i = 0; i < budgetloop.length; i++) {
                              if (budgetloop[i].tema == data_header_tema.id) {
                                execution_time = budgetloop[i].execution_time;
                                // total += Number(budgetloop[i].total);
                                if (budgetloop[i].target != null) {
                                  target = budgetloop[i].target;
                                }

                                for (
                                  a = 0;
                                  a < budgetloop[i].allocations.length;
                                  a++
                                ) {
                                  plafond_id =
                                    budgetloop[i].allocations[a].plafond_id;
                                  // console.log(plafond_id);
                                  nominal =
                                    budgetloop[i].allocations[a].nominal;

                                  if (plafond_id === 1) {
                                    rutinNominal =
                                      parseInt(rutinNominal) +
                                      parseInt(nominal);
                                  } else if (plafond_id === 2) {
                                    kegiatanNominal =
                                      parseInt(kegiatanNominal) +
                                      parseInt(nominal);
                                  } else if (plafond_id === 3) {
                                    danaNominal =
                                      parseInt(danaNominal) + parseInt(nominal);
                                  } else if (plafond_id === 4) {
                                    bppNominal =
                                      parseInt(bppNominal) + parseInt(nominal);
                                  }
                                }
                                total =
                                  rutinNominal +
                                  kegiatanNominal +
                                  danaNominal +
                                  bppNominal;
                              }
                            }

                            rows.push([
                              <tr key={`budget_rapbu_rows_${idx}`}>
                                <td>{data_header_tema.code}</td>
                                <td>{data_header_tema.name}</td>
                                <td>{execution_time}</td>
                                <td>{target}</td>
                                <td clasname="nominal">
                                  Rp.{commaFormatted(rutinNominal)}
                                </td>
                                <td clasname="nominal">
                                  Rp.{commaFormatted(kegiatanNominal)}
                                </td>
                                <td clasname="nominal">
                                  Rp.{commaFormatted(danaNominal)}
                                </td>
                                <td clasname="nominal">
                                  Rp.{commaFormatted(bppNominal)}
                                </td>
                                <td className="nominal">
                                  Rp.{commaFormatted(total)}
                                </td>
                              </tr>,
                            ]);
                          }
                        );
                        for (let t = 0; t < 1; t++) {
                          rows.push([
                            <tr key={`budget_rapbu_rows_${idx}`}>
                              <td colspan="10" className="nominal">
                                Rp. {commaFormatted(total2)}
                              </td>
                            </tr>,
                          ]);
                        }
                      }
                    }
                  );
                }
              }
            );
          }
          //ddsdsds

          map(
            data_header_tema.sub_header_tema,
            (data_header_tema, idx_sub_header_tema) => {
              // console.log(data_header_tema.prm_detail_header_tema)
              if (data_header_tema.prm_detail_header_tema) {
                map(
                  data_header_tema.prm_detail_header_tema,
                  (data_header_tema, idx_header_tema) => {
                    rows.push([
                      <tr key={`budget_rapbu_rows_${idx}`}>
                        <td colspan="10">
                          <b>
                            {data.divisi +
                              " - " +
                              data_header_tema.code_detail_header_tema +
                              " - " +
                              data_header_tema.name}
                          </b>
                        </td>
                      </tr>,
                    ]);
                    if (this.state.selectedTema.code) {
                      let PengeluaranData6 = data_header_tema.tema.filter(
                        (o, index) => {
                          return o.code === this.state.selectedTema.code;
                        }
                      );
                      map(PengeluaranData6, (data_tema, idx_tema) => {
                        let execution_time = "";
                        let total = 0;
                        let target = "";
                        let i = "";
                        let a = "";
                        let nominal = 0;
                        let rutinNominal = 0;
                        let kegiatanNominal = 0;
                        let bppNominal = 0;
                        let danaNominal = 0;
                        let plafond_id = "";

                        for (i = 0; i < budgetloop.length; i++) {
                          if (budgetloop[i].tema == data_tema.id) {
                            execution_time = budgetloop[i].execution_time;
                            // total += Number(budgetloop[i].total);
                            if (budgetloop[i].target != null) {
                              target = budgetloop[i].target;
                            }

                            for (
                              a = 0;
                              a < budgetloop[i].allocations.length;
                              a++
                            ) {
                              plafond_id =
                                budgetloop[i].allocations[a].plafond_id;
                              // console.log(plafond_id);
                              nominal = budgetloop[i].allocations[a].nominal;

                              if (plafond_id === 1) {
                                rutinNominal =
                                  parseInt(rutinNominal) + parseInt(nominal);
                              } else if (plafond_id === 2) {
                                kegiatanNominal =
                                  parseInt(kegiatanNominal) + parseInt(nominal);
                              } else if (plafond_id === 3) {
                                danaNominal =
                                  parseInt(danaNominal) + parseInt(nominal);
                              } else if (plafond_id === 4) {
                                bppNominal =
                                  parseInt(bppNominal) + parseInt(nominal);
                              }
                            }
                            total =
                              rutinNominal +
                              kegiatanNominal +
                              danaNominal +
                              bppNominal;
                          }
                        }

                        rows.push([
                          <tr key={`budget_rapbu_rows_${idx}`}>
                            <td>{data_tema.code}</td>
                            <td>{data_tema.name}</td>
                            <td>{execution_time}</td>
                            <td>{target}</td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(rutinNominal)}
                            </td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(kegiatanNominal)}
                            </td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(danaNominal)}
                            </td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(bppNominal)}
                            </td>
                            <td className="nominal">
                              Rp.{commaFormatted(total)}
                            </td>
                          </tr>,
                        ]);
                      });
                    } else {
                      map(data_header_tema.tema, (data_tema, idx_tema) => {
                        let execution_time = "";
                        let total = 0;
                        let target = "";
                        let i = "";
                        let a = "";
                        let nominal = 0;
                        let rutinNominal = 0;
                        let kegiatanNominal = 0;
                        let bppNominal = 0;
                        let danaNominal = 0;
                        let plafond_id = "";

                        for (i = 0; i < budgetloop.length; i++) {
                          if (budgetloop[i].tema == data_tema.id) {
                            execution_time = budgetloop[i].execution_time;
                            // total += Number(budgetloop[i].total);
                            if (budgetloop[i].target != null) {
                              target = budgetloop[i].target;
                            }

                            for (
                              a = 0;
                              a < budgetloop[i].allocations.length;
                              a++
                            ) {
                              plafond_id =
                                budgetloop[i].allocations[a].plafond_id;
                              // console.log(plafond_id);
                              nominal = budgetloop[i].allocations[a].nominal;

                              if (plafond_id === 1) {
                                rutinNominal =
                                  parseInt(rutinNominal) + parseInt(nominal);
                              } else if (plafond_id === 2) {
                                kegiatanNominal =
                                  parseInt(kegiatanNominal) + parseInt(nominal);
                              } else if (plafond_id === 3) {
                                danaNominal =
                                  parseInt(danaNominal) + parseInt(nominal);
                              } else if (plafond_id === 4) {
                                bppNominal =
                                  parseInt(bppNominal) + parseInt(nominal);
                              }
                            }
                            total =
                              rutinNominal +
                              kegiatanNominal +
                              danaNominal +
                              bppNominal;
                          }
                        }

                        rows.push([
                          <tr key={`budget_rapbu_rows_${idx}`}>
                            <td>{data_tema.code}</td>
                            <td>{data_tema.name}</td>
                            <td>{execution_time}</td>
                            <td>{target}</td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(rutinNominal)}
                            </td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(kegiatanNominal)}
                            </td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(danaNominal)}
                            </td>
                            <td clasname="nominal">
                              Rp.{commaFormatted(bppNominal)}
                            </td>
                            <td className="nominal">
                              Rp.{commaFormatted(total)}
                            </td>
                          </tr>,
                        ]);
                      });
                    }

                    for (let t = 0; t < 1; t++) {
                      rows.push([
                        <tr key={`budget_rapbu_rows_${idx}`}>
                          <td colspan="10" className="nominal">
                            Rp. {commaFormatted(total2)}
                          </td>
                        </tr>,
                      ]);
                    }

                    if (data_header_tema.prm_sub_detail_header_tema) {
                      // console.log(data_header_tema.prm_sub_detail_header_tema)
                      map(
                        data_header_tema.prm_sub_detail_header_tema,
                        (data_header_tema, idx_header_tema) => {
                          rows.push([
                            <tr key={`budget_rapbu_rows_${idx}`}>
                              <td colspan="10">
                                <b>
                                  {data.divisi +
                                    " - " +
                                    data_header_tema.code_sub_detail_header_tema +
                                    " - " +
                                    data_header_tema.name}
                                </b>
                              </td>
                            </tr>,
                          ]);

                          if (this.state.selectedTema.code) {
                            let PengeluaranData8 = data_header_tema.tema.filter(
                              (o, index) => {
                                return o.code === this.state.selectedTema.code;
                              }
                            );
                            map(PengeluaranData8, (data_tema, idx_tema) => {
                              let execution_time = "";
                              let total = 0;
                              let target = "";
                              let i = "";
                              let a = "";
                              let nominal = 0;
                              let rutinNominal = 0;
                              let kegiatanNominal = 0;
                              let bppNominal = 0;
                              let danaNominal = 0;
                              let plafond_id = "";

                              for (i = 0; i < budgetloop.length; i++) {
                                if (budgetloop[i].tema == data_tema.id) {
                                  execution_time = budgetloop[i].execution_time;
                                  // total += Number(budgetloop[i].total);
                                  if (budgetloop[i].target != null) {
                                    target = budgetloop[i].target;
                                  }

                                  for (
                                    a = 0;
                                    a < budgetloop[i].allocations.length;
                                    a++
                                  ) {
                                    plafond_id =
                                      budgetloop[i].allocations[a].plafond_id;
                                    // console.log(plafond_id);
                                    nominal =
                                      budgetloop[i].allocations[a].nominal;

                                    if (plafond_id === 1) {
                                      rutinNominal =
                                        parseInt(rutinNominal) +
                                        parseInt(nominal);
                                    } else if (plafond_id === 2) {
                                      kegiatanNominal =
                                        parseInt(kegiatanNominal) +
                                        parseInt(nominal);
                                    } else if (plafond_id === 3) {
                                      danaNominal =
                                        parseInt(danaNominal) +
                                        parseInt(nominal);
                                    } else if (plafond_id === 4) {
                                      bppNominal =
                                        parseInt(bppNominal) +
                                        parseInt(nominal);
                                    }
                                  }
                                  total =
                                    rutinNominal +
                                    kegiatanNominal +
                                    danaNominal +
                                    bppNominal;
                                }
                              }

                              rows.push([
                                <tr key={`budget_rapbu_rows_${idx}`}>
                                  <td>{data_tema.code}</td>
                                  <td>{data_tema.name}</td>
                                  <td>{execution_time}</td>
                                  <td>{target}</td>
                                  <td clasname="nominal">
                                    Rp.{commaFormatted(rutinNominal)}
                                  </td>
                                  <td clasname="nominal">
                                    Rp.{commaFormatted(kegiatanNominal)}
                                  </td>
                                  <td clasname="nominal">
                                    Rp.{commaFormatted(danaNominal)}
                                  </td>
                                  <td clasname="nominal">
                                    Rp.{commaFormatted(bppNominal)}
                                  </td>
                                  <td className="nominal">
                                    Rp.{commaFormatted(total)}
                                  </td>
                                </tr>,
                              ]);
                            });
                          } else {
                            map(
                              data_header_tema.tema,
                              (data_tema, idx_tema) => {
                                let execution_time = "";
                                let total = 0;
                                let target = "";
                                let i = "";
                                let a = "";
                                let nominal = 0;
                                let rutinNominal = 0;
                                let kegiatanNominal = 0;
                                let bppNominal = 0;
                                let danaNominal = 0;
                                let plafond_id = "";

                                for (i = 0; i < budgetloop.length; i++) {
                                  if (budgetloop[i].tema == data_tema.id) {
                                    execution_time =
                                      budgetloop[i].execution_time;
                                    // total += Number(budgetloop[i].total);
                                    if (budgetloop[i].target != null) {
                                      target = budgetloop[i].target;
                                    }

                                    for (
                                      a = 0;
                                      a < budgetloop[i].allocations.length;
                                      a++
                                    ) {
                                      plafond_id =
                                        budgetloop[i].allocations[a].plafond_id;
                                      // console.log(plafond_id);
                                      nominal =
                                        budgetloop[i].allocations[a].nominal;

                                      if (plafond_id === 1) {
                                        rutinNominal =
                                          parseInt(rutinNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 2) {
                                        kegiatanNominal =
                                          parseInt(kegiatanNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 3) {
                                        danaNominal =
                                          parseInt(danaNominal) +
                                          parseInt(nominal);
                                      } else if (plafond_id === 4) {
                                        bppNominal =
                                          parseInt(bppNominal) +
                                          parseInt(nominal);
                                      }
                                    }
                                    total =
                                      rutinNominal +
                                      kegiatanNominal +
                                      danaNominal +
                                      bppNominal;
                                  }
                                }

                                rows.push([
                                  <tr key={`budget_rapbu_rows_${idx}`}>
                                    <td>{data_tema.code}</td>
                                    <td>{data_tema.name}</td>
                                    <td>{execution_time}</td>
                                    <td>{target}</td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(rutinNominal)}
                                    </td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(kegiatanNominal)}
                                    </td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(danaNominal)}
                                    </td>
                                    <td clasname="nominal">
                                      Rp.{commaFormatted(bppNominal)}
                                    </td>
                                    <td className="nominal">
                                      Rp.{commaFormatted(total)}
                                    </td>
                                  </tr>,
                                ]);
                              }
                            );
                          }

                          for (let t = 0; t < 1; t++) {
                            rows.push([
                              <tr key={`budget_rapbu_rows_${idx}`}>
                                <td colspan="10" className="nominal">
                                  Rp. {commaFormatted(total2)}
                                </td>
                              </tr>,
                            ]);
                          }
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        });
      });
    }

    return rows;
  }

  renderHeader() {
    const { user } = this.props;
    const { user_group } = user;

    const isBendahara =
      user_group && user_group.name === "Bendahara" ? true : false;
    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    return (
      <thead>
        <tr>
          <th
            className="manage-budget__row table"
            style={{ width: "50%" }}
            rowSpan={2}
          >
            Kode
          </th>
          <th
            className="manage-budget__row table"
            style={{ width: "5%" }}
            rowSpan={2}
          >
            Nama
          </th>
          <th className="manage-budget__row table" rowSpan={2}>
            Waktu Pelaksanaan
          </th>
          <th className="manage-budget__row table" rowSpan={2}>
            Target
          </th>
          <th className="manage-budget__row table" rowSpan={2}>
            Rutin
          </th>
          <th className="manage-budget__row table" rowSpan={2}>
            Kegiatan
          </th>
          {/* <th className="manage-budget__row table" rowSpan={2}>Dana Pemerintah</th> */}
          {/* <th className="manage-budget__row table" rowSpan={2}>
            Dana Pemerintah
          </th> */}
          <th className="manage-budget__row table" rowSpan={2}>
            Dana BOS
          </th>
          <th className="manage-budget__row table" rowSpan={2}>
            Biaya Pengembangan Pendidikan
          </th>
          <th className="manage-budget__row table" rowSpan={2}>
            Total
          </th>
        </tr>
      </thead>
    );
  }
  render() {
    const {
      budgetloop,
      dataloop,
      dataSummary,
      budgetSummary,
      recommendations,
      error,
      target,
      onSearchTema,
      tema,
      user,
    } = this.props;

    // console.log(dataSummary)

    // const { dataloop } = dataSummary;
    // const { budgetloop } = budgetSummary;
    const { user_group } = user;

    const isBendahara =
      user_group && user_group.name === "Bendahara" ? true : false;

    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    const tableColSpan = isPusat ? 6 : 6;
    const summaryRowColSpan = 2;

    const Modal = ({ handleClose, show, children }) => {
      const showHideClassName = show
        ? "modal display-block"
        : "modal display-none";

      return (
        <div className={showHideClassName}>
          <section className="modal-main">
            {children}
            <button onClick={handleClose}>Close</button>
          </section>
        </div>
      );
    };

    return (
      <div className="manage-budget__table-wrapper">
        <div>
          {this.renderExcel(dataloop, budgetloop)}
          <br></br>
          <br></br>

          <SelectCoa
            noMargin
            // edit={canEdit}
            name="tema"
            value={this.state.selectedTema}
            target={tema}
            tema={target}
            // value={target}
            error={error.tema}
            coa={tema}
            onClick={this.onSearchTema}
            placeholder="Pilih tema"
            rightIcon="icon-search"
            onSearchCoa={onSearchTema}
          />
        </div>
        <br></br>
        <br></br>
        <table className="table">
          {this.renderHeader()}
          {this.renderRows(dataloop, budgetloop)}
        </table>
      </div>
    );
  }
}
BudgetRapbuRekapExcel.propTypes = {
  onSelectBudgetDetail: PropTypes.func,
  rapbuSummary: PropTypes.object.isRequired,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  handleGetTemaOptions: PropTypes.func,
  onSearchTema: PropTypes.object,
};
BudgetRapbuRekapExcel.defaultProps = {
  handleGetTemaOptions: noop,
  onSearchCoa: noop,
  onChange: noop,
  onSearchTema: noop,
  onSelectBudgetDetail: noop,
  user: null,
  recommendations: {},
  error: {},
};
