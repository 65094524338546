import React, { Component } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, filter,
  dropRight, find, mapValues, keyBy, map,
} from 'lodash';
import { Button, Textarea, Select } from '../../../../components/base';
import BudgetRapbu from './components/BudgetRapbu.component';
import DashboardPrint from './components/DashboardPrint.component';
import language from '../../../../languages';
import {commaFormatted,  normalizeAmount, datetimeFormat } from '../../../../utils/transformer.util';
import {printJournal} from '../../../../utils/print.util';
import {errorAlert} from '../../../../utils/alert.util';

export default class ManageBudget extends Component {
  constructor(props) {
    super(props);
    this.onSetForm = this.onSetForm.bind(this);
    this.onChangeRecommendation = this.onChangeRecommendation.bind(this);
    this.onSaveRecommendations = this.onSaveRecommendations.bind(this);
    this.onSubmitApproval = this.onSubmitApproval.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onSelectBudgetDetail = this.onSelectBudgetDetail.bind(this);
    this.onSelectDivisiDetail = this.onSelectDivisiDetail.bind(this);
    this.setRemarks = this.setRemarks.bind(this);
    this.onClickDivisi = this.onClickDivisi.bind(this);
    this.renderPlafondRows = this.renderPlafondRows.bind(this);
    this.confirmReject = this.confirmReject.bind(this);
    this.hasRecommendations = this.hasRecommendations.bind(this);

    this.remarks = React.createRef();
    this.renderWorkflowHistory = this.renderWorkflowHistory.bind(this);
    this._onChangeDivisi = this._onChangeDivisi.bind(this);
    // this.calculateTotalPlafond = this.calculateTotalPlafond.bind(this);
    this.onFormChange = this.onFormChange.bind(this);

  
    this.state = {
      budget: {
        plafon:[],
        school_unit:{
          school_unit:{
            name:''
          },
        },
      },
      value: {},
      rapbuSummary: {
        semesterOne: [],
        semesterTwo: [],
      },
      recommendations: {},
      selectedBudgetDetails: [],
      workflow: {},
      total_pendapatan:'',
      total_pengeluaran:'',
      total_estimasi:'',
      total_saldo:'',
      remarks: '',
      unique_id:'',
      head_unique_id:'',

      selectedDivisi: null,
    };
    // console.log(this.state)
    // console.log("cek total pendapatan"+total_pendapatan)
  }

  componentDidMount() {
    this.onSetForm();
  }

  onClickDivisi(event) {
    const { history } = this.props;
    const { target } = event;
    const { dataset } = target;

    history.push(`/dashboard/keuangan/penganggaran/rapbs/${dataset.type}`, {
      ...this.state.form.value,
      type: dataset.type,
    });

  }

  async onSetForm(keyword = null) {
    const { handleGetBudgetDetail, handleGetDivisiOptions, handleGetPlafondOptions, location } = this.props;
    const { account_unique_id, head_unique_id,value} = location.state;
    const plafond = await handleGetPlafondOptions();
    const res = await handleGetBudgetDetail({ account_unique_id, head_unique_id, type: 'RAPBU' });

    // this.props.history.push('/dashboard/keuangan/penganggaran/anggaran')
    const divisi = await handleGetDivisiOptions({
      keyword,
      label: (value === 5) ? 'keuangan' : 'manajerial'
    });
    let availablePlafonds = filter(plafond, data => {
      const exists = find(res.budget.plafon, (plafon) => {
        return plafon.plafond_id === data.value
      });
      return typeof exists !== 'undefined';
    });

    const totalPlafond = mapValues(keyBy(res.budget.plafon, 'plafond_id'), 'total');
    const countable = {};
    map(res.budget.plafon, 'plafond_id').forEach(plafondId => {
      countable[plafondId] = {
        semesterOne: {},
        semesterTwo: {}
      };
    });

    let totalalokasiAllSemester = 0;
    let totalalokasiAllSemester2 = 0;
    let totalalokasiAllSemester3 = 0;
    let totalalokasiAllSemester4 = 0;

    const arrpengeluaran = [];
    const MyObject = res.ganjil.pengeluaran;
    Object.keys(MyObject).forEach(key => arrpengeluaran.push({name: key, value: MyObject[key]}))
      // ini untuk convert dari object ke array
      // console.log(arrpengeluaran)
      // console.log(MyObject)
    // let totalALo2 = 0;
    let totalAlokegiatan = 0;
    let totalAlorutin= 0;
    let totalAloBpp= 0;
    let totalAloDanaP= 0;
    arrpengeluaran.forEach((item, index) => {
      // console.log(item.value.data)
      const { data } = item.value;
      for (let i = 0; i < data.length; i++) {
        const allocations = data[i].allocations;
        Object.keys(allocations).forEach((key) => {
          const nominal = parseInt(allocations[key].nominal);
          if (key === '1'){
            totalAlorutin += parseInt(nominal);
          }
          if (key === '2' ){
            totalAlokegiatan += parseInt(nominal);
           }
          if (key ===  '3' ){
            totalAloDanaP += parseInt(nominal);
          }
          if (key ===  '4' ){
            totalAloBpp += parseInt(nominal);
          }
        });
      }
     });
   
     const arrpengeluarangenap = []; 
    const MyObject4 = res.genap.pengeluaran;
    Object.keys(MyObject4).forEach(key => arrpengeluarangenap.push({name: key, value: MyObject4[key]}))
    let totalAloganjilpengeluarankegiatan = 0;
    let totalAloganjilpengeluaranrutin= 0;
    let totalAloganjilpengeluaranBpp= 0;
    let totalAloganjilpengeluaranDanaP= 0;
    arrpengeluarangenap.forEach((item, index) => {
      // console.log(item.value.data)
      for(let f=0; f<item.value.data.length; f++){
          for(let h=0; h<item.value.data[f].allocations.length; h++){
            if (item.value.data[f].allocations[h].plafond_id == '1' ){
              totalAloganjilpengeluaranrutin += parseInt(item.value.data[f].allocations[h].nominal);
            }
            if (item.value.data[f].allocations[h].plafond_id == '2' ){
              totalAloganjilpengeluarankegiatan += parseInt(item.value.data[f].allocations[h].nominal);
             }
            if (item.value.data[f].allocations[h].plafond_id == '3' ){
              totalAloganjilpengeluaranDanaP += parseInt(item.value.data[f].allocations[h].nominal);
             }
             if (item.value.data[f].allocations[h].plafond_id == '4' ){
              totalAloganjilpengeluaranBpp += parseInt(item.value.data[f].allocations[h].nominal);
             }
            }
      }
      });

      // totalalokasiAllSemester = totalAloganjilpengeluaranrutin + totalAlogenappendapatanrutin + totalAlorutin + totalAlopendapatanrutin
      // totalalokasiAllSemester2 = totalAloganjilpengeluarankegiatan + totalAlogenappendapatankegiatan+ totalAlokegiatan + totalAlopendapatankegiatan
      // totalalokasiAllSemester3 = totalAloganjilpengeluaranDanaP + totalAlogenappendapatanDanaP+ totalAloDanaP + totalAlopendapatanDanaP
      // totalalokasiAllSemester4 = totalAloganjilpengeluaranBpp + totalAlogenappendapatanBpp + totalAloBpp + totalAlopendapatanBpp

      totalalokasiAllSemester = totalAloganjilpengeluaranrutin  + totalAlorutin
      totalalokasiAllSemester2 = totalAloganjilpengeluarankegiatan + totalAlokegiatan
      totalalokasiAllSemester3 = totalAloganjilpengeluaranDanaP +  totalAloDanaP
      totalalokasiAllSemester4 = totalAloganjilpengeluaranBpp  + totalAloBpp

      localStorage.setItem('TotalItem', totalalokasiAllSemester);
      localStorage.setItem('TotalItem2', totalalokasiAllSemester2);
      localStorage.setItem('TotalItem3', totalalokasiAllSemester3);
      localStorage.setItem('TotalItem4', totalalokasiAllSemester4);


    this.setState({
      form: {
        value: {
          semesterOne: res.ganjil || [],
          semesterTwo: res.genap || [],
        },
        error: {
          semesterOne: [],
          semesterTwo: [],
        },
      },
      
      budget: res.budget,
      rapbuSummary: {
        semesterOne: res.ganjil || [],
        semesterTwo: res.genap || [],
      },
      pendapatanganjil : res.ganjil.pendapatan || [],
      pengeluaranganjil : res.ganjil.pengeluaran || [],

      pendapatangenap : res.genap.pendapatan || [],
      pengeluarangenap : res.genap.pengeluaran || [],
      

      recommendations: res.recommendations || {},
      workflow: res.workflow || {},
      total_pendapatan:res.total_pendapatan,
      total_pengeluaran:res.total_pengeluaran,
      total_estimasi:res.total_estimasi,
      total_saldo:res.total_saldo,
      selectedDivisi: parseInt(divisi[0].value),
      availablePlafonds: availablePlafonds || [],
      totalPlafond: totalPlafond || {},
    });
    // console.log(this.state.form.value)
    // console.log(this.state.rapbuSummary)
    // this.calculateTotalPlafond();

    // console.log(res.ganjil.pengeluaran.length > 0 ? res.ganjil.pengeluaran : [])
    // console.log(res.ganjil.pendapatan.length > 0 ? res.ganjil.pendapatan : [])


    // console.log(res.ganjil)

    // console.log(this.setState)
  }

  onFormChange(event, formName) {

    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputArray = false, arrayPosition = 0, countable = false, inputType, plafondId  } = dataset;
    const { selectedDivisi } = this.state;

      this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      let newSemester = [];
      let newSemesterError = [];
      let countableData = {...prevState.countable}

      if(inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        newSemester = prevState.form.value[formName];
        newSemesterError = prevState.form.error[formName];
        newSemester[arrayPosition][name] = formattedValue;
        newSemester[arrayPosition].divisi_id = selectedDivisi;
        if (!isEmpty(newSemesterError[arrayPosition])) {
          newSemesterError[arrayPosition][name] = '';
        }
        if(plafondId) {
          if(!newSemester[arrayPosition].allocations) {
            newSemester[arrayPosition].allocations = {}
          }
          newSemester[arrayPosition].allocations[plafondId] = formattedValue;
        }
      }

      // console.log("sini" + arrayPosition)
      // console.log("sini" + inputArray)
      const error = {
        ...prevState.form.error,
        ...(inputArray
          ? { [formName]: newSemesterError }
          : { [name]: '' }),
      };
      error.semesterOne = filter(error.semesterOne, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      error.semesterTwo = filter(error.semesterTwo, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      // console.log(countable);

      if(countable) {
        if(!countableData[plafondId]) {
          countableData[plafondId] = {
            semesterOne: {},
            semesterTwo: {},
          }
        }
        countableData[plafondId][formName][arrayPosition] = formattedValue;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [formName]: newSemester }
              : { [name]: formattedValue }),
          },
          error,
        },
        countable: countableData,
      };
    }, () => {
      // if(countable) {
      //   this.calculateTotalPlafond();
      // }
    });
  }

  // calculateTotalPlafond() {
  //   const { countable, totalPlafond = {} } = this.state;

  //   if(countable) {
  //     for(let key in countable) {
  //       const item = countable[key];
  //       const { semesterOne, semesterTwo } = item;
  //       let total = 0;
  //       if (semesterOne) {
  //         for (let index in semesterOne) {
  //           total += semesterOne[index];
  //         }
  //       }
  //       if (semesterTwo) {
  //         for (let index in semesterTwo) {
  //           total += semesterTwo[index];
  //         }
  //         // console.log(total)
  //       }
  //       totalPlafond[key] = total;
  //     };
      
  //     // console.log(totalPlafond);
  //   }

  //   this.setState(prevState => ({
  //     ...prevState,
  //     totalPlafond
  //   }));
  // }

  onChangeRecommendation(event) {
    const { target } = event;
    const { dataset, value, name } = target;
    const { referenceid } = dataset;
    const { recommendations } = this.state;

    if (recommendations[name] === null) {
      recommendations[name] = {};
    }

    recommendations[name][referenceid] = normalizeAmount(value);

    this.setState(prevState => ({
      ...prevState,
      recommendations: {
        ...recommendations,
      },
    }));
  }

  async onSubmitApproval() {
    const { recommendations, selectedBudgetDetails } = this.state;
    const { handleSubmitApproval, location, history } = this.props;
    const { head_unique_id, data } = location.state;

    const res = await handleSubmitApproval({
      head: head_unique_id,
      recommendations,
      selectedBudgetDetails,
    });

    history.push('/dashboard/keuangan/penganggaran/anggaran');
  }

  onSaveRecommendations() {
    const { recommendations } = this.state;
    const { handleSaveRecommendations, history } = this.props;

    handleSaveRecommendations({
      recommendations,
    }, history.goBack);
  }

  onSaveRecommendations() {
    const { recommendations } = this.state;
    const { handleSaveRecommendations, history } = this.props;

    handleSaveRecommendations({
      recommendations,
    }, history.goBack);
  }

 
  async onReject(remarks) {
    const {recommendations, selectedBudgetDetails} = this.state;
    const { handleReject, history, location } = this.props;
    const { head_unique_id } = location.state;
    if(remarks === ""){
      alert("Keterangan Reject Wajib Diisi")
    }else{
    const res = await handleReject({
      head: head_unique_id,
      recommendations,
      selectedBudgetDetails,
      remarks,
    });
   
    history.push('/dashboard/keuangan/penganggaran/anggaran');
   }
  // history.push('/dashboard/keuangan/penganggaran/anggaran');
    
  }

  onSelectBudgetDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { selectedBudgetDetails } = this.state;
    const index = selectedBudgetDetails.indexOf(value);
    let newArray = selectedBudgetDetails.slice(0, selectedBudgetDetails.length);

    if (index !== -1) {
      if (checked === false) {
        newArray.splice(index, 1);
      }
    } else {
      if (checked) {
        newArray.push(value);
      }
    }

    this.setState(prevState => ({
      ...prevState,
      selectedBudgetDetails: newArray,
    }));
  }

  setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  onSelectDivisiDetail(event){
    let buttons = [];

    let { handleSelectDivisiDetail, history } = this.props;

    handleSelectDivisiDetail({
      // recommendations,
    }, history.goBack);

  }

  confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    return swal({
      title: language.translate.ALERT__ARE_YOU_SURE,
      text: "Masukkan remarks untuk menolak RAPBS",
      content: el,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((reject) => {
      if (reject) {
        this.onReject(el.value);
      }
    });
  }


  hasRecommendations() {
    const { recommendations } = this.state;

    return Object.keys(recommendations).some((key) => {
      const items = recommendations[key];
      if (items !== null && Object.keys(items).length > 0) {
        return Object.keys(items).some((referenceid) => {
          const value = items[referenceid];
          return value !== null && value > 0;
        });
      }
      return false;
    });
  }

  renderPlafondRows() {
    const {
      budget,
      availablePlafonds = [],
    } = this.state;

    const rows = [];

    if (budget.plafon.length > 0) {
      const { plafon: budgetPlafond } = budget;
      availablePlafonds.forEach((plafond) => {
        const currentPlafond = budgetPlafond.find(o => o.plafond_id === plafond.value);
        const { nominal, total } = currentPlafond;
        const percent = (total / nominal) * 100;
        rows.push((
          <tr key={`plafond_${plafond.value}`}>
            <td>
              {plafond.label}
            </td>
            <td className="nominal">
              {commaFormatted(total)}
            </td>
            <td
              className={`nominal ${total > nominal ? 'red' : ''}`}
            >{commaFormatted(nominal)}
            </td>
            <td
              className={`nominal ${percent > 100 ? 'red' : ''}`}
            >
              {commaFormatted(percent)} %
            </td>
          </tr>
        ));
        // console.log(currentPlafond)
        // console.log(availablePlafonds)
        // console.log(budget.plafon)
      });
    }
    
    return rows;
  }

  _onChangeDivisi(event) {
    const { target } = event;
    const { value } = target;

    this.setState(prevState => ({
      ...prevState,
      selectedDivisi: parseInt(value),

    }));
  }
  renderButton() {


    const { location, user } = this.props;
    const { type } = location.state;
    const { user_groups_id, user_group } = user;
    const { workflow, rapbuSummary } = this.state;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const { is_done = false } = lastWorkflow;
    // console.log(lastWorkflow)
    // console.log(is_done)
    // console.log(user_groups_id)

    const semesterOne = rapbuSummary.semesterOne;
    const semesterTwo = rapbuSummary.semesterTwo;
    let count = 0;
    let count2 = 0;

    if(semesterOne.pengeluaran!=undefined&&semesterTwo.pengeluaran!=undefined){
      count += Object.keys(semesterOne.pengeluaran).length;
      count += Object.keys(semesterTwo.pengeluaran).length;
    }

    
    if(semesterOne.pendapatan!=undefined&&semesterTwo.pendapatan!=undefined){
      count2 += Object.keys(semesterOne.pendapatan).length;
      count2 += Object.keys(semesterTwo.pendapatan).length;
    }
    // console.log(count)

    const submitButton = (
      <div key="button_submit">
        { count || count2 && user_group.name === 'Keuangan Pusat'?
       
        <Button
          type="button"
          onClick={this.onSubmitApproval}
          title={(user_group.name !== 'Keuangan Sekolah' && user_group.name !== 'Keuangan Pusat') ? 'Submit' : 'Approve'}
        />
        : ''}
         {/* {console.log(count)} */}
      </div>
    );

    // console.log(this.onSubmitApproval)

    const saveButton = (
      <div key="button_save">
        <Button
          type="button"
          onClick={this.onSaveRecommendations}
          title="Save"
        />
      </div>
    )
    const rejectButton = (
      <div key="button_reject">
        <Button
          type="button"
          onClick={this.confirmReject}
          // title={(user_group.name !== 'Keuangan Sekolah' && user_group.name !== 'Keuangan Pusat') ? 'Submit' : 'Reject'}
          title="Reject"
        />
      </div>
    )

    let buttons = [];

    if(lastWorkflow.hasOwnProperty('is_done')) {
      if(is_done) {
        return (
          <div>
            <i>RAPBS telah disetujui</i>
          </div>
        );
      } else {
        // console.log(!is_done)
        // console.log(action)
        // console.log(workflow)

        // console.log(lastWorkflow.next_role)
        // if (lastWorkflow.next_role === user_group.name || lastWorkflow.next_role ==="Keuangan Pusat") {
        if (lastWorkflow.next_role === user_group.name || user_group.name ==="Keuangan Pusat") {
          // console.log(lastWorkflow)

          // console.log(lastWorkflow.next_role)

          switch(user_group.name) {
            case 'Keuangan Sekolah':
              buttons.push(submitButton, rejectButton);
              // console.log(buttons)
            break;

            case 'Bendahara Perwakilan':
              buttons.push(submitButton);
            break;
            case 'Bendahara Pusat':
              buttons.push(submitButton);
            break;
            case 'Kepala Sekolah':
              buttons.push(submitButton);
              // console.log(submitButton);
            break;
            case 'Korektor Perwakilan':
              buttons.push(submitButton, rejectButton);
            break;
            case 'Korektor Pusat':
              buttons.push(saveButton, submitButton, rejectButton);
            break;
			      case 'Accounting Pusat':
              buttons.push(saveButton, submitButton, rejectButton);
            break;
            case 'Manager Keuangan':
              buttons.push(saveButton, submitButton, rejectButton);
            break;
            case 'Bendahara':
              buttons.push(submitButton, rejectButton);
            break;
            case 'Keuangan Pusat':
              if(lastWorkflow.action != 'reject')
              {
                buttons.push(submitButton, rejectButton);
              }else{
                return (
                  <div>
                      <i>RAPBS telah disubmit ke {lastWorkflow.next_role}</i>
                  </div>
                );
              }
            break;
          }

          if (user_groups_id) {
            return buttons;
          }
        } else {
          // console.log(lastWorkflow.next_role)
          return (
            <div>
              <i>RAPBS telah disubmit ke {lastWorkflow.next_role}</i>
            </div>
          );
        }
      }
    }

    if (user_group.name === 'Keuangan Sekolah' || user_group.name === 'Kepala Sekolah' || user_group.name === 'Keuangan Pusat') {
      buttons.push(submitButton);
      return buttons;
    }

    return false;
  }

  renderWorkflowHistory(workflow) {
    const rows = [];

    if(workflow) {
      for(let idx in workflow){
        const { prev_role, next_role, action, updated_at, remarks, is_done} = workflow[idx];
        const roleMap = {
          // 'Korektor Perwakilan': 'Perwakilan',
          // 'Bendahara': 'Pengurus',
          // 'Manager Keuangan': 'Manager Keuangan',
          'Keuangan Sekolah': 'Kepala Sekolah',
          // 'Keuangan Pusat': 'Kepala Sekolah',
          'Keuangan Sekolah': 'Keuangan Sekolah',

        };

        // console.log(roleMap)

        // console.log(workflow[idx])

        let flowAction = (action === 'reject') ?
          'mengembalikan ke' : ' mengajukan ke';
        let nextRole = roleMap[next_role] ? roleMap[next_role] : next_role;
        let prevRole = roleMap[prev_role] ? roleMap[prev_role] : prev_role;
        let flowValue = `${prevRole} ${flowAction} ${nextRole}`;

        // console.log(next_role)
        // console.log(action)

        if(is_done) {
          flowValue = `RAPBS telah disetujui oleh Bagian Keuangan`;
        }
        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{parseInt(idx)+1}</td>
            <td>{flowValue}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>
        ])


      }

      return rows;

      // (
      //  <table className="table4">
      //    <thead>
      //      <tr>
      //        <th>No.</th>
      //        <th>Flow</th>
      //        <th>Catatan</th>
      //        <th>Tanggal</th>
      //      </tr>
      //    </thead>
      //    <tbody>
      //      {rows}
      //    </tbody>
      //   </table>

      // )

    }

    return false;
  }

  render() {
    const {
      rapbuSummary, recommendations, workflow, remarks, selectedDivisi, total_pendapatan,
      total_pengeluaran,total_estimasi, total_saldo, budget,availablePlafonds
    } = this.state;
    const {
      location, user, divisi = {}, semesterOne, semesterTwo, history
    } = this.props;
    const { type, code } = location.state;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const isDone = lastWorkflow.is_done || false;
    let number_transaction = budget.transaction_number;
    let subTitle = budget.desc;
    let periode = budget.periode;
    let tgl_dibuat = budget.created_at;
    let unit = budget.school_unit.school_unit.name;

    let divisiValue = selectedDivisi || '';

    if(divisiValue === null && Object.keys(divisi).length > 0) {
      divisiValue = divisi.list[0].value;
    }

     let semesterTwoData = filter(semesterTwo, (o, index) => {
      if(o.divisi_id === selectedDivisi) {
        o.realIndex = index;
        return true;
      }
      return false
    });

    //console.log(rapbuSummary)


    let semesterOneData = filter(semesterOne, (o, index) => {
      if(o.divisi_id === selectedDivisi) {
        o.realIndex = index.toString();
        return true;
      }
      return false;
    });

    // console.log(selectedDivisi)

    return (
      <div className="manage-budget">
        <div className="table_title">
            <Textarea
              onChange={this.setRemarks}
              ref={this.remarks}
              classNames="hidden"
              value={remarks}
              />

              <h1>
                {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET__TITLE}&nbsp;
                {language.translate[`FINANCE__BUDGETING__MANAGE_BUDGET__${type}`]}
              </h1>
              <br></br>
              <tr></tr>
              <h2>
                <td >Periode {periode}</td>
              </h2>
        </div>


        {/* <table  style={{ width: "100%" }}> */}
          <tr>
            <td valign="top" style={{ width: "350px", fontSize: "15px" }}>
              <table className="table0" style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}>
                <tr>
                  <td width="100px">Transaksi</td>
                  <td >:</td>
                  <td >{number_transaction}</td>
                </tr>
                <tr>
                  <td width="100px">Tanggal</td>
                  <td >:</td>
                  <td >{tgl_dibuat}</td>
                </tr>
                <tr>
                  <td width="100px">Unit</td>
                  <td >:</td>
                  <td >{unit}</td>
                </tr>
                <tr>
                  <td width="100px">Judul</td>
                  <td >:</td>
                  <td>{subTitle}</td>
                </tr>
                <td>
                </td>

              </table>
            </td>
          </tr>
          <tr></tr>

        {/* </table> */}

        <br></br>

        <div className="print">
          <Button
            title="Cetak"
            onClick={() => { printJournal('table_title', 'table0', 'table','table5','table6',{
            // onClick={() => { printJournal('table_title', 'table0', 'table','tes2', 'table5',{

                pageSize: 'A4 landscape',
                printHeader: {
                  repeated: false,
                }
              });
          }}
          />
        </div>
        <br></br>

        { type === 'RAPBU'  &&
          <table className="table">
            <thead>
              <tr>
                <th className="manage-budget__row" rowSpan={2}>Biaya</th>
                <th rowSpan={1}>Total</th>
                <th rowSpan={1}>Plafon</th>
                <th rowSpan={1}>%</th>
              </tr>
            </thead>
            <tbody>
              {this.renderPlafondRows()}
            </tbody>
          </table>
        }
        <br></br>

        <br></br>

        {/* {console.log(this.props)} */}
        <DashboardPrint
        location={location}
        budget={budget}
        code={code}
        user={user}
        history={history}
        />

        <br></br>
        <br></br>

        {

          type === 'RAPBU'?
         (<Select
          data={divisi.list}
          value={divisiValue}
          onChange={this._onChangeDivisi}
        />)
        :(<h1>Keuangan</h1>)

        }

      <table className="table5">
        <BudgetRapbu
            code={code}
            rapbuSummary={rapbuSummary}
            recommendations={recommendations}
            onChangeRecommendation={this.onChangeRecommendation}
            onSelectBudgetDetail={this.onSelectBudgetDetail}
            onSelectDivisiDetail={this.onSelectDivisiDetail}
            user={user}
            workflow={workflow}
            total_pendapatan={total_pendapatan}
            total_pengeluaran={total_pengeluaran}
            total_estimasi={total_estimasi}
            total_saldo={total_saldo}
            selectedDivisi={selectedDivisi}
          />

          </table>

          {/* <br></br> */}

        { workflow && (
          <table className="table6">
            <div className="table6">
              <h2>Workflow</h2>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Flow</th>
                    <th>Catatan</th>
                    <th>Tanggal</th>
                  </tr>
                </thead>
                <tbody>
                    {this.renderWorkflowHistory(workflow)}
                </tbody>
            </div>
          </table>

            )}

        {workflow.remarks &&
        <table className="workflow_remarks">
          <div className="workflow_remarks">
            <p><b>Catatan {workflow.prev_role}</b>: {workflow.remarks}</p>
          </div>
          </table>
        }

        <div id="buttons">
          {this.renderButton()}
        </div>

      </div>
    );
  }
}
ManageBudget.propTypes = {
  semesterOne: PropTypes.array.isRequired,
  semesterTwo: PropTypes.array.isRequired,
  handleGetBudgetDetail: PropTypes.func,
  handleSaveRecommendations: PropTypes.func,
  handleSelectDivisiDetail:PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleGetPlafondOptions: PropTypes.func,
  handleReject: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
};
ManageBudget.defaultProps = {
  handleGetBudgetDetail: noop,
  handleSaveRecommendations: noop,
  handleSelectDivisiDetail : noop,
  handleGetPlafondOptions: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  user: null,
  history: null,
};
