import { generateYearList } from '../../../utils/transformer.util';

export const FINANCE_ADD_BUDGET_FORM_FIELDS = [{
  type: 'select',
  name: 'period',
  data: generateYearList(),
  label: 'Periode',
  placeholder: 'pilih periode',
  full: true,
},
{
  type: 'select',
  name: 'school_unit_code',
  label: 'Unit',
  placeholder: 'Pilih Unit',
  full: true,
  data: [
    { label: 'Ricci I TK/Pre', value: 'ricci_I_tk/pre' },
    { label: 'Ricci I SD', value: 'ricci_I_sd' },
    { label: 'Ricci I SMP', value: 'ricci_I_sma' },
    { label: 'Ricci I SMA', value: 'ricci_I_sma' },
    { label: 'Ricci II TK/Pre', value: 'ricci_II_tk/pre' },
    { label: 'Ricci II SD', value: 'ricci_II_sd' },
    { label: 'Ricci II SMP', value: 'ricci_II_sma' },
    { label: 'Ricci II SMA', value: 'ricci_II_sma' },
  ],
},

{
  type: 'textarea',
  name: 'desc',
  label: 'Deskripsi',
  placeholder: 'Isi deskripsi',
  full: true,
},
];

export const BUDGET_REQUIRED_FIELD = ['parameter_code', 'desc', 'quantity', 'price'];
export const BUDGET_REQUIRED_FIELD_AllO = ['plafond'];
export const BUDGET_REQUIRED_EXECUTION_TIME = ['execution_time'];


export const FINANCE_BUDGET_TABLE_FIELDS = {
  label: [
    'No',
    'Periode',
    'Deskripsi',
    'Status',
    '',
  ],
  value: [{}, { type: 'string', attribute: 'periode' }, { type: 'string', attribute: 'desc', disabled: true }, { type: 'string', attribute: 'status', disabled: true }],
};

export const FINANCE_PAYMENT_JOURNAL_FORM_FIELDS = [{
  type: 'text',
  inputType: 'number',
  name: 'nominal',
  label: 'Nominal',

}];

export const RAPBS_RAPBY = [{
  type: 'select',
  name: 'type',
  label: 'Type Anggaran',
  placeholder: 'Pilih Unit',
  data: [
    { label: 'Rapbs', value: 'rapbu' },
    { label: 'Rapby', value: 'rapby' },
  ],

}];

export const BUDGET_KELOLA_ALOKASI_PENGELUARAN = [{
  type: 'date',
  name: 'tanggal',
  label: 'Tanggal',
  placeholder: '',
  full : true,
},
{
  type: 'text',
  name: 'no_transaksi',
  label: 'No Transaksi',
  placeholder: '',
  full : true,
},{
  type: 'text',
  name: 'unit',
  label: 'Unit',
  placeholder: '',
  full : true,
},



{
  type: 'select',
  name: 'period',
  data: generateYearList(),
  label: 'Periode',
  placeholder: 'pilih periode',
  full: true,
},
{
  type: 'text',
  inputType: 'number',
  name: 'total',
  label: 'Total',
  full : true,
},
];
