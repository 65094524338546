// import Http from './http.util';
import * as Http from './axios.util';
import {
  SERVER_URL_2, SERVER_URL_3, SERVER_URL_4, SERVER_URL_5, API_LOCAL, API_BRIDGE, API_AKADEMIS,
} from '../configs/api.config';

export function TEST(data, options = {}) {
  return Http.POST('TEST', data, '', options);
}

export function AUTH(path, options = {}) {
  return Http.GET('AUTH', path, options);
}

// export function LOGIN(data) {
//   return Http.POST('LOGIN', data, '', { baseURL: API_BRIDGE });
// }

export function LOGIN(data) {
  return Http.POST('LOGIN', data);
}

export function PROFILE(path = '', options = {}) {
  return Http.GET('PROFILE', path, options);
}

export function OPTIONS(data, path = '', options = {}) {
  return Http.POST('OPTIONS', data, path, options);
}

export function PARAMS(data, path = '', params = {}) {
  return Http.POST('PARAMS', data, path, params);
}

export function UNITS(data, path = '', params = {}) {
  return Http.POST('UNITS', data, path, params);
}

export function CLASSROOMS(data, path = '', params = {}) {
  return Http.POST('CLASSROOMS', data, path, params);
}

export function DIVISI(data, path = '', options = {}) {
  return Http.POST('DIVISI', data, path, options);
}

export function BUDGET(data, path = '', options = {}) {
  return Http.POST('BUDGET', data, path, options);
}
export function REPORT_RAPBS(data, path = '', options = {}) {
  return Http.POST('REPORT_RAPBS', data, path, options);
}

export function BUDGET_REQUEST(data, path = '', options = {}) {
  return Http.POST('BUDGET_REQUEST', data, path, options);
}

export function NON_BUDGET_POST(data, path = '', options = {}) {
  return Http.POST('NON_BUDGET', data, path, options);
}

export function REALIZATION_POST(data, path = '', options = {}) {
  return Http.POST('REALIZATION', data, path, options);
}

export function FILE(data, path = '', options = {}) {
  return Http.POST('FILE', data, path, options);
}

export function DOWNLOAD(path = '', options = {}) {
  return Http.GET('FILE', path, options);
}

export function PARAM(data, path = '', options = {}) {
  return Http.POST('PARAM', data, path, options);
}

export function PARAM_STAFF(data, path = '', options = {}) {
  return Http.POST('PARAM_STAFF', data, path, { ...options, baseURL: SERVER_URL_2 });
}
// export function PARAM_AKADEMI(data, path = '', options = {}) {
//   return Http.POST('PARAM_AKADEMI', data, path, { ...options, baseURL: SERVER_URL_3 });
// }

export function FINANCE_OUTCOME(path = '', options = {}) {
  return Http.GET('FINANCE_OUTCOME', path, options);
}

export function FINANCE_RAPBU(path = '', options = {}) {
  return Http.GET('FINANCE_RAPBU', path, options);
}

export function FINANCE_CASH_JOURNAL(path = '', options = {}) {
  return Http.GET('FINANCE_CASH_JOURNAL', path, options);
}

export function FINANCE_JOURNAL_GET(path = '', options = {}) {
  return Http.GET('FINANCE_JOURNAL', path, options);
}

export function FINANCE_JOURNAL_POST(data, path = '', options = {}) {
  return Http.POST('FINANCE_JOURNAL', data, path, options);
}

export function FINANCE_CASH_JOURNAL_BY_ID(path = '', options = {}) {
  return Http.GET('FINANCE_CASH_JOURNAL_BY_ID', path, options);
}

export function FINANCE_BANK_JOURNAL(path = '', options = {}) {
  return Http.GET('FINANCE_BANK_JOURNAL', path, options);
}

export function FINANCE_BANK_JOURNAL_BY_ID(path = '', options = {}) {
  return Http.GET('FINANCE_BANK_JOURNAL_BY_ID', path, options);
}

export function FINANCE_STARTING_BALANCE(path = '', options = {}) {
  return Http.POST('FINANCE_STARTING_BALANCE', path, options);
}

export function FINANCE_SETTINGS(path = '', options = {}) {
  return Http.POST('FINANCE_SETTINGS', path, options);
}

export function STAFF_POST(data, path = '', options = {}) {
  return Http.POST('STAFF', data, path, { ...options, baseURL: SERVER_URL_2 });
}

export function SK_POST(data, path = '', options = {}) {
  return Http.POST('SK', data, path, { ...options, baseURL: SERVER_URL_2 });
}

export function REPORT_POST(data, path = '', options = {}) {
  return Http.POST('REPORT', data, path, options);
}

export function TAX_POST(data, path = '', options = {}) {
  return Http.POST('TAX', data, path, { ...options, baseURL: SERVER_URL_5 });
}

export function WORKFLOW(data, path = '', options = {}) {
  return Http.POST('WORKFLOW', data, path, options);
}

export function CLASSES(data, path = '', options = {}) {
  return Http.POST('CLASSES', data, path, { ...options, baseURL: SERVER_URL_2 });
}

export function STUDENTS_POST(data, path = '', options = {}) {
  return Http.POST('STUDENTS', data, path, { ...options, baseURL: SERVER_URL_4 });
}

export function NOTIFICATION(data, path = '', options = {}) {
  return Http.POST('NOTIFICATION', data, path, options);
}

export function NOTIFICATION_STREAM(data, path = '', options = {}) {
  return Http.GET('NOTIFICATION_STREAM', data, path, options);
}

export function PARAM_AKADEMI(data, path = '', options = {}) {
  return Http.POST('PARAM_AKADEMI', data, path, { ...options, baseURL: API_LOCAL });
}

export function ACADEMICS(data, path = '', options = {}) {
  return Http.POST('ACADEMICS', data, path, { ...options, baseURL: API_LOCAL });
}

export function ACADEMICS_PARAM(data, path = '', options = {}) {
  return Http.POST('ACADEMICS_PARAM', data, path, { ...options, baseURL: API_LOCAL });
}

export function ACADEMICS_PARAM2(data, path = '', options = {}) {
  return Http.POST('ACADEMICS_PARAM2', data, path, { ...options, baseURL: API_LOCAL });
}

export function AKADEMIS(data, path = '', options = {}) {
  return Http.POST('AKADEMIS', data, path, { ...options, baseURL: API_AKADEMIS });
}

// export function GETACADEMICS(data, path = '', options = {}) {
//   return Http.GET2('GETACADEMICS', data, path, options, {baseURL: 'http://localhost:8080'});
// }

export function GETACADEMICS(path = '', options = {}) {
  return Http.GET('GETACADEMICS', path, { ...options, baseURL: API_LOCAL });
}
