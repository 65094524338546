import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty, filter } from 'lodash';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import language from '../../../../../languages';
import ReactExport from "react-export-excel";
import { Button, Input, Pagination } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const userGroupPusat = [
  'Korektor Pusat',
  'Manager Keuangan',
  'Bendahara',
];

export default class BudgetRapbuRekap extends Component {

constructor(props) {
    super(props);
    this.state = {
      list: [],
      listAmount: 5,
      form:{
        value:{
          tema:'',
        },
      },
      selectedTema :'',
    };

    this.setRef = this.setRef.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this._onFormChange = this._onFormChange.bind(this)
    this.onFormChange = this.onFormChange.bind(this);
  
  }
  
  setRef(el) {
    const { input } = el;
    const { dataset } = input;
    const { pos, referenceid } = dataset;
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  onSearchTema(tema = null){
    // let divisiValue = this.state.selectedDivisi?this.state.selectedDivisi:2;
    const { handleGetTemaOptions } = this.props;
    const { target } = tema;
    const { value } = target;
    handleGetTemaOptions({
      tema,
      value
      // divisiValue
    });
    this.setState({ selectedTema : target.value})
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)

    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
    // console.log(dataset.arrayPosition)

  }
  

  renderRows(list, budgetloop) {
    const { recommendations, user,
       onSelectBudgetDetail , onSearchTema } = this.props;
    const { user_group } = user;
    const isBendahara = user_group && user_group.name === 'Bendahara';
    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    let rows = [];
    // console.log(list)


    if(list.data !== undefined){
      // console.log(list.length)
      // console.log(list.data)
      // console.log(list.data.length)

      map(list.data, (data, idx) => {
        // let id_header_tema: 1 =

        rows.push([
          <tr key={`budget_rapbu_rows_${idx}`}>
            <td colspan="10"><b>{data.code_header_tema + " - "+ data.name}</b></td>
          </tr>
          ]);

          let total2 = 0;
          let total3 = 0;

          if(data.sub_header_tema.length==0){

            // console.log(this.state.selectedTema.code)

            if(this.state.selectedTema.code){
              let PengeluaranData = data.tema.filter((o, index) =>
              {
                return o.code === this.state.selectedTema.code;}

              )

              map(PengeluaranData, (data_tema, idx_tema) => {
                let execution_time = "";
                let total =0;
                let target="";
                let i = "";
                let a = "";
                let nominal =0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for(i=0; i<budgetloop.length; i++){
                  if(budgetloop[i].tema == data_tema.id){
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if(budgetloop[i].target!= null){
                      target = budgetloop[i].target;
                    }

                    // console.log(budgetloop)

                      for(a=0; a<budgetloop[i].allocations.length; a++){
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        nominal = budgetloop[i].allocations[a].nominal;
                        if(plafond_id ===1){
                          rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                        } else if(plafond_id ===2){
                          kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if(plafond_id ===3){
                          danaNominal = parseInt(danaNominal) + parseInt(nominal);
                        } else if(plafond_id ===4){
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                      }
                      total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                  }

                }

                total2 += total;
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    {/* aaaaa */}
                    <td>{data_tema.code}</td>
                    <td>{data_tema.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>
                  ]);
              });

          }

          else{
            map(data.tema, (data_tema, idx_tema) => {
              let execution_time = "";
              let total =0;
              let target="";
              let i = "";
              let a = "";
              let nominal =0;
              let rutinNominal = 0;
              let kegiatanNominal = 0;
              let bppNominal = 0;
              let danaNominal = 0;
              let plafond_id = "";

              for(i=0; i<budgetloop.length; i++){
                if(budgetloop[i].tema == data_tema.id){
                  execution_time = budgetloop[i].execution_time;
                  // total += Number(budgetloop[i].total);
                  if(budgetloop[i].target!= null){
                    target = budgetloop[i].target;
                  }

                  // console.log(budgetloop)

                    for(a=0; a<budgetloop[i].allocations.length; a++){
                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                      nominal = budgetloop[i].allocations[a].nominal;
                      if(plafond_id ===1){
                        rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                      } else if(plafond_id ===2){
                        kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                      } else if(plafond_id ===3){
                        danaNominal = parseInt(danaNominal) + parseInt(nominal);
                      } else if(plafond_id ===4){
                        bppNominal = parseInt(bppNominal) + parseInt(nominal);
                      }
                    }
                    total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                }

              }

              total2 += total;
              
              rows.push([
                <tr key={`budget_rapbu_rows_${idx}`}>
                  {/* aaaaa */}
                  <td>{data_tema.code}</td>
                  <td>{data_tema.name}</td>
                  <td>{execution_time}</td>
                  <td>{target}</td>
                  <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                  <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                  <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                  <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                  <td className="nominal">Rp.{commaFormatted(total)}</td>
                </tr>
                ]);
            });
          }



            for (let t=0; t<1; t++){
              rows.push([

                <tr key={`budget_rapbu_rows_${idx}`}>
                  <td colspan="10" className="nominal">Rp. {commaFormatted(total2)}</td>
                </tr>
                ]);
            }
          }

          else if(data.tema.length==0){

            if(this.state.selectedTema.code){
              let PengeluaranData8 = data.sub_header_tema.filter((o, index) =>

                {
                  return o.code_sub_header_tema === this.state.selectedTema.code;}

                )

                map(PengeluaranData8, (data, idx_sub_header_tema) => {
                  let execution_time = "";
                  let total =0;
                  let target="";
                  let i = "";
                  let a = "";
                  let nominal =0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";

                  for(i=0; i<budgetloop.length; i++){
                    if(budgetloop[i].tema == data.id){
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if(budgetloop[i].target!= null){
                        target = budgetloop[i].target;
                      }

                      // console.log(budgetloop)

                        for(a=0; a<budgetloop[i].allocations.length; a++){
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          nominal = budgetloop[i].allocations[a].nominal;
                          if(plafond_id ===1){
                            rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                          } else if(plafond_id ===2){
                            kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if(plafond_id ===3){
                            danaNominal = parseInt(danaNominal) + parseInt(nominal);
                          } else if(plafond_id ===4){
                            bppNominal = parseInt(bppNominal) + parseInt(nominal);
                          }
                        }
                        total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                    }

                  }
                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td>{data.code_sub_header_tema}</td>
                      <td>{data.name}</td>
                      <td>{execution_time}</td>
                      <td>{target}</td>
                      <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                      <td className="nominal">Rp.{commaFormatted(total)}</td>
                    </tr>
                    ]);
                });
            }
            
            else{

              // console.log(total2)
              
              

              map(data.sub_header_tema, (data, idx_sub_header_tema) => { 
                
                let totalht2= 0;
                
                map(data.tema, (data_tema, idx_tema) => {
                  
                  let total =0;
                  let target="";
                  let i = "";
                  let a = "";
                  let nominal =0;
                  let rutinNominal2 = 0;
                  let kegiatanNominal2 = 0;
                  let bppNominal2 = 0;
                  let danaNominal2 = 0;
                  let plafond_id = "";
                  let execution_time = "";

                  for(i=0; i<budgetloop.length; i++){
                    // console.log(budgetloop[i].length)
                    if(budgetloop[i].tema == data_tema.id){
                      // console.log(budgetloop[i].execution_time)
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if(budgetloop[i].target!= null){
                        target = budgetloop[i].target;
                      }
                        for(a=0; a<budgetloop[i].allocations.length; a++){
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id); 
                          nominal = budgetloop[i].allocations[a].nominal;
                          if(plafond_id ===1){
                            rutinNominal2 = parseInt(rutinNominal2) + parseInt(nominal);
                          } else if(plafond_id ===2){
                            kegiatanNominal2 = parseInt(kegiatanNominal2) + parseInt(nominal);
                          } else if(plafond_id ===3){
                            danaNominal2 = parseInt(danaNominal2) + parseInt(nominal);
                          } else if(plafond_id ===4){
                            bppNominal2 = parseInt(bppNominal2) + parseInt(nominal);
                          }
                          // console.log(rutinNominal)
                        } 
                        total = rutinNominal2+kegiatanNominal2+danaNominal2+bppNominal2
                    }
    
                  }
                  
                  totalht2 += total; 
                 

                
                });
                total3 += totalht2;
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    {/* <td>{data_tema.code}dadada</td> */}
                    <td>{data.code_sub_header_tema}</td>
                    <td>{data.name}</td>
                    <td colSpan='7' className="nominal">Rp.{commaFormatted(totalht2)}</td>
                    {/* <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td> */}
                  </tr>
                  ]);

            
              });
            }
            // console.log(total3)

            for (let t=0; t<1; t++){
              rows.push([

                <tr key={`budget_rapbu_rows_${idx}`}>
                  <td colspan="10" className="nominal">Rp. {commaFormatted(total3)}</td>
                </tr>
                ]);
            }


          map(data.sub_header_tema, (data, idx_header_tema) => {
            let total2 = 0;
            rows.push([
            <tr key={`budget_rapbu_rows_${idx}`}>
              <td colspan="10"><b>{data.code_sub_header_tema + " - " + data.name}</b></td>
            </tr>
            ]);

            if(this.state.selectedTema.code)
            {
              let PengeluaranData2 = data.tema.filter((o, index) =>
              {
                return o.code === this.state.selectedTema.code;}

              )

              map(PengeluaranData2, (data_tema, idx_tema) => {
                let execution_time = "";
                let total =0;
                let target="";
                let i = "";
                let a = "";
                let nominal =0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for(i=0; i<budgetloop.length; i++){
                  // console.log(budgetloop[i].length)
                  if(budgetloop[i].tema == data_tema.id){
                    // console.log(budgetloop[i].execution_time)
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if(budgetloop[i].target!= null){
                      target = budgetloop[i].target;
                    }
                      for(a=0; a<budgetloop[i].allocations.length; a++){
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;
                        if(plafond_id ===1){
                          rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                        } else if(plafond_id ===2){
                          kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if(plafond_id ===3){
                          danaNominal = parseInt(danaNominal) + parseInt(nominal);
                        } else if(plafond_id ===4){
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                        // console.log(rutinNominal)
                      }
                      total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                  }

                }

                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td>{data_tema.code}</td>
                    <td>{data_tema.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>
                  ]);
              });
            }
            else{

              // console.log(total2)

              map(data.tema, (data_tema, idx_tema) => {
                let execution_time = "";
                let total =0;
                let target="";
                let i = "";
                let a = "";
                let nominal =0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for(i=0; i<budgetloop.length; i++){
                  // console.log(budgetloop[i].length)
                  if(budgetloop[i].tema == data_tema.id){
                    // console.log(budgetloop[i].execution_time)
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if(budgetloop[i].target!= null){
                      target = budgetloop[i].target;
                    }
                      for(a=0; a<budgetloop[i].allocations.length; a++){
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;
                        if(plafond_id ===1){
                          rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                        } else if(plafond_id ===2){
                          kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if(plafond_id ===3){
                          danaNominal = parseInt(danaNominal) + parseInt(nominal);
                        } else if(plafond_id ===4){
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                        // console.log(rutinNominal)
                      }
                      total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                  }

                }
                
                total2 += total; 
                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    {/* <td>{data_tema.code}dadada</td> */}
                    <td>{data_tema.code}</td>
                    <td>{data_tema.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>
                  ]);
              });
            }
            
            // console.log(total2 +"aaaaa")
            for (let t=0; t<1; t++){
              rows.push([

                <tr key={`budget_rapbu_rows_${idx}`}>
                  {/* <td colspan="10" className="nominal">Rp. {commaFormatted(total)}</td> */}
                  <td colspan="10" className="nominal">Rp. {commaFormatted(total2)}</td>
                </tr>
                ]);
            }

          });
        }

        // sampe sini

        else if(data.sub_header_tema && data.tema){

          if(this.state.selectedTema.code)
          {
            let PengeluaranData9 = data.sub_header_tema&& data.tema.filter((o, index) =>

            {
              return o.code_sub_header_tema === this.state.selectedTema.code;}

            )

               // ini data yg subtemanya dluan pengenya tema dluan tapi struktur datanya salah
          map(PengeluaranData9, (data, idx_sub_header_tema) => {
            let execution_time = "";
            let total =0;
            let target="";
            let i = "";
            let a = "";
            let nominal =0;
            let rutinNominal = 0;
            let kegiatanNominal = 0;
            let bppNominal = 0;
            let danaNominal = 0;
            let plafond_id = "";
            for(i=0; i<budgetloop.length; i++){
              // console.log(budgetloop[i].length)
              if(budgetloop[i].tema == data.id){
                // console.log(budgetloop[i].execution_time)
                execution_time = budgetloop[i].execution_time;
                // total += Number(budgetloop[i].total);
                if(budgetloop[i].target!= null){
                  target = budgetloop[i].target;
                }
                  for(a=0; a<budgetloop[i].allocations.length; a++){
                    plafond_id = budgetloop[i].allocations[a].plafond_id;
                    // console.log(plafond_id);
                    nominal = budgetloop[i].allocations[a].nominal;
                    if(plafond_id ===1){
                      rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                    } else if(plafond_id ===2){
                      kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                    } else if(plafond_id ===3){
                      danaNominal = parseInt(danaNominal) + parseInt(nominal);
                    } else if(plafond_id ===4){
                      bppNominal = parseInt(bppNominal) + parseInt(nominal);
                    }
                    // console.log(rutinNominal)
                  }
                  total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
              }

            }
            rows.push([
              <tr key={`budget_rapbu_rows_${idx}`}>
                <td>{data.code_sub_header_tema}</td>
                <td>{data.name}</td>
                <td>{execution_time}</td>
                <td>{target}</td>
                <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                <td className="nominal">Rp.{commaFormatted(total)}</td>
              </tr>
              ]);
          });
          // for (let t=0; t<1; t++){
          //   rows.push([

          //     <tr key={`budget_rapbu_rows_${idx}`}>
          //       <td colspan="10" className="nominal">Rp. {commaFormatted(total2)}</td>
          //     </tr>
          //     ]);
          // }

          }

          else{
               // ini data yg subtemanya dluan pengenya tema dluan tapi struktur datanya salah
          map(data.sub_header_tema, (data, idx_sub_header_tema) => {
            let execution_time = "";
            let total =0;
            let target="";
            let i = "";
            let a = "";
            let nominal =0;
            let rutinNominal = 0;
            let kegiatanNominal = 0;
            let bppNominal = 0;
            let danaNominal = 0;
            let plafond_id = "";
            for(i=0; i<budgetloop.length; i++){
              // console.log(budgetloop[i].length)
              if(budgetloop[i].tema == data.id){
                // console.log(budgetloop[i].execution_time)
                execution_time = budgetloop[i].execution_time;
                // total += Number(budgetloop[i].total);
                if(budgetloop[i].target!= null){
                  target = budgetloop[i].target;
                }
                  for(a=0; a<budgetloop[i].allocations.length; a++){
                    plafond_id = budgetloop[i].allocations[a].plafond_id;
                    // console.log(plafond_id);
                    nominal = budgetloop[i].allocations[a].nominal;
                    if(plafond_id ===1){
                      rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                    } else if(plafond_id ===2){
                      kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                    } else if(plafond_id ===3){
                      danaNominal = parseInt(danaNominal) + parseInt(nominal);
                    } else if(plafond_id ===4){
                      bppNominal = parseInt(bppNominal) + parseInt(nominal);
                    }
                    // console.log(rutinNominal)
                  }
                  total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
              }

            }
            rows.push([
              <tr key={`budget_rapbu_rows_${idx}`}>
                <td>{data.code_sub_header_tema}</td>
                <td>{data.name}</td>
                <td>{execution_time}</td>
                <td>{target}</td>
                <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                <td className="nominal">Rp.{commaFormatted(total)}</td>
              </tr>
              ]);
          });
          // for (let t=0; t<1; t++){
          //   rows.push([

          //     <tr key={`budget_rapbu_rows_${idx}`}>
          //       <td colspan="10" className="nominal">Rp. {commaFormatted(total2)}</td>
          //     </tr>
          //     ]);
          // }
          }
          // sampe sini

          map(data.tema, (data_tema, idx_tema) => {
            let execution_time = "";
            let total =0;
            let target="";
            let i = "";
            let a = "";
            let nominal =0;
            let rutinNominal = 0;
            let kegiatanNominal = 0;
            let bppNominal = 0;
            let danaNominal = 0;
            let plafond_id = "";

            for(i=0; i<budgetloop.length; i++){
              // console.log(budgetloop[i].length)
              if(budgetloop[i].tema == data_tema.id){
                // console.log(budgetloop[i].execution_time)
                execution_time = budgetloop[i].execution_time;
                // total += Number(budgetloop[i].total);
                if(budgetloop[i].target!= null){
                  target = budgetloop[i].target;
                }
                  for(a=0; a<budgetloop[i].allocations.length; a++){
                    plafond_id = budgetloop[i].allocations[a].plafond_id;
                    // console.log(plafond_id);
                    nominal = budgetloop[i].allocations[a].nominal;
                    if(plafond_id ===1){
                      rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                    } else if(plafond_id ===2){
                      kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                    } else if(plafond_id ===3){
                      danaNominal = parseInt(danaNominal) + parseInt(nominal);
                    } else if(plafond_id ===4){
                      bppNominal = parseInt(bppNominal) + parseInt(nominal);
                    }
                    // console.log(rutinNominal)
                  }
                  total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
              }

            }

            rows.push([
              <tr key={`budget_rapbu_rows_${idx}`}>
                <td>{data_tema.code}</td>
                <td>{data_tema.name}</td>
                <td>{execution_time}</td>
                <td>{target}</td>
                <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                <td className="nominal">Rp.{commaFormatted(total)}</td>
              </tr>
              ]);
          });
          // 1

          for (let t=0; t<1; t++){
            rows.push([

              <tr key={`budget_rapbu_rows_${idx}`}>
                <td colspan="10" className="nominal">Rp. {commaFormatted(total2)}</td>
              </tr>
              ]);
          }


          // ini data yg subtemanya dluan pengenya tema dluan tapi struktur datanya salah

          map(data.sub_header_tema, (data, idx_header_tema) => {
            rows.push([
            <tr key={`budget_rapbu_rows_${idx}`}>
              <td colspan="10"><b>{ data.code_sub_header_tema + " - " + data.name}</b></td>
            </tr>
            ]);

            if(this.state.selectedTema.code)
              {
                let PengeluaranData3 = data.tema.filter((o, index) =>

                {
                  return o.code === this.state.selectedTema.code;}
                )
                map(PengeluaranData3, (data_tema, idx_tema) => {

                  let execution_time = "";
                  let total =0;
                  let target="";
                  let i = "";
                  let a = "";
                  let nominal =0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";

                  for(i=0; i<budgetloop.length; i++){
                    if(budgetloop[i].tema == data_tema.id){
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if(budgetloop[i].target!= null){
                        target = budgetloop[i].target;
                      }

                        for(a=0; a<budgetloop[i].allocations.length; a++){
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;

                          if(plafond_id ===1){
                            rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                          } else if(plafond_id ===2){
                            kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if(plafond_id ===3){
                            danaNominal = parseInt(danaNominal) + parseInt(nominal);
                          } else if(plafond_id ===4){
                            bppNominal = parseInt(bppNominal) + parseInt(nominal);
                          }

                        }
                        total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                    }

                  }

                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td>{data_tema.code}</td>
                      <td>{data_tema.name}</td>
                      <td>{execution_time}</td>
                      <td>{target}</td>
                      <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                      <td className="nominal">Rp.{commaFormatted(total)}</td>
                    </tr>
                    ]);
                });
              }

              else{
                map(data.tema, (data_tema, idx_tema) => {

                  let execution_time = "";
                  let total =0;
                  let target="";
                  let i = "";
                  let a = "";
                  let nominal =0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";

                  for(i=0; i<budgetloop.length; i++){
                    if(budgetloop[i].tema == data_tema.id){
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if(budgetloop[i].target!= null){
                        target = budgetloop[i].target;
                      }

                        for(a=0; a<budgetloop[i].allocations.length; a++){
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;

                          if(plafond_id ===1){
                            rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                          } else if(plafond_id ===2){
                            kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if(plafond_id ===3){
                            danaNominal = parseInt(danaNominal) + parseInt(nominal);
                          } else if(plafond_id ===4){
                            bppNominal = parseInt(bppNominal) + parseInt(nominal);
                          }

                        }
                        total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                    }

                  }

                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td>{data_tema.code}</td>
                      <td>{data_tema.name}</td>
                      <td>{execution_time}</td>
                      <td>{target}</td>
                      <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                      <td className="nominal">Rp.{commaFormatted(total)}</td>
                    </tr>
                    ]);
                });
              }

          });
          // 2

          for (let t=0; t<1; t++){
            rows.push([

              <tr key={`budget_rapbu_rows_${idx}`}>
                <td colspan="10" className="nominal">Rp. {commaFormatted(total2)}</td>
              </tr>
              ]);
          }



        }

        map(data.sub_header_tema, (data, idx_sub_header_tema) => {

          // console.log(data_header_tema.prm_detail_header_tema)
          if(data.prm_detail_header_tema){

            map(data.prm_detail_header_tema, (data, idx_header_tema) => {
              rows.push([
              <tr key={`budget_rapbu_rows_${idx}`}>
                <td colspan="10"><b>{data.code_detail_header_tema + " - " + data.name}</b></td>
              </tr>
              ]);

              if(this.state.selectedTema.code)
              {
                let PengeluaranData6 = data.tema.filter((o, index) =>

                {
                  return o.code === this.state.selectedTema.code;}

                )
                map(PengeluaranData6, (data_tema, idx_tema) => {

                  let execution_time = "";
                  let total =0;
                  let target="";
                  let i = "";
                  let a = "";
                  let nominal =0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = "";

                  for(i=0; i<budgetloop.length; i++){
                    if(budgetloop[i].tema == data_tema.id){
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if(budgetloop[i].target!= null){
                        target = budgetloop[i].target;
                      }

                        for(a=0; a<budgetloop[i].allocations.length; a++){
                          plafond_id = budgetloop[i].allocations[a].plafond_id;
                          // console.log(plafond_id);
                          nominal = budgetloop[i].allocations[a].nominal;

                          if(plafond_id ===1){
                            rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                          } else if(plafond_id ===2){
                            kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                          } else if(plafond_id ===3){
                            danaNominal = parseInt(danaNominal) + parseInt(nominal);
                          } else if(plafond_id ===4){
                            bppNominal = parseInt(bppNominal) + parseInt(nominal);
                          }

                        }
                        total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                    }

                  }

                  rows.push([
                    <tr key={`budget_rapbu_rows_${idx}`}>
                      <td>{data_tema.code}</td>
                      {/* {console.log(data_tema.code)} */}
                      <td>{data_tema.name}</td>
                      <td>{execution_time}</td>
                      <td>{target}</td>
                      <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                      <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                      <td className="nominal">Rp.{commaFormatted(total)}</td>
                    </tr>
                    ]);
                });

              }

            else{
              map(data.tema, (data_tema, idx_tema) => {

                let execution_time = "";
                let total =0;
                let target="";
                let i = "";
                let a = "";
                let nominal =0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = "";

                for(i=0; i<budgetloop.length; i++){
                  if(budgetloop[i].tema == data_tema.id){
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if(budgetloop[i].target!= null){
                      target = budgetloop[i].target;
                    }

                      for(a=0; a<budgetloop[i].allocations.length; a++){
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;

                        if(plafond_id ===1){
                          rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                        } else if(plafond_id ===2){
                          kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if(plafond_id ===3){
                          danaNominal = parseInt(danaNominal) + parseInt(nominal);
                        } else if(plafond_id ===4){
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }

                      }
                      total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                  }

                }

                rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td>{data_tema.code}</td>
                    {/* {console.log(data_tema.code)} */}
                    <td>{data_tema.name}</td>
                    <td>{execution_time}</td>
                    <td>{target}</td>
                    <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                    <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                    <td className="nominal">Rp.{commaFormatted(total)}</td>
                  </tr>
                  ]);
              });

            }


              if (data.prm_sub_detail_header_tema){
                // console.log(data.prm_sub_detail_header_tema)
                map(data.prm_sub_detail_header_tema, (data, idx_header_tema) => {
                  rows.push([
                  <tr key={`budget_rapbu_rows_${idx}`}>
                    <td colspan="10"><b>{data.code_sub_detail_header_tema + " - " + data.name}</b></td>
                  </tr>
                  ]);

                  if(this.state.selectedTema.code)
                  {
                    let PengeluaranData8 = data.tema.filter((o, index) =>
                    {
                      return o.code === this.state.selectedTema.code;}

                    )

                    map(PengeluaranData8, (data_tema, idx_tema) => {
                      let execution_time = "";
                      let total =0;
                      let target="";
                      let i = "";
                      let a = "";
                      let nominal =0;
                      let rutinNominal = 0;
                      let kegiatanNominal = 0;
                      let bppNominal = 0;
                      let danaNominal = 0;
                      let plafond_id = "";


                      for(i=0; i<budgetloop.length; i++){
                        if(budgetloop[i].tema == data_tema.id){
                          execution_time = budgetloop[i].execution_time;
                          // total += Number(budgetloop[i].total);
                          if(budgetloop[i].target!= null){
                            target = budgetloop[i].target;
                          }

                            for(a=0; a<budgetloop[i].allocations.length; a++){
                              plafond_id = budgetloop[i].allocations[a].plafond_id;
                              // console.log(plafond_id);
                              nominal = budgetloop[i].allocations[a].nominal;

                              if(plafond_id ===1){
                                rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                              } else if(plafond_id ===2){
                                kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                              } else if(plafond_id ===3){
                                danaNominal = parseInt(danaNominal) + parseInt(nominal);
                              } else if(plafond_id ===4){
                                bppNominal = parseInt(bppNominal) + parseInt(nominal);
                              }

                            }
                            total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                        }

                      }

                      rows.push([
                        <tr key={`budget_rapbu_rows_${idx}`}>
                          <td>{data_tema.code}</td>
                          <td>{data_tema.name}</td>
                          <td>{execution_time}</td>
                          <td>{target}</td>
                          <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                          <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                          <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                          <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                          <td className="nominal">Rp.{commaFormatted(total)}</td>
                        </tr>
                        ]);
                    });
                  }
                  else{
                    map(data.tema, (data_tema, idx_tema) => {
                      let execution_time = "";
                      let total =0;
                      let target="";
                      let i = "";
                      let a = "";
                      let nominal =0;
                      let rutinNominal = 0;
                      let kegiatanNominal = 0;
                      let bppNominal = 0;
                      let danaNominal = 0;
                      let plafond_id = "";


                      for(i=0; i<budgetloop.length; i++){
                        if(budgetloop[i].tema == data_tema.id){
                          execution_time = budgetloop[i].execution_time;
                          // total += Number(budgetloop[i].total);
                          if(budgetloop[i].target!= null){
                            target = budgetloop[i].target;
                          }

                            for(a=0; a<budgetloop[i].allocations.length; a++){
                              plafond_id = budgetloop[i].allocations[a].plafond_id;
                              // console.log(plafond_id);
                              nominal = budgetloop[i].allocations[a].nominal;

                              if(plafond_id ===1){
                                rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                              } else if(plafond_id ===2){
                                kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                              } else if(plafond_id ===3){
                                danaNominal = parseInt(danaNominal) + parseInt(nominal);
                              } else if(plafond_id ===4){
                                bppNominal = parseInt(bppNominal) + parseInt(nominal);
                              }

                            }
                            total = rutinNominal+kegiatanNominal+danaNominal+bppNominal
                        }

                      }

                      rows.push([
                        <tr key={`budget_rapbu_rows_${idx}`}>
                          <td>{data_tema.code}</td>
                          <td>{data_tema.name}</td>
                          <td>{execution_time}</td>
                          <td>{target}</td>
                          <td clasname="nominal">Rp.{commaFormatted(rutinNominal)}</td>
                          <td clasname="nominal">Rp.{commaFormatted(kegiatanNominal)}</td>
                          <td clasname="nominal">Rp.{commaFormatted(danaNominal)}</td>
                          <td clasname="nominal">Rp.{commaFormatted(bppNominal)}</td>
                          <td className="nominal">Rp.{commaFormatted(total)}</td>
                        </tr>
                        ]);
                    });
                  }




                });

              }



            });
          }

        });

      });
    }

    return rows;
  }

  renderHeader() {
    const {
       user,
    } = this.props;
    const { user_group } = user;

    const isBendahara = (user_group && user_group.name === 'Bendahara') ?
      true : false;
    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    return (
      <thead>
        <tr>
          <th className="manage-budget__row table" style={{ width: "50%" }} rowSpan={2} >Kode</th>
          <th className="manage-budget__row table" style={{ width: "5%" }} rowSpan={2}>Nama</th>
          <th className="manage-budget__row table" rowSpan={2}>Waktu Pelaksanaan</th>
          <th className="manage-budget__row table" rowSpan={2}>Target</th>
          <th className="manage-budget__row table" rowSpan={2}>Rutin</th>
          <th className="manage-budget__row table" rowSpan={2}>Kegitan</th>
          <th className="manage-budget__row table" rowSpan={2}>Dana Pemerintah</th>
          <th className="manage-budget__row table" rowSpan={2}>Biaya Pengembangan Pendidikan</th>
          <th className="manage-budget__row table" rowSpan={2}>Total</th>

        </tr>
      </thead>

    )
  }
  render() {
    const {
     budgetloop, dataloop,  recommendations, error, target, onSearchTema, tema,
       user,
    } = this.props;
    const { list  } = this.state;

    const { user_group } = user;
    const isBendahara = (user_group && user_group.name === 'Bendahara') ?
      true : false;

    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    const tableColSpan = (isPusat) ? 6 : 6;
    const summaryRowColSpan = 2;

    const Modal = ({ handleClose, show, children }) => {
      const showHideClassName = show ? 'modal display-block' : 'modal display-none';

      return (
        <div className={showHideClassName}>
          <section className='modal-main'>
            {children}
            <button
              onClick={handleClose}
            >
              Close
            </button>
          </section>
        </div>
      );
    };

    return (
      <div className="manage-budget__table-wrapper">
        <div>
          <SelectCoa
            noMargin
            // edit={canEdit}
            name="tema"
            value={this.state.selectedTema}
            target={tema}
            tema={target}
            // value={target}
            error={error.tema}
            coa={tema}
            onClick={this.onSearchTema}
            placeholder="Pilih tema"
            rightIcon="icon-search"
            onSearchCoa={onSearchTema}
          />
        </div>
        <br></br>
        <br></br>
        <table className="table">
          {this.renderHeader()}
          {this.renderRows(dataloop, budgetloop)}
        </table>
      </div>
    );
  }
}
BudgetRapbuRekap.propTypes = {
  handleGetBudgetRapbuRekapDetail: PropTypes.func,
  onSelectBudgetDetail: PropTypes.func,
  rapbuSummary: PropTypes.object.isRequired,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  handleGetTemaOptions : PropTypes.func,
  onSearchTema: PropTypes.object,
};
BudgetRapbuRekap.defaultProps = {
  handleGetBudgetRapbuRekapDetail: noop,
  handleGetTemaOptions : noop,
  onSearchCoa: noop,
  onChange: noop,
  onSearchTema: noop,
  onSelectBudgetDetail: noop,
  user: null,
  recommendations: {},
  error: {},
};
