import { connect } from 'react-redux';
import TemaCoaMapping from '../../../views/Finance/Settings/TemaCoaMapping.finance.settings.view';
import { getTemaCoaMap, updateTemaCoaMap } from '../../../states/thunks/finance.thunk';
import { getCoaOptions } from '../../../states/thunks/common.thunk';
import { getTemaOptions } from '../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    tema: state.options.tema,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetTemaCoaMap: (payload, unit_id) => dispatch(getTemaCoaMap(payload, unit_id)),
    handleUpdateTemaCoaMap: payload => dispatch(updateTemaCoaMap(payload)),
    handleGetTemaOptions: payload => dispatch(getTemaOptions(payload)),
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemaCoaMapping);
