import { connect } from 'react-redux';
import ManageBudgetRapbuRekap from '../../../../views/Finance/Budgeting/Budget/ManageBudgetRapbuRekap.finance.view';
import {
  getBudgetDetail,handleGetBudgetRapbuRekapDetail, saveRecommendations, submitApproval, rejectRapbu,
} from '../../../../states/thunks/finance.thunk';

import {getTemaFilterOptions} from '../../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
    tema: state.options.tema,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetRapbuRekapDetail: payload => dispatch(handleGetBudgetRapbuRekapDetail(payload)),
    handleGetTemaOptions: payload => dispatch(getTemaFilterOptions(payload)),
    // handleGetBudgetDetail: payload => dispatch(getBudgetDetail(payload)),
    // handleSaveRecommendations: (payload, goBack) => dispatch(saveRecommendations(payload, goBack)),
    // handleSubmitApproval: (payload, goBack) => dispatch(submitApproval(payload, goBack)),
    // handleReject: (payload, goBack) => dispatch(rejectRapbu(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBudgetRapbuRekap);

