import React, { Component } from "react";
import PropTypes from 'prop-types';
import Swal from 'sweetalert2'
import { find } from 'lodash';
import withReactContent from 'sweetalert2-react-content'
import { Button,Modal, Input } from '../../../../../components/base';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';

const radioStyle = {
  display: "block",
  "margin-bottom": "5px",
  "border-radius": "3px"
};

const MySwal = withReactContent(Swal)

export default class DashboardPrint extends Component {

  constructor(props){
    super(props);
    this.onClickButton = this.onClickButton.bind(this);
    this.onClick = this.onClick.bind(this);
    this.clickHandlers = {};

  }
  onClick(type, code) {

    const { location, history } = this.props;
    const { data , head_unique_id  } = location.state;
    
    // console.log(head_unique_id)
    // if (type === 'DETAIL_RAPBU') {
    //   history.push('/dashboard/keuangan/penganggaran/detail-rapbs', {
    //     type,
    //     code: '',
    //     head_unique_id: head_unique_id,
    //   });
    // }
    if (type === 'REKAP_RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/rekap-rapbs-excel', {
        type,
        code: '',
        head_unique_id: head_unique_id,
      });
    }
  }

  getClickHandler(type, code) {
    // console.log(type)
    
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, type)) {
      this.clickHandlers[type] = () => this.onClick(type, code);
      // console.log(type)
    }
    return this.clickHandlers[type];

  }

  renderHeaderModal() {
    return (
      <div>
        <label style={{fontSize:18}}>
          <button onClick={this.getClickHandler('REKAP_RAPBU','')}>
            <h3>Excel RAPBS</h3>
          </button><br></br>
          <br></br> 
          
          </label>
      </div>
      
    )
  }

  onClickButton() {
    const filteredList = (() => {
      
    });

    MySwal.fire({
      size: 200,
      title: <h3>Export</h3>,
      width: '300px',
      height: '100px',
      footer: '',
      html: this.renderModalContent(filteredList)
    })
  }

  renderModalContent() {
    
    return(   
      <div>
        
      <table class="manage-budget__form-table table">
        
         
        {this.renderHeaderModal()}
     
      </table>
      </div>
    )
  }
  
  render() { 
    return (
      <main>
          <td>
              <Button className="print" onClick={() => this.onClickButton()} >Export Excel</Button>
            </td>
          
      </main>

    );
  }
}
const container = document.createElement("div");
document.body.appendChild(container);

DashboardPrint.propTypes = {
  
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}
