import React from 'react';
import PropTypes from 'prop-types';

const MonthlyActivityPlanFormTableHeaders = (props) => {
  const {
    showEdit,
    workflow,
  } = props;
  const lastWorkflow = workflow[workflow.length - 1] || {};
  return (
    <thead>
      <tr>
        <th style={{ width: '1%' }} rowSpan={2}>
          No.
        </th>
        <th
          className="manage-budget__row table"
          style={{ width: '5%' }}
          rowSpan={2}
        >
          Kode Kegiatan
        </th>
        <th
          className="manage-budget__row table"
          rowSpan={2}
        >
          Program Kegiatan
        </th>
        <th
          className="manage-budget__row table"
          rowSpan={2}
        >
          Jenis Kegiatan
        </th>
        <th
          className="manage-budget__row table"
          style={{ width: '15s%' }}
          rowSpan={2}
        >
          PIC Kegiatan
        </th>
        <th className="manage-budget__row table" colSpan={2}>
          Tgl
        </th>
        <th colSpan={3}>
          RP
        </th>
        <th className="manage-budget__row table" rowSpan={2}>
          Pembayaran Dana
        </th>
        <th className="manage-budget__row table" rowSpan={2}>
          Rincian Anggaran
        </th>
        {showEdit && workflow.length === 0 && <th rowSpan={2} width="4%" />}
        {showEdit && lastWorkflow.action === 'reject' && (
        <th rowSpan={2} width="4%" />
        )}
      </tr>
      <tr>
        <th className="manage-budget__row table" width="5%">
          Butuh Dana
        </th>
        <th className="manage-budget__row table" width="5%">Pelaksanaan</th>
        {/* <th className="manage-budget__row table" width="10%">
            RAPBS
          </th>
          <th className="manage-budget__row table">Pengajuan</th>
          <th className="manage-budget__row table">Selisih</th> */}

        <th className="manage-budget__row table" width="5%">
          Saldo Awal
        </th>
        <th className="manage-budget__row table" width="5%">Pengajuan</th>
        <th className="manage-budget__row table" width="5%">Saldo Akhir</th>
      </tr>
    </thead>
  // </table>
  //  </div>
  );
};

MonthlyActivityPlanFormTableHeaders.propTypes = {
  showEdit: PropTypes.bool,
  workflow: PropTypes.array,
};
MonthlyActivityPlanFormTableHeaders.defaultProps = {
  showEdit: false,
  workflow: [],
};

export default MonthlyActivityPlanFormTableHeaders;
