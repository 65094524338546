import fixturesConfig from './fixtures.config';

const defaults = {
  ENV: 'prod',
  MOCKAPI: false,
  URL: 'https://dev.ricci.api.kliksekolah.com/api',
  //URL: 'https://api.ricci.kliksekolah.com/api',
  URL_2: 'https://staff.kliksekolah.com/api',
  URL_3: 'https://api.param.kliksekolah.com/',
  URL_4: 'https://akademik.api.kliksekolah.com/api',
  API_AKADEMIS: 'https://dev.api.academics.kliksekolah.com',
  API_LOCAL: 'https://dev.api.academics.kliksekolah.com',
  API_BRIDGE: 'https://api.bridge.kliksekolah.com',
  fixtures: fixturesConfig,
};

const setEnv = (envKey, value) => {
  defaults[envKey] = value;
  return defaults;
};

export default {
  setEnv,
  ENV: defaults.ENV,
  MOCKAPI: defaults.MOCKAPI,
  URL: defaults.URL,
  URL_2: defaults.URL_2,
  URL_3: defaults.URL_3,
  API_BRIDGE: defaults.API_BRIDGE,
  API_AKADEMIS: defaults.API_AKADEMIS,
  API_LOCAL: defaults.API_LOCAL,
  fixtures: defaults.fixtures,
  PASSWORD: defaults.password,
};

