import env from './env.config';

export const {
  URL: SERVER_URL,
  URL_2: SERVER_URL_2,
  URL_3: SERVER_URL_3,
  URL_4: SERVER_URL_4,
  URL_5: SERVER_URL_5,
  API_LOCAL,
  API_BRIDGE,
  API_AKADEMIS,
} = env;
export const endpoints = {
  TEST: '/test',
  AUTH: '/auth',
  LOGIN: '/auth/login',
  PROFILE: '/auth/profile',
  BUDGET: '/budget',
  DIVISI: '/divisi',
  ACADEMICS: '',
  PARAM_AKADEMI: '/options/param',
  ACADEMICS_PARAM: '/options',
  ACADEMICS_PARAM2: '/options/',
  AKADEMIS: '',
  GETACADEMICS: '',
  BUDGET_REQUEST: '/budget/request',
  NON_BUDGET: '/non-budget',
  REALIZATION: '/budget/realization',
  PARAM: '/param',
  PARAM_STAFF: '/param',
  // PARAM_AKADEMI: '/param',
  REPORT_RAPBS: '/report',
  OPTIONS: '/options',
  FINANCE_OUTCOME: '/finance/outcome',
  FINANCE_RAPBU: '/finance/rapbu',
  FINANCE_JOURNAL: '/journal',
  FINANCE_CASH_JOURNAL: '/finance/cash-journal',
  FINANCE_CASH_JOURNAL_BY_ID: '/finance/cash-journal',
  FINANCE_STARTING_BALANCE: '/journal/starting-balance',
  FINANCE_SETTINGS: '/finance/settings',
  NOTIFICATION_STREAM: '/notifications/stream',
  NOTIFICATION: '/notifications',
  STAFF: '/staff',
  STUDENTS: '/students',
  SK: '/sk',
  REPORT: '/report',
  TAX: '',
  FILE: '/file',
  CLASSES: '/param',
};

export const mockResponses = env.fixtures || {};
