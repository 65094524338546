import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../../../components/base';

export default class BudgetDivisiDetail extends Component {

    constructor(props){
      super(props);
      this._onFormChange = this._onFormChange.bind()
    }

    _onFormChange(event) {
      const { onChange} = this.props;
      onchange (event);
    }

    render(){
      const{
        onChange, list,
      } = this.props;

      return(
        <table className="manage-detail-divisi__form-table table">
          <thead>
            <tr>
              <th>Biaya</th>
              <th>Total</th>
              <th>Plafon</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            {
              map(list, (row) => {
                return (
                  <tr>
                    <td>{row.plafond_name}</td>
                    <td>{row.total}</td>
                    <td>{row.nominal}</td>
                    <td>{row.persen}</td>
                  </tr>
                );
              })
            }

          </tbody>
        </table>
      );
    }

}


BudgetDivisiDetail.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteList: PropTypes.func,
    onChange: PropTypes.func,

  };
  BudgetDivisiDetail.defaultProps = {
    onAddList: noop,
    onDeleteList: noop,
    onChange: noop,
  };
