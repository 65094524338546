import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input ,Select} from '../../../../../components/base';

export default class BudgetDivisiDetailList extends Component {
    constructor(props){
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
    }

    _onFormChange(event) {
      const { onChange} = this.props;
      onChange(event);
    }

    render(){
      const{
        onChange, list, form,
      } = this.props;
      const {value} = form;
      const {divisi = null }  = value;

      return(
       <Select 
       data={list}
       data-field-name='divisi'
       name='divisi'
       onChange={this._onFormChange}
       value={divisi}
       />
      );
    }
}


BudgetDivisiDetailList.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    divisi :PropTypes.func,
    onChange: PropTypes.func,
    form:PropTypes.object,
    
  };
  BudgetDivisiDetailList.defaultProps = {
    onAddList: noop,
    onChange: noop,
    divisi :[],
    form:{},
  };