import language from '../languages';
import homeIcon from '../assets/img/menu/beranda.png';
import studentIcon from '../assets/img/menu/kesiswaan.png';
import curiculumIcon from '../assets/img/menu/kurikulum.png';
import personalIcon from '../assets/img/menu/personalia.png';
import financeIcon from '../assets/img/menu/penganggaran.png';
import administrationIcon from '../assets/img/menu/administrasi.png';
import inventoryIcon from '../assets/img/menu/inventaris.png';
import libraryIcon from '../assets/img/menu/perpustakaan.png';
import settingIcon from '../assets/img/menu/pengaturan.png';

export const SIDEBAR__DASHBOARD_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: '/dashboard',
  },
  {
    title: language.translate.SIDEBAR__STUDENT,
    iconImage: studentIcon,
    path: '/dashboard/kesiswaan',
    permission: 'view_daftar_siswa_kelas',
    menu: [
      {
        title: language.translate.SIDEBAR__STUDENT__REGISTRATION,
        path: '/dashboard/kesiswaan/list-pendaftaran-siswa',
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_form_TU',
      },
      {
        title: 'Data Siswa',
        icon: 'icon-minus',
        path: '/dashboard/kesiswaan/data-siswa',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_daftar_siswa_kelas',

        menu: [
          //   {

          //   title: 'Profil Siswa',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kesiswaan/laporan/nis',
          // },
          {
            title: 'Daftar Siswa Kelas',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/laporan/daftar-siswa-kelas',
          },
          {
            title: 'Catat Siswa Keluar',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/data-siswa/catat-siswa-keluar',
            permission: 'view_form_TU',
          },
          {
            title: 'Pembatalan Siswa Keluar',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/data-siswa/pembatalan-siswa-keluar',
            permission: 'view_form_TU',
          },
        ],
      },
      {
        title: 'Absensi Siswa',
        icon: 'icon-minus',
        path: '/dashboard/kesiswaan/absensi-siswa',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_absensi_akademis',

        menu: [
          {
            title: 'Absensi Harian',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/absensi-siswa/absensi-harian',
          },
          {
            title: 'Absensi Rekap',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/absensi-siswa/absensi-rekap',
          },
          {
            title: 'Isi per Bidang Study',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/absensi-siswa/isi-perbidang-study',
          },
          {
            title: 'Laporan Absen Bidang Study',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/absensi-siswa/laporan-absen-bidang-study',
          },
        ],
      },
      {
        title: 'Displiner',
        icon: 'icon-minus',
        path: '/dashboard/kesiswaan/displiner',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_displiner',
        menu: [
          {
            title: 'Table Pelanggaran',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/displiner/table-pelanggaran',
          },
          {
            title: 'Table Prestasi',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/displiner/table-prestasi',
          },
          // {
          //   title:'Analisa Displiner',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kesiswaan/displiner/analisa-displiner',
          // },
          {
            title: 'Catat Pelanggaran',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/displiner/catat-pelanggaran',
          },
          {
            title: 'Catat Prestasi',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/displiner/catat-prestasi',
          },
          {
            title: 'Catat Harian Siswa',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/displiner/catat-harian-siswa',
          },
        ],
      },
      {
        title: 'Ekstrakulikuler',
        icon: 'icon-minus',
        path: '/dashboard/kesiswaan/ekstrakulikuler',
        className: 'menu__left-icon--small',
        padding: 20,
        menu: [
          {
            title: 'Jenis Ekstrakulikuler',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler',
            permission: 'view_form_TU',
          },
          {
            title: 'Daftar Peserta Ekskul',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/ekstrakulikuler/daftar-peserta-ekskul',
            permission: 'view_ekskul',
          },
          {
            title: 'Pembagian Kelas Ekskul',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul',
            permission: 'view_form_TU',
          },
          {
            title: 'Absensi Ekskul',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/ekstrakulikuler/absensi-ekskul',
            permission: 'view_ekskul_guru',
          },
          {
            title: 'Pengisian Nilai Ekskul',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul',
            permission: 'view_ekskul_guru',
          },
        ],
      },
      {
        title: 'Bimbingan Konseling',
        path: '/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa',
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_bimbingan_konseling',
      },
      {
        title: language.translate.SIDEBAR__STUDENT__STUDENT_REPORT,
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        path: '/dashboard/kesiswaan/laporan',
        padding: 20,
        menu: [
          {
            title: language.translate.SIDEBAR__STUDENT__STUDENT_REPORT__KLAPPER,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/laporan/klapper',
            permission: 'view_form_TU',
          },
          {
            title: 'Laporan 8355',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/laporan/laporan-8355',
            permission: 'view_form_TU',
          },
          {
            title: 'Laporan Displiner',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/laporan/laporan-displiner',
            permission: 'view_form_TU',
          },
          {
            title: 'Laporan Konseling',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/laporan/laporan-konseling',
            permission: 'view_bimbingan_konseling',
          },
          // {
          //   title: 'Laporan Absensi Kelas',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kesiswaan/laporan/laporan-absensi-kelas',

          // },{
          //   title: 'Laporan Persensi',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kesiswaan/laporan/laporan-persensi',

          // }
        ],
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__ADMINISTRATION,
    iconImage: administrationIcon,
    path: '/dashboard/administrasi',
    permission: 'view_form_TU',
    menu: [
      {
        title: 'Data Kelas',
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        menu: [
          //     {
          //     title: 'Identitas Unit Sekolah',
          //     icon: 'icon-angle-right',
          //     className: 'menu__left-icon--small',
          //     padding: 40,
          //     path: '/dashboard/administrasi/data-kelas/identitas-unit-sekolah',

          // },
          {
            title: 'Tahun Ajaran Baru',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/tahun-ajaran',
          },
          {
            title: 'Jurusan Kelas',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/jurusan-kelas',
          },
          {
            title: 'Kelas',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/kelas',
          },
          {
            title: 'Pembagian Kelas',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/pembagian-kelas',
          },
          {
            title: 'Kenaikan Kelas Siswa',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/kenaikan-kelas-siswa',
          },
          // {
          //   title: 'Kelas Khusus',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/administrasi/data-kelas/kelas-khusus',

          // },

          // {
          //   title: 'Pembagian Kelas Khusus',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/administrasi/data-kelas/pembagian-kelas-khusus',

          // },
          {
            title: 'Siswa Pindah Kelas',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/siswa-pindah-kelas',
          },
          {
            title: 'Pindah Unit',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/pindah-unit',
          },
          {
            title: 'Daftar Pindah Unit',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/daftar-pindah-unit',
          },
          {
            title: 'Permohonan Pindah Unit',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/administrasi/data-kelas/permohonan-pindah-unit',
          },
        ],
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__CURRICULUM,
    iconImage: curiculumIcon,
    path: '/dashboard/kurikulum',
    permission: 'view_daftar_siswa_kelas',
    menu: [
      {
        title: 'Ulangan',
        icon: 'icon-minus',
        path: '/dashboard/kurikulum/ulangan',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_ulangan_guru',
        menu: [
          {
            title: 'Pengisian Indikator Sikap',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/ulangan/pengisian-indikator-sikap',
          },
          {
            title: 'Pengisian Nilai Kognitif, Psik & Afek',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif',
          },
          {
            title: 'Pengisian Kompetensi Dasar',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/ulangan/pengisian-kompetensi-dasar',
          },
          {
            title: 'Pengisian Observasi & Daftar Nilai',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/ulangan/pengisian-observasi-harian',

            // },{
            //   title: 'Pengisian Observasi Mingguan',
            //   icon: 'icon-angle-right',
            //   className: 'menu__left-icon--small',
            //   padding: 40,
            //   path: '/dashboard/kurikulum/ulangan/pengisian-observasi-mingguan',

            // },{
            //   title: 'Daftar Nilai / Report',
            //   icon: 'icon-angle-right',
            //   className: 'menu__left-icon--small',
            //   padding: 40,
            //   path: '/dashboard/kurikulum/ulangan/daftar-nilai/report',
          },
          {
            title: 'Daftar Nilai',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/ulangan/daftar-nilai',
          },
          {
            title: 'Catatan Anekdot',
            icon: 'icon-minus',
            path: '/dashboard/kurikulum/ulangan/catatan-anekdot',
            className: 'menu__left-icon--small',
            padding: 40,
            permission: 'view_ekskul_guru',

            menu: [
              {
                title: 'Tema',
                icon: 'icon-angle-right',
                className: 'menu__left-icon--small',
                padding: 60,
                path: '/dashboard/kurikulum/ulangan/catatan-anekdot/tema',
                permission: 'view_ekskul_guru',
              },
              {
                title: 'Sub Tema',
                icon: 'icon-angle-right',
                className: 'menu__left-icon--small',
                padding: 60,
                path: '/dashboard/kurikulum/ulangan/catatan-anekdot/sub-tema',
                permission: 'view_ekskul_guru',
              },
              {
                title: 'Pengisian Catatan Anekdot',
                icon: 'icon-angle-right',
                className: 'menu__left-icon--small',
                padding: 60,
                path: '/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot',
                permission: 'view_ekskul_guru',
              },
            ],
          },
        ],
      },
      {
        title: 'Pengumuman',
        icon: 'icon-minus',
        path: '/dashboard/kurikulum/Pengumuman',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_form_TU',
      },
      {
        title: 'Jadwal Kelas & Karyawan',
        icon: 'icon-minus',
        path: '/dashboard/kurikulum/jadwal/jadwal-per-kelas',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_jadwal_kelas',
        // menu: [{
        //   title: 'Jadwal Per Kelas',
        //   icon: 'icon-angle-right',
        //   className: 'menu__left-icon--small',
        //   padding: 40,
        //   path: '/dashboard/kurikulum/jadwal/jadwal-per-kelas',
        // },{
        //   title: 'Jadwal Karyawan',
        //   icon: 'icon-angle-right',
        //   className: 'menu__left-icon--small',
        //   padding: 40,
        //   path: '/dashboard/kurikulum/jadwal/jadwal-karyawan',
        // }]
      },
      {
        title: 'Mata Pelajaran',
        icon: 'icon-minus',
        path: '/dashboard/kurikulum/mata-pelajaran',
        className: 'menu__left-icon--small',
        padding: 20,
        menu: [
          {
            title: 'Kode Mata Pelajaran',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran',
            permission: 'view_form_TU',
          },
          {
            title: 'Jadwal Matapelajaran',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran',
          },
          {
            title: 'Daftar Guru',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/mata-pelajaran/daftar-guru',
            permission: 'view_form_TU',
          },
        ],
      },
      // {
      //   title: "Bobot Penilaian",
      //   icon: "icon-minus",
      //   path: "/dashboard/kurikulum/bobot-penilaian",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   menu: [
      //     {
      //       title: "Pengisian Bobot Penilaian",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kurikulum/bobot-penilaian/pengisian-bobot-penilaian",
      //     },
      //   ],
      // },
      // {
      //   title: 'Rencana Penilaian',
      //   icon: 'icon-minus',
      //   path: '/dashboard/kurikulum/rencana-penilaian',
      //   className: 'menu__left-icon--small',
      //   padding: 20,
      //   menu: [{
      //     title: 'Upload RPP',
      //     icon: 'icon-angle-right',
      //     className: 'menu__left-icon--small',
      //     padding: 40,
      //     path: '/dashboard/kurikulum/rencana-penilaian/upload-rpp',

      //   },{
      //     title: 'Pengisian KD',
      //     icon: 'icon-angle-right',
      //     className: 'menu__left-icon--small',
      //     padding: 40,
      //     path: '/dashboard/kurikulum/rencana-penilaian/pengisian-kd',

      //   },{
      //     title: 'Laporan RPP RPK',
      //     icon: 'icon-angle-right',
      //     className: 'menu__left-icon--small',
      //     padding: 40,
      //     path: '/dashboard/kurikulum/rencana-penilaian/laporan-rpp-rpk',

      //   }],

      // },
      {
        title: 'Raport',
        icon: 'icon-minus',
        path: '/dashboard/kurikulum/raport',
        className: 'menu__left-icon--small',
        padding: 20,
        permission: 'view_raport',
        menu: [
          {
            title: 'Daftar Nilai Raport',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kurikulum/raport/daftar-nilai-raport',
          },
          // ,{
          //   title: 'Raport KBK',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kurikulum/raport/raport-kbk',

          // }
        ],
      },
      // {
      //   title: 'Analisa',
      //   icon: 'icon-minus',
      //   path: '/dashboard/kurikulum/analisa',
      //   className: 'menu__left-icon--small',
      //   padding: 20,
      //   menu: [{
      //     title: 'Estimasi Kenaikan Kelas',
      //     icon: 'icon-angle-right',
      //     className: 'menu__left-icon--small',
      //     padding: 40,
      //     path: '/dashboard/kurikulum/analisa/estimasi-kenaikan-kelas',

      //   },{
      //     title: 'Komposisi Siswa',
      //     icon: 'icon-angle-right',
      //     className: 'menu__left-icon--small',
      //     padding: 40,
      //     path: '/dashboard/kurikulum/analisa/komposisi-siswa',

      //   }],
      // },
      // {title: 'Soal',
      // icon: 'icon-minus',
      // path: '/dashboard/kurikulum/soal',
      // className: 'menu__left-icon--small',
      // padding: 20,
      // menu: [{
      //   title: 'List Soal',
      //   icon: 'icon-angle-right',
      //   className: 'menu__left-icon--small',
      //   padding: 40,
      //   path: '/dashboard/kurikulum/soal/list-soal',
      // }, {
      //   title: 'Pengaturan Soal',
      //   path: '/dashboard/kurikulum/soal/pengaturan-soal',
      //   icon: 'icon-angle-right',
      //   className: 'menu__left-icon--small',
      //   padding: 40,
      // }],

      // }
    ],
  },
  {
    title: 'SPP',
    className: 'menu__left-icon--small',
    path: '/dashboard/keuangan/spp',
    iconImage: financeIcon,
    menu: [
      {
        title: 'Jenis SPP',
        icon: 'icon-angle-right',
        className: 'menu__left-icon--small',
        padding: 40,
        path: '/dashboard/keuangan/spp/jenis-spp',
      },
      {
        title: 'Create Invoice',
        icon: 'icon-angle-right',
        className: 'menu__left-icon--small',
        padding: 40,
        path: '/dashboard/keuangan/spp/create-invoice',
      },
      {
        title: 'Pembayaran',
        icon: 'icon-angle-right',
        className: 'menu__left-icon--small',
        padding: 40,
        path: '/dashboard/keuangan/spp/pembayaran',
      },
      {
        title: 'Laporan SPP',
        icon: 'icon-angle-right',
        className: 'menu__left-icon--small',
        padding: 40,
        path: '/dashboard/keuangan/spp/laporan-spp',
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__PERSONAL,
    iconImage: personalIcon,
    menu: [
      {
        title: language.translate.SIDEBAR__PERSONAL__STAFF_LIST,
        path: '/dashboard/personalia/data-staff',
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
      },
      {
        title: language.translate.SIDEBAR__PERSONAL__PAYROLL_LIST,
        path: '/dashboard/personalia/data-payroll',
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
      },
      {
        title: 'Absensi Pegawai',
        path: '/dashboard/personalia/absensi-pegawai',
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        menu: [
          {
            title: 'Approval',
            path: '/dashboard/personalia/absensi-pegawai/approval',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Laporan Absensi Pegawai',
            path: '/dashboard/personalia/absensi-pegawai/laporan-absensi-pegawai',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Laporan Absensi Khusus Pegawai',
            path: '/dashboard/personalia/absensi-pegawai/laporan-absensi-khusus-pegawai',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Laporan Absensi Pegawai Detail',
            path: '/dashboard/personalia/absensi-pegawai/laporan-absensi-pegawai-detail',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Pengajuan Cuti Pegawai',
            path: '/dashboard/personalia/absensi-pegawai/pengajuan-cuti',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Pengajuan Ijin Pulang Awal',
            path: '/dashboard/personalia/absensi-pegawai/pengajuan-ijin-pulang-awal',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Pengajuan Kegiatan Pegawai',
            path: '/dashboard/personalia/absensi-pegawai/pengajuan-kegiatan-pegawai',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Pengajuan Lembur Pegawai',
            path: '/dashboard/personalia/absensi-pegawai/pengajuan-lembur-pegawai',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
          {
            title: 'Table Kegiatan Pegawai',
            path: '/dashboard/personalia/absensi-pegawai/table-kegiatan-pegawai',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
          },
        ],
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__FINANCE,
    iconImage: financeIcon,
    path: '/dashboard/keuangan',
    menu: [
      {
        title: language.translate.SIDEBAR__FINANCE__BUDGETING,
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        path: '/dashboard/keuangan/penganggaran',
        menu: [
          {
            title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/penganggaran/anggaran',
          },
          // {
          //   title: language.translate.SIDEBAR__FINANCE__BUDGETING__NON_BUDGET,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/penganggaran/non-anggaran',
          // }, {
          //   title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET_TRANSFER,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/penganggaran/pengalihan-anggaran',
          // },
          // {
          //   title: language.translate.SIDEBAR__FINANCE__BUDGETING__FUND_REQUEST,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/penganggaran/permohonan-dana',
          // },
          {
            title:
              language.translate
                .SIDEBAR__FINANCE__BUDGETING__MONTHLY_ACTIVITY_PLANT,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/penganggaran/rencana-kegiatan-bulanan',
          },
          {
            title: language.translate.SIDEBAR__FINANCE__BUDGETING__REALIZATION,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/penganggaran/realisasi',
          },
        ],
      },
      {
        title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING,
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        path: '/dashboard/keuangan/pembukuan',
        menu: [
          {
            title: 'Saldo Awal',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/pembukuan/saldo-awal',
          },
          {
            title:
              language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CASH_JOURNAL,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/pembukuan/jurnal-kas',
          },
          {
            title:
              language.translate.SIDEBAR__FINANCE__BOOKKEEPING__BANK_JOURNAL,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/pembukuan/jurnal-bank',
          },
          // {
          //   title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__PAYMENT_JOURNAL,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/pembukuan/jurnal-pembayaran',
          // },
          {
            title:
              language.translate
                .SIDEBAR__FINANCE__BOOKKEEPING__ADJUSTMENT_JOURNAL,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/pembukuan/jurnal-umum',
          },
          // {
          //   title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CLOSING_JOURNAL,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/pembukuan/jurnal-penutup',
          // },
          // {
          //   title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__NON_KAS_JOURNAL,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/pembukuan/jurnal-non-kas',
          // }
        ],
      },
      {
        title: language.translate.SIDEBAR__FINANCE__REPORT,
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        menu: [
          {
            title: language.translate.SIDEBAR__FINANCE__REPORT__CASH_JOURNAL,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/laporan/jurnal-kas',
          },
          {
            title: language.translate.SIDEBAR__FINANCE__REPORT__BANK_JOURNAL,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/laporan/jurnal-bank',
          },
          {
            title: language.translate.SIDEBAR__FINANCE__REPORT__BIG,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/laporan/buku-besar',
          },
          // {
          //   title: language.translate.SIDEBAR__FINANCE__REPORT__MONTH,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/laporan/transaksi-bulanan',
          // },
          //  {
          //   title: language.translate.SIDEBAR__FINANCE__REPORT__DAILY_MONTH,
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/keuangan/laporan/jurnal-harian-dan-bulanan',
          // },
          {
            title: language.translate.SIDEBAR__FINANCE__REPORT__PROFIT_AND_LOST,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/laporan/laba-rugi',
          },

          {
            title: language.translate.SIDEBAR__FINANCE__REPORT__BALANCE,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/laporan/neraca',
          },
        ],
      },
      {
        title: language.translate.SIDEBAR__FINANCE__SETTINGS,
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        menu: [
          {
            title: language.translate.SIDEBAR__FINANCE__SETTINGS__TEMA_COA_MAP,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/pengaturan/tema-coa-map',
          },
        ],
      },
    ],
  },
  //  {
  //   title: language.translate.SIDEBAR__INVENTORY,
  //   iconImage: inventoryIcon,
  //   menu: [{
  //     title: 'Menu',
  //     path: '/dashboard/menu',
  //   }],
  // },
  {
    title: language.translate.SIDEBAR__LIBRARY,
    iconImage: libraryIcon,
    path: '/dashboard/perpustakaan',
    menu: [
      {
        title: 'Data Perpustakaan',
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        path: '/dashboard/perpustakaan/data-perpustakaan',
        menu: [
          {
            title: 'Control Perpustakaan',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/control-perpustakaan',
          },
          {
            title: 'Buat Klasifikasi',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/buat-klasifikasi',
          },
          {
            title: 'Catat Buku atau Catat Majalah',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/catat-buku-atau-catat-majalah',
          },
          {
            title: 'Upload',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/upload',
          },
          {
            title: 'Buat Asal Buku atau Majalah',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/buat-asal-buku-atau-majalah',
          },
          {
            title: 'Top 10 Analysis',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/top-10-analysis',
          },
          {
            title: 'Entry Data Pengunjung',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/entry-data-pengunjung',
            menu: [
              {
                title: 'Data Individu',
                icon: 'icon-angle-right',
                className: 'menu__left-icon--small',
                padding: 60,
                path: '/dashboard/perpustakaan/data-perpustakaan/entry-data-pengunjung/individu',
              },
              {
                title: 'Data Perkelas',
                icon: 'icon-angle-right',
                className: 'menu__left-icon--small',
                padding: 60,
                path: '/dashboard/perpustakaan/data-perpustakaan/entry-data-pengunjung/perkelas',
              },
            ],
          },
          {
            title: 'Laporan Pemasukan Uang',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/laporan-pemasukan-uang',
          },
          {
            title: 'Laporan Pengunjung',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/laporan-pengunjung',
          },
          {
            title: 'Laporan Peminjaman',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/laporan-peminjaman',
          },
          {
            title: 'Peminjaman Buku',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/peminjaman-buku',
          },
          {
            title: 'Pengembalian Buku',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/pengembalian-buku',
          },
          {
            title: 'Search Buku',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/search-buku',
          },
          {
            title: 'Grafik Analisis',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/perpustakaan/data-perpustakaan/grafik-analisis',
          },
        ],
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__SETTING,
    iconImage: settingIcon,
    menu: [
      {
        title: 'Menu',
        path: '/dashboard/menu',
      },
    ],
  },
  {
    title: 'Report Logged-in',
    iconImage: settingIcon,
    path: '/dashboard/report-logged-in',
  },
];

export const SIDEBAR_ADMISSION_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: '/admission',
    menu: [
      {
        title: language.translate.SIDEBAR__FINANCE__REPORT__BALANCE,
        icon: 'icon-angle-right',
        className: 'menu__left-icon--small',
        padding: 40,
        path: '/dashboard/keuangan/laporan/neraca',
      },
      {},
    ],
  },
];
