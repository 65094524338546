import React from 'react';
import PropTypes from 'prop-types';

const RealizationTableHeaders = (props) => {
  const {
    showEdit,
    workflow,
  } = props;
  const lastWorkflow = workflow[workflow.length - 1] || {};
  return (
    <thead>
      <tr>
        <th style={{ width: '5%' }} rowSpan={2}>
          No.
        </th>
        <th
          className="manage-budget__row table"
          style={{ width: '12%' }}
          rowSpan={2}
        >
          Kode Kegiatan
        </th>
        <th
          className="manage-budget__row table"
          style={{ width: '18%' }}
          rowSpan={2}
        >
          Program Kegiatan
        </th>
        <th
          className="manage-budget__row table"
          style={{ width: '12%' }}
          rowSpan={2}
        >
          Jenis Kegiatan
        </th>
        <th
          className="manage-budget__row table"
          style={{ width: '15%' }}
          rowSpan={2}
        >
          PIC Kegiatan
        </th>
        <th className="manage-budget__row table" colSpan={2}>
          Tgl
        </th>
        <th style={{ width: '20%' }} colSpan={3}>
          RP
        </th>
        <th className="manage-budget__row table" rowSpan={2}>
          Pembayaran Dana
        </th>
        <th className="manage-budget__row table" rowSpan={2}>
          Rincian Anggaran
        </th>
        {showEdit && workflow.length === 0 && <th rowSpan={2} width="4%" />}
        {showEdit && lastWorkflow.action === 'reject' && (
        <th rowSpan={2} width="4%" />
        )}
      </tr>
      <tr>
        <th className="manage-budget__row table" width="20%">
          Butuh Dana
        </th>
        <th className="manage-budget__row table">Pelaksanaan</th>
        <th className="manage-budget__row table" width="10%">
          Saldo Awal
        </th>
        <th className="manage-budget__row table">Pengajuan</th>
        <th className="manage-budget__row table">Realisasi</th>
        <th className="manage-budget__row table">Saldo Akhir</th>
      </tr>
    </thead>
  // </table>
  //  </div>
  );
};

RealizationTableHeaders.propTypes = {
  showEdit: PropTypes.bool,
  workflow: PropTypes.array,
};

RealizationTableHeaders.defaultProps = {
  showEdit: true,
  workflow: [],
};

export default RealizationTableHeaders;
