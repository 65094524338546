import React, { Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PropTypes from "prop-types";
import { map, noop, isEmpty, find, filter } from "lodash";
import ReactDOM from "react-dom";
import ReactExport from "react-export-excel";
import { Button, Input, FileInput } from "../../../../../components/base";
import BudgetPlafon from "./BudgetPlafon.component";
import BudgetField from "./BudgetField.component";
import language from "../../../../../languages";
import {
  calculateAllSemester,
  commaFormatted,
  dateFormat,
} from "../../../../../utils/transformer.util";
import SelectCoa from "../../../../../components/SelectCoa/SelectCoa.component";

const MySwal = withReactContent(Swal);

const userGroupPusat = ["Korektor Pusat", "Manager Keuangan", "Bendahara"];

export default class BudgetRapby extends Component {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
    this.onCheckAll = this.onCheckAll.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderAllocations = this.renderAllocations.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this.renderModalContent = this.renderModalContent.bind(this);
    this.renderSummary = this.renderSummary.bind(this);

    this.onABP = this.onABP.bind(this);
    this.onManagerial = this.onManagerial.bind(this);
    this.onKurikulum = this.onKurikulum.bind(this);
    this.onKesiswaan = this.onKesiswaan.bind(this);

    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onFormChange = this.onFormChange.bind(this);

    this.checkboxRefs = {
      ypl: {},
      committee: {},
      intern: {},
      bos: {},
    };

    this.state = {
      renderKetJumlahPengeluaran: false,
    };
  }

  onKurikulum() {
    const { onKurikulum } = this.props;
    onKurikulum("kurikulum");
  }

  onKesiswaan() {
    const { onKesiswaan } = this.props;
    onKesiswaan("kesiswaan");
  }

  onABP() {
    const { onABP } = this.props;
    onABP("ABP");
  }

  onManagerial() {
    const { onManagerial } = this.props;
    onManagerial("managerial");
  }

  setRef(el) {
    const { input } = el;
    const { dataset } = input;
    const { pos, referenceid } = dataset;
    this.checkboxRefs[pos][referenceid] = input;
  }

  onCheckAll(event) {
    const { onCheckAll } = this.props;
    const { target } = event;
    const { dataset } = target;
    const { pos } = dataset;
    const checkboxPos = this.checkboxRefs[pos];

    for (const referenceId in checkboxPos) {
      checkboxPos[referenceId].checked = target.checked;
    }

    onCheckAll(checkboxPos);
  }

  onClickButton(data, list) {
    const filteredList = filter(
      list,
      (o) =>
        // console.log(o)
        o.code === data.code && data.code_tema === o.code_tema
    );
    // console.log(data)
    // console.log(filteredList)

    MySwal.fire({
      size: 20000,
      title: <h3>Keterangan Anggaran</h3>,
      width: "1000px",
      height: "1000px",
      footer: "",
      html: this.renderModalContent(filteredList),
    });
  }

  Total(filteredList) {
    let totalnominal = 0;
    // console.log(filteredList)

    if (filteredList[0].data.length > 0) {
      for (let i = 0; i < filteredList[0].data.length; i++) {
        totalnominal += Number(filteredList[0].data[i].total);
      }
    }
    return totalnominal;
  }

  TotalHeader(filteredList) {
    let totalnominal = 0;
    // console.log(filteredList.data.length)

    if (filteredList.data.length > 0) {
      for (let i = 0; i < filteredList.data.length; i++) {
        totalnominal += Number(filteredList.data[i].total);
      }
    }
    return totalnominal;
  }

  renderModalContent(filteredList) {
    // let totalnominal;
    // console.log(filteredList)
    return (
      <div>
        <div>
          <h1>{filteredList[0].name_tema}</h1>
        </div>
        <table className="manage-budget__form-table table">
          {this.renderHeaderModal()}
          {map(filteredList[0].data, (item) => (
            <tr>
              <td style={{ fontSize: 20 }}>{item.desc}</td>
              <td style={{ fontSize: 20 }}>{item.execution_time}</td>
              <td style={{ fontSize: 20 }} className="nominal">
                {commaFormatted(item.quantity)}
              </td>
              <td style={{ fontSize: 20 }} className="nominal">
                {commaFormatted(item.price)}
              </td>
              <td style={{ fontSize: 20 }} className="nominal">
                {commaFormatted(item.term)}
              </td>
              <td style={{ fontSize: 20 }} className="nominal">
                {commaFormatted(item.total)}
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={5} style={{ fontSize: 20 }} className="nominal">
              Total
            </td>
            <td className="nominal">
              Rp. {commaFormatted(this.Total(filteredList))}
            </td>
          </tr>
        </table>
      </div>
    );
  }

  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)
    // console.log(this.props)

    if (name == "file") {
      // console.log(value)
    }
    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });

    // console.log(dataset.arrayPosition)
  }

  async onFileUpload(event) {
    const { handleFileUpload, onChange, idx } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const file = files[0];

    if (file) {
      const filename = file.name;
      const res = await handleFileUpload(file);

      onChange({
        target: {
          name: "file",
          value: {
            path: res.path,
            filename,
          },
          dataset: {
            ...dataset,
            inputArray: true,
            arrayPosition: idx,
          },
        },
      });
    }
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }

  renderAllocations(allocations) {
    let rutinValue = 0;
    let kegiatanValue = 0;
    let danPemValue = 0;
    let biPengValue = 0;
    // console.log(allocations)
    for (let i = 0; i < allocations.length; i++) {
      const filteredAllocations = allocations[i].allocations;
      Object.keys(filteredAllocations).forEach((key) => {
        const allocation = filteredAllocations[key];
        if (key === "1") {
          rutinValue += parseInt(allocation.nominal);
        } else if (key === "2") {
          kegiatanValue += parseInt(allocation.nominal);
        } else if (key === "3") {
          danPemValue += parseInt(allocation.nominal);
        } else if (key === "4") {
          biPengValue += parseInt(allocation.nominal);
        }
      });
      // console.log(rutinValue)
      // console.log(rutinValue)
      // console.log(kegiatanValue)
      // console.log(danPemValue)
      // console.log(biPengValue)
    }
    const rows = [];

    rows.push([
      <td className="nominal">Rp.{commaFormatted(rutinValue)}</td>,
      <td className="nominal">Rp.{commaFormatted(kegiatanValue)}</td>,
      <td className="nominal">Rp.{commaFormatted(danPemValue)}</td>,
      <td className="nominal">Rp.{commaFormatted(biPengValue)}</td>,
    ]);
    return rows;
  }

  renderRows(list) {
    const {
      recommendations,
      user,
      workflow,
      rapbuSummary,
      onChangeRecommendation,
      onSelectBudgetDetail,
      selectedDivisi,
      handleFileUpload,
      handleFileDownload,
    } = this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;
    const isBendahara = user_group && user_group.name === "Bendahara";
    const canEdit =
      user_group.name === "Korektor Perwakilan" ||
      user_group.name === "Manager Keuangan";
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    // console.log(list)

    let rows = [];
    map(list, (data, idx) => {
      const draft = data.budget_detail_draft || {};

      // console.log(data)

      let show = 0;
      let semesterTwoData = filter(data.data, (o, index) => {
        // console.log(data.data)
        // console.log(divisi_id)
        if (o.divisi_id === selectedDivisi) {
          // o.realIndex = index;
          show = 1;
          return true;
        } else {
          show = 0;
          return false;
        }
      });

      // console.log(show)

      // console.log(show);

      if (show) {
        rows.push([
          <tr key={`budget_rapbu_rows_${idx}`}>
            <td>
              {data.code}
              <Button
                variant="primary"
                onClick={() => this.onClickButton(data, list)}
              >
                cek data
              </Button>
            </td>
            <td>{data.title}</td>
            {(data.code_tema == "0" && ( //cek jika data = 0
              <h3>
                <center>-</center>
              </h3>
            )) || ( // kalau tidak = 0
              <td>
                {data.code_tema} - {data.name_tema}
              </td>
            )}
            <td>{data.data[0].target}</td>
            <td>{data.data[0].execution_time}</td>

            {this.renderAllocations(data.data)}
            {/* {
            this.renderKetJumlahPengeluaran(data.data)
            } */}
            <td>
              <div className="icon2">
                <FileInput
                  style={{ width: "300px" }}
                  data-input-type="file"
                  noMargin
                  type="file"
                  name="file"
                  edit={canEdit}
                  onChange={this.onFileUpload}
                  fileName={data.data[0].file ? data.data[0].file.name : ""}
                  displayName={
                    data.data[0].file
                      ? data.data[0].file.display_name
                      : "default"
                  }
                  onRemoveFile={this.removeFile}
                  onDownloadFile={handleFileDownload}
                />
              </div>
            </td>

            {hasCheckbox && (
              <td>
                <Input
                  type="checkbox"
                  name="select_budget_detail"
                  data-referenceid={data.id}
                  data-pos="bos"
                  value={data.id}
                  ref={this.setRef}
                />
              </td>
            )}

            <td className="nominal">
              Rp.{commaFormatted(this.TotalHeader(data))}
            </td>
          </tr>,
        ]);
      }
    });
    return rows;
  }
  renderHeaderModal() {
    return (
      <thead>
        <tr>
          <th style={{ fontSize: 20 }} rowSpan={2}>
            Keterangan
          </th>
          <th style={{ fontSize: 20 }} rowSpan={4}>
            Waktu Pelaksanaan
          </th>
          <th style={{ fontSize: 20 }} rowSpan={2}>
            Kuantitas
          </th>
          <th style={{ fontSize: 20 }} rowSpan={2}>
            Nominal
          </th>
          <th style={{ fontSize: 20 }} rowSpan={2}>
            Period
          </th>
          <th style={{ fontSize: 20 }} rowSpan={2}>
            Total
          </th>
        </tr>
      </thead>
    );
  }

  renderSummary(type, semester) {
    const { rapbuSummary, selectedDivisi, user } = this.props;
    const { semesterOne, semesterTwo } = rapbuSummary;
    const { user_group } = user;
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const semesterDataList = semester === 1 ? semesterOne : semesterTwo;
    const title = type === "pengeluaran" ? "Pengeluaran" : "Pendapatan";

    const totalSummary = {
      semesterOne: {
        pengeluaran: {},
        pemasukan: {},
      },
      semesterTwo: {
        pengeluaran: {},
        pemasukan: {},
      },
    };

    const plafondTotal = {};
    let total = 0;

    if (semesterDataList.length === 0 || semesterDataList[type].length === 0) {
      return;
    }
    Object.values(semesterDataList[type]).forEach((semesterData) => {
      const { data } = semesterData;
      data.forEach((dataItem) => {
        if (dataItem.divisi_id === selectedDivisi) {
          const { allocations } = dataItem;
          const plafondIds = Object.keys(allocations);
          plafondIds.forEach((key) => {
            const allocation = allocations[key];
            if (!plafondTotal[key]) {
              Object.assign(plafondTotal, { [key]: 0 });
            }
            plafondTotal[key] += parseInt(allocation.nominal);
          });
        }
      });
    });

    if (plafondTotal) {
      Object.values(plafondTotal).forEach((sum) => {
        total += sum;
      });
    }
    // console.log(plafondTotal[1]);
    return (
      <tr className="total">
        <td className="total" colSpan={5}>
          Jumlah {title}
        </td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[1])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[2])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[3])}</td>
        <td className="nominal">Rp.{commaFormatted(plafondTotal[4])}</td>
        <td>
          <center> - </center>
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(total)}
        </td>
      </tr>
    );
  }

  renderKetJumlahPendapatan(allocations) {
    const { rapbuSummary, workflow, user, total_pengeluaran, selectedDivisi } =
      this.props;

    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;

    const isBendahara = !!(user_group && user_group.name === "Bendahara");
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    // const summaryRowColSpan = 9;
    const summaryRowColSpan = 5;

    const rows = [];
    const cols = [];

    let totalRutin = 0;
    let totalKegiatan = 0;
    let totalBiaya = 0;
    let totalDana = 0;
    let totalPendapatan = 0;
    const totalPenjumlahan = 0;

    // console.log(allocations)

    map(allocations, (data, idx) => {
      // console.log(allocations)

      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          for (let s = 0; s < data.data[i].allocations.length; s++) {
            if (data.data[i].allocations[s].plafond_id == "1") {
              totalRutin += parseInt(data.data[i].allocations[s].nominal);
              // localStorage.setItem('TotalRutin', totalRutin);
            } else if (data.data[i].allocations[s].plafond_id == "2") {
              totalKegiatan += parseInt(data.data[i].allocations[s].nominal);
            } else if (data.data[i].allocations[s].plafond_id == "3") {
              totalDana += parseInt(data.data[i].allocations[s].nominal);
            } else if (data.data[i].allocations[s].plafond_id == "4") {
              totalBiaya += parseInt(data.data[i].allocations[s].nominal);
            }
          }
        }
      }
    });

    totalPendapatan = totalRutin + totalKegiatan + totalBiaya + totalDana;
    // if(totalRutin == 0 || totalKegiatan == 0 || totalBiaya == 0 || totalDana == 0)
    // {

    //   cols.push([

    return (
      <tr className="total">
        <td className="total" colSpan={summaryRowColSpan}>
          Jumlah Pendapatan
        </td>
        <td className="nominal">Rp.{commaFormatted(totalRutin)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKegiatan)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDana)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBiaya)}</td>

        <td>
          <center> - </center>
          {/* <div className="icon2">
                Tidak ada
                  <FileInput
                  style={{width:"300px"}}
                    data-input-type="file"
                    type="file"
                    name="file" 
                    label="File"
                    onChange={this.onFormChange}
                  />
               </div> */}
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalPendapatan)}
        </td>
      </tr>
    );
    // ]);
    // }
    // return cols;
    // });
  }

  renderKetJumlahPengeluaran(allocations) {
    const { rapbuSummary, workflow, user, total_pengeluaran, selectedDivisi } =
      this.props;

    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;

    const isBendahara = !!(user_group && user_group.name === "Bendahara");
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    // const summaryRowColSpan = 9;
    const summaryRowColSpan = 5;
    const summaryRowRowSpan = 5;

    const rows = [];
    const cols = [];

    let totalRutin = 0;
    let totalKegiatan = 0;
    let totalBiaya = 0;
    let totalDana = 0;
    let totalPengeluaran = 0;
    const totalPenjumlahan = 0;

    // console.log(allocations)

    map(allocations, (data, idx) => {
      // console.log(allocations)

      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          for (let s = 0; s < data.data[i].allocations.length; s++) {
            if (data.data[i].allocations[s].plafond_id == "1") {
              totalRutin += parseInt(data.data[i].allocations[s].nominal);
              // localStorage.setItem('TotalRutin', totalRutin);
            } else if (data.data[i].allocations[s].plafond_id == "2") {
              totalKegiatan += parseInt(data.data[i].allocations[s].nominal);
            } else if (data.data[i].allocations[s].plafond_id == "3") {
              totalDana += parseInt(data.data[i].allocations[s].nominal);
            } else if (data.data[i].allocations[s].plafond_id == "4") {
              totalBiaya += parseInt(data.data[i].allocations[s].nominal);
            }
          }
        }
      }
    });

    totalPengeluaran = totalRutin + totalKegiatan + totalBiaya + totalDana;
    // if(totalRutin == 0 || totalKegiatan == 0 || totalBiaya == 0 || totalDana == 0)
    // {

    //   cols.push([

    return (
      <tr className="total">
        <td className="total" colSpan={summaryRowColSpan}>
          Jumlah Pengeluaran
        </td>
        <td className="nominal">Rp.{commaFormatted(totalRutin)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKegiatan)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDana)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBiaya)}</td>

        <td>
          <center> - </center>
          {/* <div className="icon2">
                Tidak ada
                  <FileInput
                  style={{width:"300px"}}
                    data-input-type="file"
                    type="file"
                    name="file"
                    label="File"
                    onChange={this.onFormChange}
                  />
               </div> */}
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalPengeluaran)}
        </td>
      </tr>
    );
    // ]);
    // }
    // return cols;
    // });
  }

  renderKetTotalPendapatan(allocations1, allocations2) {
    const {
      rapbuSummary,
      workflow,
      user,
      total_pendapatan,
      total_pengeluaran,
      selectedDivisi,
    } = this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;

    // console.log(total_pengeluaran)

    const isBendahara = !!(user_group && user_group.name === "Bendahara");
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    const summaryRowColSpan = 5;

    let totalRutin = 0;
    let totalKegiatan = 0;
    let totalBiaya = 0;
    let totalDana = 0;
    let totalPengeluaran = 0;

    map(allocations1, (data, idx) => {
      // console.log(allocations1)
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
    });
    // console.log(totalKegiatan)

    map(allocations2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
      // console.log(totalRutin)
    });

    totalPengeluaran = totalRutin + totalKegiatan + totalBiaya + totalDana;

    return (
      // <div>
      // <table>

      <tr className="summary total">
        <td className="total" colSpan={summaryRowColSpan}>
          Total Pendapatan
        </td>
        {isPusat && <td />}

        <td className="nominal">Rp.{commaFormatted(totalRutin)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKegiatan)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDana)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBiaya)}</td>

        <td>
          <center> - </center>
          {/* <div className="icon2">
                Tidak ada
                  <FileInput
                  style={{width:"300px"}}
                    data-input-type="file"
                    type="file"
                    name="file"
                    label="File"
                    onChange={this.onFormChange}
                  />
               </div> */}
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalPengeluaran)}
        </td>
      </tr>
    );
  }

  renderKetTotalPengeluaran(allocations1, allocations2) {
    const {
      rapbuSummary,
      workflow,
      user,
      total_pendapatan,
      total_pengeluaran,
      selectedDivisi,
    } = this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;

    // console.log(total_pengeluaran)

    const isBendahara = !!(user_group && user_group.name === "Bendahara");
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    const summaryRowColSpan = 5;

    let totalRutin = 0;
    let totalKegiatan = 0;
    let totalBiaya = 0;
    let totalDana = 0;
    let totalPengeluaran = 0;

    map(allocations1, (data, idx) => {
      // console.log(allocations1)
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
    });
    // console.log(totalKegiatan)

    map(allocations2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
      // console.log(totalRutin)
    });

    totalPengeluaran = totalRutin + totalKegiatan + totalBiaya + totalDana;

    return (
      // <div>
      // <table>

      <tr className="summary total">
        <td className="total" colSpan={summaryRowColSpan}>
          Total Pengeluaran
        </td>
        {isPusat && <td />}

        <td className="nominal">Rp.{commaFormatted(totalRutin)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKegiatan)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDana)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBiaya)}</td>

        <td>
          <center> - </center>
          {/* <div className="icon2">
                Tidak ada
                  <FileInput
                  style={{width:"300px"}}
                    data-input-type="file"
                    type="file"
                    name="file"
                    label="File"
                    onChange={this.onFormChange}
                  />
               </div> */}
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalPengeluaran)}
        </td>
      </tr>
    );
  }

  renderKetTotalEstimasiLabaRugi(
    allocationsPen1,
    allocationsPen2,
    allocationsPeng1,
    allocationsPeng2
  ) {
    const { rapbuSummary, workflow, user, total_estimasi, selectedDivisi } =
      this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;

    const isBendahara = !!(user_group && user_group.name === "Bendahara");
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    const summaryRowColSpan = 5;

    let totalRutin = 0;
    let totalKegiatan = 0;
    let totalBiaya = 0;
    let totalDana = 0;
    const totalEstimasi = 0;

    map(allocationsPen1, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    map(allocationsPen2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    let totalRutin1 = 0;
    let totalKegiatan1 = 0;
    let totalBiaya1 = 0;
    let totalDana1 = 0;
    let totalEstimasi1 = 0;
    let totalRE = 0;
    let totalKE = 0;
    let totalBE = 0;
    let totalDE = 0;

    map(allocationsPeng1, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    map(allocationsPeng2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    totalRE = totalRutin - totalRutin1;
    totalKE = totalKegiatan - totalKegiatan1;
    totalBE = totalBiaya - totalBiaya1;
    totalDE = totalDana - totalDana1;

    totalEstimasi1 = totalRutin1 + totalKegiatan1 + totalBiaya1 + totalDana1;

    let totalEstimasialokasi = 0;
    totalEstimasialokasi = totalRE + totalKE + totalDE + totalBE;

    return (
      <tr className="summary total">
        <td className="total" colSpan={summaryRowColSpan}>
          Estimasi Laba/Rugi
        </td>
        {isPusat && <td />}

        <td className="nominal">Rp.{commaFormatted(totalRE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBE)}</td>
        <td>
          <center> - </center>
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalEstimasialokasi)}
        </td>
      </tr>
    );
  }

  renderKetTotalEstimasiLabaRugi2(allocationsPeng1, allocationsPeng2) {
    const { rapbuSummary, workflow, user, total_estimasi, selectedDivisi } =
      this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;

    const isBendahara = !!(user_group && user_group.name === "Bendahara");
    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    const summaryRowColSpan = 5;

    const totalRutin = 0;
    const totalKegiatan = 0;
    const totalBiaya = 0;
    const totalDana = 0;
    const totalEstimasi = 0;

    let totalRutin1 = 0;
    let totalKegiatan1 = 0;
    let totalBiaya1 = 0;
    let totalDana1 = 0;
    let totalEstimasi1 = 0;
    let totalRE = 0;
    let totalKE = 0;
    let totalBE = 0;
    let totalDE = 0;

    map(allocationsPeng1, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    map(allocationsPeng2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    totalRE = totalRutin1;
    totalKE = totalKegiatan1;
    totalBE = totalBiaya1;
    totalDE = totalDana1;

    totalEstimasi1 = totalRutin1 + totalKegiatan1 + totalBiaya1 + totalDana1;

    let totalEstimasialokasi = 0;
    totalEstimasialokasi = totalRE + totalKE + totalDE + totalBE;

    return (
      // <div>
      // <table>

      <tr className="summary total">
        <td className="total" colSpan={summaryRowColSpan}>
          Estimasi Laba/Rugi
        </td>
        {isPusat && <td />}

        <td className="nominal">Rp.{commaFormatted(totalRE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBE)}</td>
        <td>
          <center> - </center>
          {/* <div className="icon2">
                Tidak ada
                  <FileInput
                  style={{width:"300px"}}
                    data-input-type="file"
                    type="file"
                    name="file"
                    label="File"
                    onChange={this.onFormChange}
                  />
               </div> */}
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalEstimasialokasi)}
        </td>
      </tr>
    );
  }

  renderKetTotalSaldo(
    allocationsPen1,
    allocationsPen2,
    allocationsPeng1,
    allocationsPeng2
  ) {
    const { workflow, selectedDivisi } = this.props;
    const { is_done = false } = workflow;
    const summaryRowColSpan = 5;

    let totalRutin = 0;
    let totalKegiatan = 0;
    let totalBiaya = 0;
    let totalDana = 0;

    map(allocationsPen1, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    map(allocationsPen2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    let totalRutin1 = 0;
    let totalKegiatan1 = 0;
    let totalBiaya1 = 0;
    let totalDana1 = 0;
    let totalEstimasi1 = 0;
    let totalRE = 0;
    let totalKE = 0;
    let totalBE = 0;
    let totalDE = 0;

    map(allocationsPeng1, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    map(allocationsPeng2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    totalRE = totalRutin - totalRutin1;
    totalKE = totalKegiatan - totalKegiatan1;
    totalBE = totalBiaya - totalBiaya1;
    totalDE = totalDana - totalDana1;

    totalEstimasi1 = totalRutin1 + totalKegiatan1 + totalBiaya1 + totalDana1;

    let totalEstimasialokasi = 0;
    totalEstimasialokasi = totalRE + totalKE + totalDE + totalBE;

    return (
      <tr className="summary total">
        <td className="total" colSpan={summaryRowColSpan}>
          Saldo
        </td>
        <td className="nominal">Rp.{commaFormatted(totalRE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBE)}</td>
        <td>
          <center> - </center>
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalEstimasialokasi)}
        </td>
      </tr>
    );
  }

  renderKetTotalSaldo2(allocationsPeng1, allocationsPeng2) {
    const { rapbuSummary, workflow, user, total_estimasi, selectedDivisi } =
      this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo } = rapbuSummary;

    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const summaryRowColSpan = 5;

    const totalRutin = 0;
    const totalKegiatan = 0;
    const totalBiaya = 0;
    const totalDana = 0;
    const totalEstimasi = 0;
    let totalRutin1 = 0;
    let totalKegiatan1 = 0;
    let totalBiaya1 = 0;
    let totalDana1 = 0;
    let totalEstimasi1 = 0;
    let totalRE = 0;
    let totalKE = 0;
    let totalBE = 0;
    let totalDE = 0;

    map(allocationsPeng1, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    map(allocationsPeng2, (data, idx) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].divisi_id === selectedDivisi) {
          const { allocations } = data.data[i];
          Object.keys(allocations).forEach((key) => {
            const allocation = allocations[key];
            if (key === "1") {
              totalRutin1 += parseInt(allocation.nominal);
            } else if (key === "2") {
              totalKegiatan1 += parseInt(allocation.nominal);
            } else if (key === "3") {
              totalDana1 += parseInt(allocation.nominal);
            } else if (key === "4") {
              totalBiaya1 += parseInt(allocation.nominal);
            }
          });
        }
      }
    });

    totalRE = totalRutin1;
    totalKE = totalKegiatan1;
    totalBE = totalBiaya1;
    totalDE = totalDana1;

    totalEstimasi1 = totalRutin1 + totalKegiatan1 + totalBiaya1 + totalDana1;

    let totalEstimasialokasi = 0;
    totalEstimasialokasi = totalRE + totalKE + totalDE + totalBE;

    return (
      // <div>
      // <table>

      <tr className="summary total">
        <td className="total" colSpan={summaryRowColSpan}>
          Saldo
        </td>
        {isPusat && <td />}
        <td className="nominal">Rp.{commaFormatted(totalRE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalKE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalDE)}</td>
        <td className="nominal">Rp.{commaFormatted(totalBE)}</td>
        <td>
          <center> - </center>
        </td>
        <td className="nominal" style={{ width: "145px" }}>
          Rp.{commaFormatted(totalEstimasialokasi)}
        </td>
      </tr>
    );
  }

  renderHeader() {
    const { workflow, user } = this.props;
    const { is_done = false } = workflow;
    const { user_group } = user;

    const isPusat = user_group && userGroupPusat.includes(user_group.name);
    const hasCheckbox = isPusat && !is_done;

    return (
      <thead>
        <tr>
          <th className="manage-budget__row" rowSpan={2}>
            Kode Akun
          </th>
          <th rowSpan={3}>Nama Account</th>
          <th rowSpan={2}>Nama Tema</th>
          <th rowSpan={2}>Target</th>
          <th rowSpan={2}>Waktu Pelaksanaan</th>
          <th colSpan={hasCheckbox ? 3 : 4}>Alokasi Anggaran</th>
          <th rowSpan={2}>Upload File</th>
          <th rowSpan={6}>Total</th>
        </tr>
        <tr>
          <th>Rutin</th>
          <th>Kegiatan</th>
          {/* <th>Dana Pemerintah</th> */}
          <th>
            Dana <strong>BOS</strong>
          </th>
          <th>Biaya Pengembangan Pendidikan</th>
          {hasCheckbox && (
            <th rowSpan="2">
              <Input
                type="checkbox"
                name="check_all"
                data-pos="committee"
                onClick={this.onCheckAll}
              />
            </th>
          )}
          {hasCheckbox && (
            <th rowSpan="2">
              <Input
                type="checkbox"
                name="check_all"
                data-pos="intern"
                onClick={this.onCheckAll}
              />
            </th>
          )}
          {hasCheckbox && (
            <th rowSpan="2">
              <Input
                type="checkbox"
                name="check_all"
                data-pos="bos"
                onClick={this.onCheckAll}
              />
            </th>
          )}
        </tr>
      </thead>
    );
  }

  render() {
    const { rapbuSummary, workflow, user } = this.props;
    const { is_done = false } = workflow;
    const { semesterOne, semesterTwo } = rapbuSummary;
    const { user_group } = user;
    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    const tableColSpan = isPusat && !is_done ? 18 : 18;

    return (
      <div className="manage-budget__table-wrapper">
        <table className="manage-budget__form-table table">
          {this.renderHeader()}

          <tbody>
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester II Tahun Ajaran Berjalan</h4>
                </div>
              </td>
            </tr>
            <tr>
              {user_group.name === "Keuangan Sekolah" ||
              user_group.name === "Admin" ||
              user_group.name === "Keuangan Pusat" ? (
                <td colSpan={tableColSpan}>
                  <div className="manage-budget__add-more">
                    <h4>Pendapatan</h4>
                  </div>
                </td>
              ) : (
                ""
              )}
            </tr>
            {user_group.name === "Keuangan Sekolah" ||
            user_group.name === "Admin" ||
            user_group.name === "Keuangan Pusat"
              ? this.renderRows(semesterTwo.pendapatan)
              : ""}
            {user_group.name === "Keuangan Sekolah" ||
            user_group.name === "Admin" ||
            user_group.name === "Keuangan Pusat"
              ? this.renderSummary("pendapatan", 2)
              : ""}
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pengeluaran</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterTwo.pengeluaran)}
            {this.renderSummary("pengeluaran", 2)}

            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester I Tahun Ajaran Berikutnya</h4>
                </div>
              </td>
            </tr>
            <tr>
              {user_group.name === "Keuangan Sekolah" ||
              user_group.name === "Admin" ||
              user_group.name === "Keuangan Pusat" ? (
                <td colSpan={tableColSpan}>
                  <div className="manage-budget__add-more">
                    <h4>Pendapatan</h4>
                  </div>
                </td>
              ) : (
                ""
              )}
            </tr>

            {user_group.name === "Keuangan Sekolah" ||
            user_group.name === "Admin" ||
            user_group.name === "Keuangan Pusat"
              ? this.renderRows(semesterOne.pendapatan)
              : ""}

            {user_group.name === "Keuangan Sekolah" ||
            user_group.name === "Admin" ||
            user_group.name === "Keuangan Pusat"
              ? this.renderSummary("pendapatan", 1)
              : ""}
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pengeluaran</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterOne.pengeluaran)}
            {this.renderSummary("pengeluaran", 1)}
            {user_group.name === "Keuangan Pusat" ||
            user_group.name === "Keuangan Sekolah" ||
            user_group.name === "Admin"
              ? this.renderKetTotalPendapatan(
                  semesterOne.pendapatan,
                  semesterTwo.pendapatan
                )
              : ""}
            {this.renderKetTotalPengeluaran(
              semesterOne.pengeluaran,
              semesterTwo.pengeluaran
            )}
            {user_group.name === "Keuangan Pusat" ||
            user_group.name === "Keuangan Sekolah" ||
            user_group.name === "Admin"
              ? this.renderKetTotalEstimasiLabaRugi(
                  semesterOne.pendapatan,
                  semesterTwo.pendapatan,
                  semesterOne.pengeluaran,
                  semesterTwo.pengeluaran
                )
              : ""}
            {user_group.name === "Keuangan Pusat" ||
            user_group.name === "Keuangan Sekolah" ||
            user_group.name === "Admin"
              ? this.renderKetTotalSaldo(
                  semesterOne.pendapatan,
                  semesterTwo.pendapatan,
                  semesterOne.pengeluaran,
                  semesterTwo.pengeluaran
                )
              : ""}
            {user_group.name === "Hrd" ||
            user_group.name === "Pengadaan" ||
            user_group.name === "Litbang" ||
            user_group.name === "IT" ||
            user_group.name === "Opsdik" ||
            user_group.name === "RT" ||
            user_group.name === "Sekretariat" ||
            user_group.name === "Keuangan"
              ? this.renderKetTotalEstimasiLabaRugi2(
                  semesterOne.pengeluaran,
                  semesterTwo.pengeluaran
                )
              : ""}
            {user_group.name === "Hrd" ||
            user_group.name === "Pengadaan" ||
            user_group.name === "Litbang" ||
            user_group.name === "IT" ||
            user_group.name === "Opsdik" ||
            user_group.name === "RT" ||
            user_group.name === "Sekretariat" ||
            user_group.name === "Keuangan"
              ? this.renderKetTotalSaldo2(
                  semesterOne.pengeluaran,
                  semesterTwo.pengeluaran
                )
              : ""}
          </tbody>
        </table>
      </div>
    );
  }
}

BudgetRapby.propTypes = {
  onChangeRecommendation: PropTypes.func,
  onSelectBudgetDetail: PropTypes.func,
  onCheckAll: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  onRemoveFile: PropTypes.func,
  rapbuSummary: PropTypes.object.isRequired,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  workflow: PropTypes.object,
  total_pendapatan: PropTypes.object,
  total_pengeluaran: PropTypes.object,
  total_estimasi: PropTypes.object,
  total_saldo: PropTypes.object,
};
BudgetRapby.defaultProps = {
  onChangeRecommendation: noop,
  onSelectBudgetDetail: noop,
  onCheckAll: noop,

  handleFileUpload: noop,
  handleFileDownload: noop,
  onRemoveFile: noop,

  user: null,
  recommendations: {},
  workflow: null,
  total_pendapatan: "",
  total_pengeluaran: "",
  total_estimasi: "",
  total_saldo: "",
};
