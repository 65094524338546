import { filter, isEmpty, map } from 'lodash';
import { normalizeAmount } from './transformer.util';
import { platform } from 'chart.js';

export function addBudgetDetailFormat(payload) {

  // console.log("check payload")
  // console.log(payload)

  const { semesterOne, semesterTwo } = payload;

  const semesterOneList = map(filter(semesterOne, data => isEmpty(data.unique_id)), list => ({
    ...list,
    semester: 1,
    allocations: list.allocations,
    code_of_account: list.parameter_code.code,
    title: list.title || '',
    tema: list.tema,
    target: list.target,
    execution_time: list.execution_time,
    quantity: normalizeAmount(list.quantity),
    price: normalizeAmount(list.price),
    term: normalizeAmount(list.term),

    total: normalizeAmount(list.quantity)
    * normalizeAmount(list.price)
    * normalizeAmount(list.term),
    desc: list.desc,
  }));


  const semesterTwoList = map(filter(semesterTwo, data => isEmpty(data.unique_id)), list => ({
    ...list,
    semester: 2,
    allocations: list.allocations,
    code_of_account: list.parameter_code.code,
    title: list.title || '',
    tema: list.tema,
    target: list.target,
    execution_time: list.execution_time,
    quantity: normalizeAmount(list.quantity),
    price: normalizeAmount(list.price),
    term: normalizeAmount(list.term),
    total: normalizeAmount(list.quantity)
    * normalizeAmount(list.price)
    * normalizeAmount(list.term),
    desc: list.desc,
  }));

  return [...semesterTwoList, ...semesterOneList];
}

export function editBudgetDetailFormat(payload) {
  return [{
    ...payload,
    id: payload.id,
    semester: payload.semester,
    allocations: payload.allocations,
    code_of_account: payload.parameter_code.code,
    title: payload.title,
    tema: payload.tema,
    target: payload.target,
    realisasi: payload.realisasi,
    quantity: normalizeAmount(payload.quantity),
    price: normalizeAmount(payload.price),
    term: normalizeAmount(payload.term),
    total: normalizeAmount(payload.quantity)
    * normalizeAmount(payload.price)
    * normalizeAmount(payload.term),
    desc: payload.desc,
  }];
}

export function saveBudgetTransferFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    head: payload.head.id,
    description: payload.description,
    account: payload.code_of_account.code,
    sources: map(payload.selectedBudget, budget => ({
      budget_detail_unique_id: budget.unique_id,
      relocated_amount: normalizeAmount(budget.amount),
    })),
    recipients: map(payload.rapbuSource, list => ({
      ...(list.id ? { id: list.id } : {}),
      semester: list.semester,
      code_of_account: list.code.code,
      title: list.title,
      quantity: normalizeAmount(list.quantity),
      price: normalizeAmount(list.price),
      term: normalizeAmount(list.term),
      total: normalizeAmount(list.quantity)
      * normalizeAmount(list.price) * normalizeAmount(list.term),
      desc: list.desc,
    })),
  };
}

export function saveManageCashJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    unit_id: payload.unit_id,
    date: payload.date,
    type: payload.type,
    pos: payload.pos || '',
    unit: (payload.unit) ? payload.unit.id : null,
    submitted_by: payload.submitted_by,
    received_by: payload.received_by,
    tipe: payload.tipe,
    details: {
      standard: map(payload.standard, list => ({
        code_of_account: list.code_of_account.code,
        description: list.description,
        nominal: normalizeAmount(list.nominal),
        tax_type: list.tax_type,
        tax_value: normalizeAmount(list.tax_value),
        npwp: list.npwp,
        name: list.name,
        tax: list.tax,
        budget_details: list.budget_details || [],
      })),
      reconciliation: map(payload.reconciliation, list => ({
        code_of_account: list.code_of_account.code,
        nominal: normalizeAmount(list.nominal),
        description: list.description,
      })),
    },
  };
}

export function saveManageBankJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    unit_id: payload.unit_id,
    date: payload.date,
    journal_number: '',
    pos: payload.pos,
    type: payload.type,
    unit: (payload.unit) ? payload.unit.id : null,
    reference_number: '',
    counterparty: '',
    tax_number: '',
    tax_value: '',
    bank_account: (payload.bank_account) ? payload.bank_account.value : null,
    submitted_by: payload.submitted_by,
    received_by: payload.received_by,
    tipe: payload.tipe,
    details: {
      standard: map(payload.standard, list => ({
        code_of_account: list.code_of_account.code,
        description: list.description,
        nominal: normalizeAmount(list.nominal),
        tax_type: list.tax_type,
        tax_value: normalizeAmount(list.tax_value),
        npwp: list.npwp,
        name: list.name,
        budget_details: list.budget_details || [],
      })),
      reconciliation: map(payload.reconciliation, list => ({
        code_of_account: list.code_of_account.code,
        nominal: normalizeAmount(list.nominal),
        description: list.description,
      })),
    },
  };
}

export function managePaymentJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    date: payload.date,
    journal_number: payload.journal_number,
    va_code: payload.va_code,
    mmyy: payload.mmyy,
    payment_type: payload.payment_type,
    nominal: normalizeAmount(payload.nominal),
  };
}

export function manageAdjustmentJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      nominal: normalizeAmount(detail.nominal),
      isCredit: detail.isCredit,
    })),
  };
}

export function manageClosingJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      debit: normalizeAmount(detail.debit),
      credit: normalizeAmount(detail.credit),
    })),
  };
}

export function manageNonKasJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      nominal: normalizeAmount(detail.nominal),
      isCredit: detail.isCredit,
    })),
  };
}

export function manageGeneralFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    unit_id: payload.unit_id,
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      debit: normalizeAmount(detail.debit),
      credit: normalizeAmount(detail.credit),
    })),
  };
}

export function manageInformationLetterFormat(payload, isEdit) {
  return {
    ...(isEdit ? { id: payload.id } : {}),
    nip: payload.nip,
    no_sk: payload.no_sk,
    judul_sk: payload.judul_sk,
    jenis_sk: payload.jenis_sk,
    keterangan: payload.keterangan,
    kota: payload.kota,
    nama_ketua_yayasan: payload.nama_ketua_yayasan,
    nama_yayasan: payload.nama_yayasan,
    diangkat_sebagai: payload.diangkat_sebagai,
    tembusan: payload.tembusan,
    i_pangkat_golongan: payload.i_pangkat_golongan,
    i_jabatan: payload.i_jabatan,
    i_tanggal: payload.i_tanggal,
    i_gaji_pokok: normalizeAmount(payload.i_gaji_pokok),
    i_periode: payload.i_periode,
    i_lama_bekerja: payload.i_lama_bekerja,
    i_terbilang: payload.i_terbilang,
    ii_pangkat_golongan: payload.ii_pangkat_golongan,
    ii_jabatan: payload.ii_jabatan,
    ii_tanggal: payload.ii_tanggal,
    ii_gaji_pokok: normalizeAmount(payload.ii_gaji_pokok),
    ii_periode: payload.ii_periode,
    ii_lama_bekerja: payload.ii_lama_bekerja,
    ii_terbilang: payload.ii_terbilang,
    create_by: payload.create_by,
  };
}

export function manageStaffFormat(payload, isEdit) {
  return {
    ...(isEdit ? { nip: payload.nip } : {}),
    nip:payload.nip,
    nama_lengkap: payload.nama_lengkap,
    nama_panggilan: payload.nama_panggilan,
    email : payload.email,
    tempat_lahir: payload.tempat_lahir,
    tanggal_lahir: payload.tanggal_lahir,
    id_absensi_kartu_fingerprint: payload.id_absensi_kartu_fingerprint,
    jenis_kelamin: payload.jenis_kelamin,
    tinggi: payload.tinggi,
    berat: payload.berat,
    alamat_tempat_tinggal: payload.alamat_tempat_tinggal,
    rt: payload.rt,
    rw: payload.rw,
    kelurahan: payload.kelurahan,
    kecamatan: payload.kecamatan,
    kotamadya_kabupaten: payload.kotamadya_kabupaten,
    propinsi: payload.propinsi,
    no_telpon: payload.no_telpon,
    no_wa: payload.no_wa,
    agama: payload.agama, //kalau propesti nya di ganti engga dapet mas kayaknya itu ngambil dari api
    kewarganegaraan: payload.kewarganegaraan,
    // golongan_darah: payload.golongan_darah,
    golongan_darah:payload.golongan_darah,
    pendidikan_terakhir: payload.pendidikan_terakhir,
    status_perkawinan: payload.status_perkawinan,
    bahasa_yg_dikuasai: payload.bahasa_yg_dikuasai,
    penyakit_yg_diderita:payload.penyakit_yg_diderita,
    kelainan_fisik: payload.kelainan_fisik,
    no_ktp: payload.no_ktp,
    no_kk: payload.no_kk,
    no_npwp: payload.no_npwp,
    bank: payload.bank,
    no_rek_bank: payload.no_rek_bank,
    bpjs_kesehatan_status:payload.bpjs_kesehatan_status,
    bpjs_kesehatan_no:payload.bpjs_kesehatan_no,
    bpjs_ketenagakerjaan_status:payload.bpjs_ketenagakerjaan_status,
    bpjs_ketenagakerjaan_no:payload.bpjs_ketenagakerjaan_no,
    tanggal_mulai_bekerja: payload.tanggal_mulai_bekerja,
    masa_kerja_saat_ini: payload.masa_kerja_saat_ini,
    tgl_keluar_pensiun: payload.tgl_keluar_pensiun,
    keterangan_keluar_pensiun:payload.keterangan_keluar_pensiun,
    unitkerja: payload.unitkerja,
    status_kepegawaian : payload.status_kepegawaian,
    jabatan: payload.jabatan,
    jabatan_tugas_tambahan:payload.jabatan_tugas_tambahan,
    golongan:payload.golongan,
    no_yadapen:payload.no_yadapen,
    aktif_non_aktif : payload.aktif_non_aktif,
    apd:payload.apd,
    region: payload.region,

    pendidikan_formal: map(payload.pendidikan_formal, list => ({
      institusi: list.institusi,
      level:list.level,
      nama_sekolah:list.nama_sekolah,
      kota: list.kota,
      jurusan: list.jurusan,
      dari_tahun: list.dari_tahun,
      sampai_tahun: list.sampai_tahun,
    })),

    pendidikan_non_formal: map(payload.pendidikan_non_formal, list => ({
      institusi: list.institusi,
      nama_training:list.nama_training,
      lembaga: list.lembaga,
      kota: list.kota,
      tahun: list.tahun,
      lama: list.lama,
    })),

    pekerjaan_history: map(payload.pekerjaan_history, list => ({
      perusahaan :list.perusahaan,
      kota       :list.kota,
      jabatan    :list.jabatan,
      tahun      :list.tahun,
      sampai_tahun  :list.sampai_tahun,
      keterangan    :list.keterangan,
  })),

    memorandum: map(payload.memorandum, list => ({
      // nip:list.nip,
      level: list.level,
      no_surat :list.no_surat,
      tanggal : list.tanggal,
      jenis_pelanggaran: list.jenis_pelanggaran,
      keterangan: list.keterangan,
    })),

    cuti_panjang: map(payload.cuti_panjang, list => ({
      alasan_cuti: list.alasan_cuti,
      tanggal:list.tanggal,
      sampai_tanggal:list.sampai_tanggal,
      keterangan: list.keterangan,
    })),

    keluarga: map(payload.keluarga, list => ({
      id_staff_family:list.id_staff_family,
      nama_lengkap: list.nama_lengkap,
      tanggal_lahir: list.tanggal_lahir,
      agama:list.agama,
      pendidikan: list.pendidikan,
      status: list.status,
      tertanggung:list.tertanggung,
  })),
  };
}

export function manageGetClasses(payload, isEdit) {
  return {
    ...(isEdit ? { id: payload.id } : {}),
    classes:payload.classes,
  };
}

export function manageGetRegistration(payload, isEdit) {
  return {
    ...(isEdit ? { id: payload.id } : {}),
    student_profile :{
      nis : payload.nissiswa == undefined ? "" : payload.nissiswa,
      first_name : payload.first_name == undefined ? "" : payload.first_name,
      last_name : payload.last_name == undefined ? "" : payload.last_name,
      nick_name : payload.nama_panggilan == undefined ? "" : payload.nama_panggilan,
      genders_id :payload.genders == undefined ? "" : payload.genders ,
      weight :payload.berat_badan == undefined ? "" : payload.berat_badan,
      height : payload.tinggi_badan == undefined ? "" : payload.tinggi_badan,
      blood_types_id : payload.blood_types == undefined ? "" : payload.blood_types,
      hobby : payload.hobi == undefined ? "" : payload.hobi,
      disabilities : payload.penyakit == undefined ? "" : payload.penyakit,
      religions_id : payload.religions == undefined ? "" : payload.religions,
      nationalities_id : payload.citizenships == undefined ? "" : payload.citizenships,
      nth_child : payload.anak_ke == undefined ? "" : payload.anak_ke,
      siblings : payload.jml_saudara == undefined ? "" : payload.jml_saudara,
      language : payload.bahasa == undefined ? "" : payload.bahasa ,
      status : payload.status_anak == undefined ? "" : payload.status_anak,
      email : payload.email == undefined ? "" : payload.email,
      phone : payload.no_telp == undefined ? "" : payload.no_telp,
      house_ownership :  payload.status_kepemilikan == undefined ? "" : payload.status_kepemilikan,
      school_house_distance : payload.jarak  == undefined ? "" : payload.jarak,
      levels_id : payload.level == undefined ? "" : payload.level,
      classes_year : payload.tahunajaran == undefined ? "" : payload.tahunajaran,
      classes_id : payload.classes == undefined ? "" : payload.classes,
      address : payload.alamat == undefined ? "" : payload.alamat,
      provinces_id : payload.provinces_id == undefined ? "" : payload.provinces_id,
      cities_id : payload.cities_id == undefined ? "" : payload.cities_id,
      districts_id : payload.districts_id == undefined ? "" : payload.districts_id,
      sub_districts_id : payload.sub_districts == undefined ? "" : payload.sub_districts,
    },
    previous_schools :{
     nisn : payload.nisn == undefined ? "" : payload.nisn,
     name : payload.namasekolah == undefined ? "" : payload.namasekolah ,
		 address : payload.alamatsekolah == undefined ? "" : payload.alamatsekolah,
		 provinces_id : payload.provinces_id2 == undefined ? "" : payload.provinces_id2,
		 cities_id : payload.cities_id2 == undefined ? "" : payload.cities_id2,
		 districts_id : payload.districts_id2 == undefined ? "" : payload.districts_id2,
		 sub_districts_id : payload.sub_districts2 == undefined ? "" : payload.sub_districts2,
		 phone : payload.notelp_sekolah == undefined ? "" : payload.notelp_sekolah,
		 transfer_reason : payload.alasan == undefined ? "" : payload.alasan,
		 levels_id : payload.levels1 == undefined ? "" : payload.levels1,
		 transfer_letter_number : payload.namasurat == undefined ? "" : payload.namasurat,
		 transfer_date : payload.tanggal == undefined ? "" : payload.tanggal
    },
    certifications:{
      date: payload.tanggal_ijazah == undefined ? "" : payload.tanggal_ijazah,
      cert_number : payload.nomor_ijazah == undefined ? "" : payload.nomor_ijazah,
      grade : payload.nilai_ijazah == undefined ? "" : payload.nilai_ijazah,
      number_of_subjects : payload.jumlah_pelajaran_ijazah == undefined ? "" : payload.jumlah_pelajaran_ijazah,
      parent_names : payload.nama_orang_tua == undefined ? "" : payload.nama_orang_tua
    },
    shun:{
      shun_number : payload.nomor == undefined ? "" : payload.nomor,
      date : payload.tanggal_shun == undefined ? "" : payload.tanggal_shun,
      grade : payload.nilai == undefined ? "" : payload.nilai,
      number_of_subjects : payload.jumlah_pelajaran == undefined ? "" : payload.jumlah_pelajaran,
      uan_participant_number : payload.no_peserta == undefined ? "" : payload.no_peserta
    },
    parent:[{
        guardians_id : 1,
        full_name :payload.nama_lengkap_ayah == undefined ? "" : payload.nama_lengkap_ayah,
        nik : payload.nik1 == undefined ? "" : payload.nik1,
        religions_id : payload.religions1 == undefined ? "" : payload.religions1,
        nationalities_id : payload.citizenships1 == undefined ? "" : payload.citizenships1,
        educations_id : payload.educations1 == undefined ? "" : payload.educations1,
        birthplace : payload.tempat_tgl_lahir_ayah == undefined ? "" : payload.tempat_tgl_lahir_ayah,
        dob : payload.tgl_lahir_ayah == undefined ? "" : payload.tgl_lahir_ayah,
        address : payload.alamat1 == undefined ? "" : payload.alamat1,
        provinces_id : payload.provinces_id3 == undefined ? "" : payload.provinces_id3,
        cities_id : payload.cities_id3 == undefined ? "" : payload.cities_id3,
        districts_id : payload.districts_id3 == undefined ? "" : payload.districts_id3,
        sub_districts_id : payload.sub_districts3 == undefined ? "" : payload.sub_districts3,
        occupations_id : payload.occupations1 == undefined ? "" : payload.occupations1,
        position : payload.jabatan1 == undefined ? "" : payload.jabatan1,
        income : payload.penghasilan1 == undefined ? "" : payload.penghasilan1,
        phone : payload.notelpayah == undefined ? "" : payload.notelpayah,
        status : payload.statusayah == undefined ? "" : payload.statusayah
      },{
        guardians_id : 2,
        full_name :payload.nama_lengkap_ibu == undefined ? "" : payload.nama_lengkap_ibu,
        nik : payload.nik2 == undefined ? "" : payload.nik2,
        religions_id : payload.religions2 == undefined ? "" : payload.religions2,
        nationalities_id : payload.citizenships2 == undefined ? "" : payload.citizenships2,
        educations_id : payload.educations2 == undefined ? "" : payload.educations2,
        birthplace : payload.tempat_tgl_lahir_ibu == undefined ? "" : payload.tempat_tgl_lahir_ibu,
        dob : payload.tgl_lahir_ibu == undefined ? "" : payload.tgl_lahir_ibu,
        address : payload.alamat2 == undefined ? "" : payload.alamat2,
        provinces_id : payload.provinces_id4 == undefined ? "" : payload.provinces_id4,
        cities_id : payload.cities_id4 == undefined ? "" : payload.cities_id4,
        districts_id : payload.districts_id4 == undefined ? "" : payload.districts_id4,
        sub_districts_id : payload.sub_districts4 == undefined ? "" : payload.sub_districts4,
        occupations_id : payload.occupations2 == undefined ? "" : payload.occupations2,
        position : payload.jabatan2 == undefined ? "" : payload.jabatan2,
        income : payload.penghasilan2 == undefined ? "" : payload.penghasilan2,
        phone : payload.notelpibu == undefined ? "" : payload.notelpibu,
        status : payload.statusibu == undefined ? "" : payload.statusibu
      },{
        guardians_id : 3,
        full_name :payload.nama_lengkap_wali == undefined ? "" : payload.nama_lengkap_wali,
        nik : payload.nik3 == undefined ? "" : payload.nik3,
        religions_id : payload.religions3 == undefined ? "" : payload.religions3,
        nationalities_id : payload.citizenships3 == undefined ? "" : payload.citizenships3,
        educations_id : payload.educations3 == undefined ? "" : payload.educations3,
        birthplace : payload.tempat_tgl_lahir_wali == undefined ? "" : payload.tempat_tgl_lahir_wali,
        dob : payload.tgl_lahir_wali == undefined ? "" : payload.tgl_lahir_wali,
        address : payload.alamat3 == undefined ? "" : payload.alamat3,
        provinces_id : payload.provinces_id5 == undefined ? "" : payload.provinces_id5,
        cities_id : payload.cities_id5 == undefined ? "" : payload.cities_id5,
        districts_id : payload.districts_id5 == undefined ? "" : payload.districts_id5,
        sub_districts_id : payload.sub_districts5 == undefined ? "" : payload.sub_districts5,
        occupations_id : payload.occupations3 == undefined ? "" : payload.occupations3,
        position : payload.jabatan3 == undefined ? "" : payload.jabatan3,
        income : payload.penghasilan3 == undefined ? "" : payload.penghasilan3,
        phone : payload.notelpwali == undefined ? "" : payload.notelpwali,
        status : payload.statuswali == undefined ? "" : payload.statuswali
      }
    ]
  };
}
