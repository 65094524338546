import React, { Component } from 'react';
import PropTypes from 'prop-types';

const filterCoa = (original, filter) => original.filter(o => !filter.includes(o.code.toString())) || [];

export default class TemaCoaMapping extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.update = this.update.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.init = this.init.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.state = {
      value: [],
      coa: [],
      tema: [],
      filteredCoa: [],
      filteredTema: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const {
      handleGetCoaOptions, handleGetTemaOptions, handleGetTemaCoaMap,
    } = this.props;
    const tema = await handleGetTemaOptions();
    const coa = await handleGetCoaOptions();
    let temaCoaList = await handleGetTemaCoaMap() || [];
    let valueCoa = [];
    let filteredCoa = coa || [];

    if ( temaCoaList && temaCoaList.length > 0) {
      valueCoa = temaCoaList.map(o => o.coa);
      filteredCoa = filterCoa(coa, valueCoa);
    } else {
      temaCoaList = tema.reduce((result, current) => {
        result.push({
          tema_id: parseInt(current.id),
          coa: null,
        });
        return result;
      }, []);
    }

    this.setState({
      value: temaCoaList,
      tema,
      coa,
      filteredCoa,
    });
  }

  async getData() {
    const { handleGetTemaCoaMap } = this.props;
    const value = await handleGetTemaCoaMap();

    this.setState({ value });
  }

  async update() {
    const { handleUpdateTemaCoaMap } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleUpdateTemaCoaMap({ value });
  }

  handleChange(e) {
    const { target } = e;
    const { dataset, value } = target;
    const { tema } = dataset;
    const { value: currentValue, coa } = this.state;
    const newValue = JSON.parse(JSON.stringify(currentValue)) || [];
    let valueCoa = [];

    if (tema) {
      const index = newValue.findIndex(el => parseInt(el.tema_id) === parseInt(tema));
      if (!index || index < 0) {
        newValue.push({ tema_id: tema, coa: value });
      } else {
        newValue[index].coa = value;
      }
    }

    valueCoa = newValue.map(o => o.coa);

    this.setState(prevState => ({
      ...prevState,
      value: newValue,
      filteredCoa: filterCoa(coa, valueCoa),
    }));
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { handleUpdateTemaCoaMap } = this.props;
    const { value } = this.state;
    const res = await handleUpdateTemaCoaMap({ map: value });
  }

  renderOption(tema) {
    const { value, filteredCoa } = this.state;
    const row = value.find(el => parseInt(el.tema_id) === parseInt(tema)) || {};
    return(
      <>
        <option value="">Pilih kode akun</option>
        {filteredCoa.map((o, idx) => (
          <option key={`option${idx}`} value={o.code} selected={parseInt(row.coa) === parseInt(o.code)}>
            {o.code} - {o.title}
          </option>
        ))}
      </>
    )
  }

  renderRow() {
    const { tema } = this.state;

    if (tema.length > 0) {
      return tema.map((i, index) => (
        <tr key={`tableRow${index}`}>
          <td>{i.code} - {i.title}</td>
          <td>
            <select data-tema={i.id} onChange={this.handleChange}>
              {this.renderOption(i.id)}
            </select>
          </td>
        </tr>
      ));
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <table className="table">
          <thead>
            <tr>
              <th>Tema</th>
              <th>Kode Akun</th>
            </tr>
          </thead>
          <tbody>
            {this.renderRow()}
          </tbody>
        </table>
        <button type="submit" className="button" title="Simpan" />
      </form>
    );
  }
}

TemaCoaMapping.propTypes = {
  handleGetTemaCoaMap: PropTypes.func,
  handleUpdateTemaCoaMap: PropTypes.func,
  handleGetTemaOptions: PropTypes.func,
  handleGetCoaOptions: () => {},
};
TemaCoaMapping.defaultProps = {
  handleGetTemaCoaMap: () => {},
  handleUpdateTemaCoaMap: () => {},
  handleGetTemaOptions: () => {},
  handleGetCoaOptions: () => {},
};
