import { isEmpty, includes } from 'lodash';
import * as api from '../../utils/api.util';
import * as userActions from '../actions/user.action';
import * as commonActions from '../actions/common.action';
import {
  set, get, storageKeys, remove,
} from '../../utils/storage.util';
import { errorAlert } from '../../utils/alert.util';

export function setupUser(replace, pathname) {
  return async (dispatch) => {
    const isDashboard = includes(pathname, 'dashboard');
    if (isDashboard) {
      const token = get(storageKeys.USER_TOKEN);
      if (!isEmpty(token)) {
        try {
          const user = await api.PROFILE();
          dispatch(userActions.setUser(user));
          // if (pathname === '/') {
          //   replace('/dashboard');
          // }
        } catch (err) {
          // err actions
        }
      } else {
        replace('/login');
      }
    }
    dispatch(commonActions.appSetup(true));
  };
}

export function handleLogin(payload, push) {
  return async (dispatch) => {
    try {
      const { remember_me = false } = payload;
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      // requestBody.append('username', payload.username);
      requestBody.append('email', payload.email);
      requestBody.append('password', payload.password);
      requestBody.append('remember_me', remember_me ? 1 : 0);
      const resLogin = await api.LOGIN(requestBody);
      const token = resLogin.access_token;
      set(storageKeys.USER_TOKEN, token);
      const user = await api.PROFILE();
      dispatch(userActions.setUser(user));
      push('/dashboard');
    } catch (err) {
      errorAlert({
        title: err.responseMessage || 'Login Gagal',
        errorMessage: 'Silakan coba lagi',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function handleLogout(push) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.AUTH('/logout');
      remove(storageKeys.USER_TOKEN);
      push('/login');
    } catch (err) {
      errorAlert({
        title: 'Logout Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function setWorkingUnit(unit = {}) {
  return async (dispatch) => {
    dispatch(userActions.setWorkingUnit(unit));
    return unit;
  };
}
